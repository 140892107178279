import {
  Department,
  EmployeeListItem,
  EmployeeResults,
  RetrieveAllPeopleDirCategoriesSectionsFieldsResponse,
  RetrieveDepartmentsResponse,
  RetrieveEmployeeRecordsResponse,
  RetrieveErbFieldsResponse,
  RetrievePeopleDirViewsResponse,
  RetrieveSitesResponse,
  Site
} from '@thrivea/organization-client';

export interface PpdColumn {
  fieldId: string; // erbScalarFieldId | erbTableColumnFieldId
  name: string;
  isSortable: boolean;
  isVisible: boolean;
  tableFieldId?: string;
  erbFieldCategoryId: string;
  erbFieldSectionId: string;
}

export interface PpdData {
  allErbFields: RetrieveErbFieldsResponse;
  employeeRecords: RetrieveEmployeeRecordsResponse;
  allPeopleDirCategoriesSectionsFields: RetrieveAllPeopleDirCategoriesSectionsFieldsResponse;
  peopleDirViews: RetrievePeopleDirViewsResponse;
}

export interface PpdAdditionalData {
  employeeResults: EmployeeResults;
  sitesResponse: RetrieveSitesResponse;
  departmentsResponse: RetrieveDepartmentsResponse;
}

// Employee Status temp IDs @TODO: Remove when API is updated @Ognjen @Stevo
export const ACTIVE = '5b966088-b0ea-43e2-8e88-d221b038a9d3';
export const EMAIL_SENT = '8573cd3f-9d15-4030-99aa-0b0c21502b9a';
export const INACTIVE = 'f712b8a1-ff19-409a-ba65-058458c6678b';

export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000';

export const display = (value: string | string[] | number | boolean | undefined, isEmpty: boolean): string => {
  if (isEmpty) return '-';

  return value as string;
};

export const displayTooltip = (value: string | string[] | number | boolean | undefined, isEmpty: boolean): string => {
  if (isEmpty) return 'No value';

  return value as string;
};

export const isPresentInEmployeeListItems = (
  value: string | string[] | number | boolean | undefined,
  employeeListItemsById: {
    [key: string]: EmployeeListItem;
  }
) => value && (value as string) in employeeListItemsById;

export const isPresentInSites = (
  value: string | string[] | number | boolean | undefined,
  sitesById: {
    [key: string]: Site;
  }
) => value && (value as string) in sitesById;

export const isPresentInDepartments = (
  value: string | string[] | number | boolean | undefined,
  departmentsById: {
    [key: string]: Department;
  }
) => value && (value as string) in departmentsById;
