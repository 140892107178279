import { Button, Collapse, FormControl, InputLabel, MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { TasksTabs } from './TasksTabs';
import { TaskViewModeTabs } from './TaskViewModeTabs';
import { ActionsIcon, ChevronDownIcon, EmployeeDropdownSearch, FilterIcon, RowCenterJustifyBetweenStack, RowCenterStack, StyledSelect } from '@/shared';
import { Dropdown } from './styled/Dropdown';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  ASSIGNED,
  changeTasksGroupBy,
  COMPLETED,
  createTaskViewRequested,
  createTempTaskView,
  DRAFT,
  generateSingleTaskPublicId,
  IN_REVIEW,
  NewTaskButton,
  retrieveTasksRequested,
  selectActiveTasksView,
  selectActiveTasksViewFilters,
  selectActiveTasksViewId,
  selectIsActiveTasksViewUnsaved,
  selectIsActiveViewSingleTaskView,
  selectRetrieveTasksRequest,
  selectTasksGroupBy,
  selectTasksViewsSavedIds,
  selectTasksViewsUnsavedIds,
  TASK_TYPE_TO_I8N_KEY,
  TASKS_DEFAULT_VIEW_ID,
  TasksDropdownSearch,
  TasksFromToDateFilter,
  TasksGroupBy,
  TasksHeaderIconButton,
  TasksViewsTabs,
  TaskVewFilterDropdown,
  updateTempTaskView
} from '@features/tasks';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { snakeCase } from 'lodash';
import {
  CreateTasksViewRequest,
  DateRange,
  EmployeeListItem,
  OrderDirection,
  RetrieveTasksRequest,
  SearchExpression,
  TaskOrderingField,
  TaskScope,
  TasksView,
  TasksViewFilters,
  TaskType
} from '@thrivea/organization-client';
import Grid from '@mui/material/Grid2';
import { selectEmployeeListItems } from '@/features/shared';
import { selectCurrentEmployeeId } from '@features/shared';
import { v4 as uuidv4 } from 'uuid';

const searchExpressions = Object.entries(SearchExpression)
  .filter(([_key, value]) => typeof value === 'number')
  .slice(1)
  .map(([key, _value]) => {
    const id = snakeCase(key);
    const name = id;
    return { id, name };
  });

const taskTypeOptions = Object.keys(TaskType)
  .filter((key) => isNaN(Number(key)))
  .slice(1)
  .map((key) => ({
    id: TaskType[key as keyof typeof TaskType].toString(),
    name: key
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
  }));

const taskStatusOptions = [
  { id: DRAFT, name: 'Draft' },
  { id: ASSIGNED, name: 'Assigned' },
  { id: IN_REVIEW, name: 'In Review' },
  { id: COMPLETED, name: 'Completed' }
];

export const TasksHeader = () => {
  const { t } = useTranslation(['common, tasks']);
  const dispatch = useAppDispatch();

  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const groupBy = useAppSelector(selectTasksGroupBy);
  const employeeListItems = useAppSelector(selectEmployeeListItems);
  const isActiveViewSingleTaskView = useAppSelector(selectIsActiveViewSingleTaskView);
  const retrieveTasksRequest = useAppSelector((state) => selectRetrieveTasksRequest(state, employeeId));
  const isActiveTasksViewUnsaved = useAppSelector(selectIsActiveTasksViewUnsaved);
  const unsavedTasksViewIds = useAppSelector(selectTasksViewsUnsavedIds);
  const savedTasksViewIds = useAppSelector(selectTasksViewsSavedIds);
  const activeTasksView = useAppSelector(selectActiveTasksView);
  const activeTasksViewFilters = useAppSelector(selectActiveTasksViewFilters) as TasksViewFilters;
  const activeTasksViewId = useAppSelector(selectActiveTasksViewId);

  // const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFilterRowVisible, setIsFilterRowVisible] = useState(false);
  // const [searchText, setSearchText] = useState('');
  // const [searchExpression, setSearchExpression] = useState<SearchExpression>(SearchExpression.CONTAINS);

  const [taskType, setTaskType] = useState<TaskType>(TaskType.SINGLE_TASK);
  const [statusIds, setStatusIds] = useState<string[]>([]);
  const [ownerIds, setOwnerIds] = useState<string[]>([]);
  const [assigneeIds, setAssigneeIds] = useState<string[]>([]);
  const [reviewerIds, setReviewerIds] = useState<string[]>([]);
  // const [searchQuery, setSearchQuery] = useState<SearchQuery>(
  //   new SearchQuery({
  //     expression: SearchExpression.CONTAINS,
  //     text: ''
  //   })
  // );
  const [fromDate, setFromDate] = useState<string | null>(activeTasksViewFilters?.dueDateRange?.dateFrom ?? null);
  const [toDate, setToDate] = useState<string | null>(activeTasksViewFilters?.dueDateRange?.dateTo ?? null);

  const employeeOptions = employeeListItems.map((employee) => ({
    id: employee.employeeId,
    name: employee.displayName
  }));

  // const debouncedSearch = useCallback(
  //   debounce((value) => {
  //     setSearchQuery(
  //       new SearchQuery({
  //         expression: searchExpression,
  //         text: value
  //       })
  //     );

  //     dispatch(
  //       retrieveTasksRequested(
  //         new RetrieveTasksRequest({
  //           ...retrieveTasksRequest,
  //           pageNumber: 1,
  //           pageSize: 10,
  //           employeeId,
  //           type: taskType,
  //           statusIds,
  //           ownerIds,
  //           assigneeIds,
  //           reviewerIds,
  //           searchQuery: new SearchQuery({
  //             expression: searchExpression,
  //             text: value
  //           }),
  //           taskScope: TaskScope.MY_TASKS,
  //           taskOrdering: {
  //             field: TaskOrderingField.TITLE,
  //             orderDirection: OrderDirection.ASCENDING
  //           },
  //           dueDateRange: new DateRange({
  //             dateFrom: fromDate!,
  //             dateTo: toDate!
  //           })
  //         })
  //       )
  //     );

  //     if (unsavedTasksViewIds.includes(activeTasksViewId) || savedTasksViewIds.includes(activeTasksViewId) || activeTasksViewId !== TASKS_DEFAULT_VIEW_ID) {
  //       const updatedTaskView = {
  //         ...activeTasksView,
  //         kind: {
  //           case: 'filters' as const,
  //           value: {
  //             ...activeTasksView.kind.value,
  //             type: taskType,
  //             statusIds: statusIds,
  //             ownerIds: ownerIds,
  //             assigneeIds: assigneeIds,
  //             reviewerIds: reviewerIds,
  //             searchQuery: new SearchQuery({
  //               expression: searchExpression,
  //               text: value
  //             }),
  //             dueDateRange: new DateRange({
  //               dateFrom: fromDate!,
  //               dateTo: toDate!
  //             })
  //           }
  //         },
  //         lastModifiedAt: new Date().toISOString()
  //       };

  //       dispatch(
  //         updateTempTaskView(
  //           new CreateTasksViewRequest({
  //             employeeId,
  //             tasksView: new TasksView(updatedTaskView)
  //           })
  //         )
  //       );
  //     } else {
  //       dispatch(
  //         createTempTaskView(
  //           new CreateTasksViewRequest({
  //             employeeId,
  //             tasksView: new TasksView({
  //               tasksViewId: uuidv4(),
  //               name: 'Unsaved Filter Selection',
  //               kind: {
  //                 case: 'filters',
  //                 value: new TasksViewFilters({
  //                   type: taskType,
  //                   statusIds,
  //                   ownerIds,
  //                   assigneeIds,
  //                   reviewerIds,
  //                   searchQuery,
  //                   dueDateRange: new DateRange({
  //                     dateFrom: fromDate!,
  //                     dateTo: toDate!
  //                   })
  //                 })
  //               },
  //               createdAt: new Date().toISOString(),
  //               lastModifiedAt: new Date().toISOString()
  //             })
  //           })
  //         )
  //       );
  //     }
  //   }, 1000),
  //   []
  // );

  useEffect(() => {
    if (activeTasksViewFilters !== undefined) {
      setTaskType(activeTasksViewFilters.type);
      setStatusIds(activeTasksViewFilters.statusIds);
      setOwnerIds(activeTasksViewFilters.ownerIds);
      setAssigneeIds(activeTasksViewFilters.assigneeIds);
      setReviewerIds(activeTasksViewFilters.reviewerIds);
    }
  }, [activeTasksViewFilters]);

  // useEffect(() => {
  //   debouncedSearch(searchText);

  //   // Cleanup the debounced function
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [searchText, debouncedSearch]);

  const handleGroupByChange = (e: SelectChangeEvent<string>) => {
    dispatch(changeTasksGroupBy(e.target.value as unknown as number));
  };

  // const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   setSearchText(e.target.value);
  // };

  // const handleSearchExpressionChange = (event: SelectChangeEvent<unknown>, child?: React.ReactNode) => {
  //   console.log('handleSearchExpressionChange', event.target.value);
  //   setSearchExpression(event.target.value as SearchExpression);
  // };

  const handleTaskTypeSelect = (selectedTaskType: TaskType) => {
    setTaskType(selectedTaskType);

    dispatch(
      retrieveTasksRequested(
        new RetrieveTasksRequest({
          ...retrieveTasksRequest,
          pageNumber: 1,
          pageSize: 10,
          employeeId,
          type: selectedTaskType,
          statusIds,
          ownerIds,
          assigneeIds,
          reviewerIds,
          taskScope: TaskScope.ALL_TASKS,
          taskOrdering: {
            field: TaskOrderingField.TITLE,
            orderDirection: OrderDirection.ASCENDING
          }
        })
      )
    );
  };

  const handleTaskStatusSelect = (selectedOption: { id: string; name: string }) => {
    setStatusIds((prev) => {
      if (prev.includes(selectedOption.id)) {
        return prev.filter((id) => id !== selectedOption.id);
      } else {
        return [...prev, selectedOption.id];
      }
    });
  };

  const handleTaskOwnerSelect = (selectedOption: EmployeeListItem): void => {
    setOwnerIds((prev) => {
      if (prev.includes(selectedOption.employeeId)) {
        return prev.filter((id) => id !== selectedOption.employeeId);
      } else {
        return [...prev, selectedOption.employeeId];
      }
    });
  };

  const handleTaskAssigneeSelect = (selectedOption: EmployeeListItem): void => {
    setAssigneeIds((prev) => {
      if (prev.includes(selectedOption.employeeId)) {
        return prev.filter((id) => id !== selectedOption.employeeId);
      } else {
        return [...prev, selectedOption.employeeId];
      }
    });
  };

  const handleTaskReviewerSelect = (selectedOption: EmployeeListItem): void => {
    setReviewerIds((prev) => {
      if (prev.includes(selectedOption.employeeId)) {
        return prev.filter((id) => id !== selectedOption.employeeId);
      } else {
        return [...prev, selectedOption.employeeId];
      }
    });
  };

  const handleTaskDueDateApply = () => {
    dispatch(
      retrieveTasksRequested(
        new RetrieveTasksRequest({
          ...retrieveTasksRequest,
          pageNumber: 1,
          pageSize: 10,
          employeeId,
          type: taskType,
          statusIds,
          ownerIds,
          assigneeIds,
          reviewerIds,
          taskScope: TaskScope.ALL_TASKS,
          taskOrdering: {
            field: TaskOrderingField.TITLE,
            orderDirection: OrderDirection.ASCENDING
          },
          dueDateRange: new DateRange({
            dateFrom: fromDate!,
            dateTo: toDate!
          })
        })
      )
    );

    // Only create a new unsaved view if the current active view is not unsaved
    if (unsavedTasksViewIds.includes(activeTasksViewId) || savedTasksViewIds.includes(activeTasksViewId) || activeTasksViewId !== TASKS_DEFAULT_VIEW_ID) {
      const updatedTaskView = {
        ...activeTasksView,
        kind: {
          case: 'filters' as const,
          value: {
            ...activeTasksView.kind.value,
            type: taskType,
            statusIds: statusIds,
            ownerIds: ownerIds,
            assigneeIds: assigneeIds,
            reviewerIds: reviewerIds,
            dueDateRange: new DateRange({
              dateFrom: fromDate!,
              dateTo: toDate!
            })
          }
        },
        lastModifiedAt: new Date().toISOString()
      };

      dispatch(
        updateTempTaskView(
          new CreateTasksViewRequest({
            employeeId,
            tasksView: new TasksView(updatedTaskView)
          })
        )
      );
    } else {
      dispatch(
        createTempTaskView(
          new CreateTasksViewRequest({
            employeeId,
            tasksView: new TasksView({
              tasksViewId: uuidv4(),
              name: 'Unsaved Filter Selection',
              kind: {
                case: 'filters',
                value: new TasksViewFilters({
                  type: taskType,
                  statusIds,
                  ownerIds,
                  assigneeIds,
                  reviewerIds,
                  dueDateRange: new DateRange({
                    dateFrom: fromDate!,
                    dateTo: toDate!
                  })
                })
              },
              createdAt: new Date().toISOString(),
              lastModifiedAt: new Date().toISOString()
            })
          })
        )
      );
    }
  };

  const handleApplyButtonClick = () => {
    dispatch(
      retrieveTasksRequested(
        new RetrieveTasksRequest({
          ...retrieveTasksRequest,
          pageNumber: 1,
          pageSize: 10,
          employeeId,
          type: taskType,
          statusIds,
          ownerIds,
          assigneeIds,
          reviewerIds,
          taskScope: TaskScope.ALL_TASKS,
          taskOrdering: {
            field: TaskOrderingField.TITLE,
            orderDirection: OrderDirection.ASCENDING
          }
        })
      )
    );

    if (unsavedTasksViewIds.includes(activeTasksViewId) || savedTasksViewIds.includes(activeTasksViewId) || activeTasksViewId !== TASKS_DEFAULT_VIEW_ID) {
      const updatedTaskView = {
        ...activeTasksView,
        kind: {
          case: 'filters' as const,
          value: {
            ...activeTasksView.kind.value,
            type: taskType,
            statusIds: statusIds,
            ownerIds: ownerIds,
            assigneeIds: assigneeIds,
            reviewerIds: reviewerIds,
            dueDateRange: new DateRange({
              dateFrom: fromDate!,
              dateTo: toDate!
            })
          }
        },
        lastModifiedAt: new Date().toISOString()
      };

      dispatch(
        updateTempTaskView(
          new CreateTasksViewRequest({
            employeeId,
            tasksView: new TasksView(updatedTaskView)
          })
        )
      );
    } else {
      dispatch(
        createTempTaskView(
          new CreateTasksViewRequest({
            employeeId,
            tasksView: new TasksView({
              tasksViewId: uuidv4(),
              name: 'Unsaved Filter Selection',
              kind: {
                case: 'filters',
                value: new TasksViewFilters({
                  type: taskType,
                  statusIds,
                  ownerIds,
                  assigneeIds,
                  reviewerIds,
                  dueDateRange: new DateRange({
                    dateFrom: fromDate!,
                    dateTo: toDate!
                  })
                })
              },
              createdAt: new Date().toISOString(),
              lastModifiedAt: new Date().toISOString()
            })
          })
        )
      );
    }
  };

  const handleSaveCurrentView = () => {
    dispatch(
      createTaskViewRequested(
        new CreateTasksViewRequest({
          employeeId,
          tasksView: new TasksView({
            ...activeTasksView
          })
        })
      )
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        position: 'sticky',
        top: 0,
        padding: 2,
        borderRadius: 4,
        border: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster,
        backgroundColor: (theme) => theme.palette.customTheme.bgr,
        zIndex: (theme) => theme.zIndex.tooltip + 1
      }}
    >
      <Grid container rowSpacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 9
          }}
        >
          <RowCenterStack gap={1}>
            <TasksTabs />
            <TasksViewsTabs />
          </RowCenterStack>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 3
          }}
        >
          <RowCenterStack gap={1} sx={{ justifyContent: 'flex-end' }}>
            <RowCenterStack
              sx={{
                backgroundColor: isActiveTasksViewUnsaved ? (theme) => theme.palette.customTheme.primaryA : 'transparent',
                padding: 0,
                borderRadius: 1,
                justifyContent: isActiveTasksViewUnsaved ? 'space-between' : 'flex-end'
              }}
            >
              {isActiveTasksViewUnsaved && (
                <Button
                  variant="text"
                  onClick={handleSaveCurrentView}
                  sx={{
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  {t('save_current_view', { ns: 'common' })}
                </Button>
              )}
              <TaskVewFilterDropdown />
            </RowCenterStack>
            <NewTaskButton
              onClick={() => {
                dispatch(openDrawer({ type: DrawerType.TasksAction } as OpenDrawerRequest));
              }}
              startIcon={<ActionsIcon color="#FFFFFF" />}
            >
              {t('new_task', { ns: 'tasks' })}
            </NewTaskButton>
          </RowCenterStack>
        </Grid>
      </Grid>
      {!isActiveViewSingleTaskView && (
        <Collapse in={isFilterRowVisible} timeout={500} unmountOnExit={false}>
          <Grid container spacing={2}>
            <Grid size={2}>
              <FormControl
                fullWidth
                sx={{
                  position: 'relative',
                  width: '100%'
                }}
              >
                <InputLabel
                  shrink
                  id="task_type_id_label"
                  sx={{ fontSize: 12, fontWeight: 400, top: 14, color: (theme) => theme.palette.customTheme.globalContentColorMuted }}
                >
                  Task Type
                </InputLabel>
                <StyledSelect
                  fullWidth
                  labelId="task_type_id_label"
                  id="task_type_id_select"
                  value={taskType}
                  onChange={(event) => handleTaskTypeSelect(event.target.value as TaskType)}
                  IconComponent={ChevronDownIcon}
                  sx={{
                    '& svg': {
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '50%',
                      right: 20,
                      transform: 'translateY(-50%)',
                      pointerEvents: 'none'
                    }
                  }}
                >
                  <MenuItem sx={{ borderRadius: '8px' }} value={TaskType.SINGLE_TASK}>
                    {t(TASK_TYPE_TO_I8N_KEY[TaskType.SINGLE_TASK], { ns: 'tasks' })}
                  </MenuItem>
                  <MenuItem sx={{ borderRadius: '8px' }} value={TaskType.TASK_LIST}>
                    {t(TASK_TYPE_TO_I8N_KEY[TaskType.TASK_LIST], { ns: 'tasks' })}
                  </MenuItem>
                </StyledSelect>
              </FormControl>
            </Grid>
            <Grid size={2}>
              <TasksDropdownSearch
                options={taskStatusOptions}
                values={statusIds}
                label={'Status'}
                onOptionSelect={handleTaskStatusSelect}
                onApply={handleApplyButtonClick}
                onCancel={() => setStatusIds([])}
              />
            </Grid>
            <Grid size={2}>
              <EmployeeDropdownSearch
                options={employeeListItems}
                values={ownerIds}
                label={'Owner'}
                onOptionSelect={handleTaskOwnerSelect}
                onApply={handleApplyButtonClick}
                onCancel={() => setOwnerIds([])}
              />
            </Grid>
            <Grid size={2}>
              <EmployeeDropdownSearch
                options={employeeListItems}
                values={assigneeIds}
                label={'Assignee'}
                onOptionSelect={handleTaskAssigneeSelect}
                onApply={handleApplyButtonClick}
                onCancel={() => setAssigneeIds([])}
              />
            </Grid>
            <Grid size={2}>
              <EmployeeDropdownSearch
                options={employeeListItems}
                values={reviewerIds}
                label={'Reviewer'}
                onOptionSelect={handleTaskReviewerSelect}
                onApply={handleApplyButtonClick}
                onCancel={() => setReviewerIds([])}
              />
            </Grid>
            <Grid size={2}>
              <TasksFromToDateFilter
                label="Due Date"
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                onApply={handleTaskDueDateApply}
              />
            </Grid>
          </Grid>
        </Collapse>
      )}
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        {!isActiveViewSingleTaskView && (
          <RowCenterStack gap={1}>
            <TaskViewModeTabs />
            <Dropdown
              value={groupBy}
              onChange={handleGroupByChange}
              variant="outlined"
              IconComponent={ChevronDownIcon}
              renderValue={(value) => {
                return (
                  <RowCenterStack gap={'4px'} sx={{ height: 20 }}>
                    <Typography sx={{ fontWeight: 400, fontSize: 12, lineHeight: 'normal' }}>Group by |</Typography>
                    {value === TasksGroupBy.DueDate && (
                      <Typography sx={{ fontWeight: 700, fontSize: 12, lineHeight: 'normal' }}>{t('group_by_due_date', { ns: 'tasks' })}</Typography>
                    )}
                    {value === TasksGroupBy.Status && <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{t('group_by_status', { ns: 'tasks' })}</Typography>}
                  </RowCenterStack>
                );
              }}
            >
              <MenuItem sx={{ borderRadius: '8px' }} value={TasksGroupBy.DueDate}>
                {t('group_by_due_date', { ns: 'tasks' })}
              </MenuItem>
              <MenuItem sx={{ borderRadius: '8px' }} value={TasksGroupBy.Status}>
                {t('group_by_status', { ns: 'tasks' })}
              </MenuItem>
            </Dropdown>
          </RowCenterStack>
        )}
        {!isActiveViewSingleTaskView && (
          <RowCenterStack gap={'10px'} sx={{ marginLeft: 'auto' }}>
            {/* <Collapse in={isSearchOpen}>
              <RowCenterStack gap={'4px'}>
                <StyledSelect
                  onChange={handleSearchExpressionChange}
                  value={searchExpression}
                  IconComponent={() => {
                    return (
                      <Box className="MuiSvg-icon">
                        <ChevronDownIcon />
                      </Box>
                    );
                  }}
                  variant="outlined"
                >
                  {searchExpressions.map((se) => (
                    <MenuItem key={se.id} value={se.id}>
                      {t(`${se.name}`, { ns: 'tasks' })}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <Box sx={{ width: 249 }}>
                  <StyledFilledInput label="Search" value={searchText} onChange={(e) => handleTextChange(e)} />
                </Box>
              </RowCenterStack>
            </Collapse> */}
            <TasksHeaderIconButton isActive={isFilterRowVisible} onClick={() => setIsFilterRowVisible(!isFilterRowVisible)}>
              <FilterIcon />
            </TasksHeaderIconButton>
            {/* <TasksHeaderIconButton onClick={() => setIsSearchOpen(!isSearchOpen)}>
              <SearchIcon />
            </TasksHeaderIconButton> */}
          </RowCenterStack>
        )}
      </RowCenterStack>
    </Stack>
  );
};
