import { Stack, StackProps, styled } from '@mui/material';
import { ASSIGNED, COMPLETED, DRAFT, IN_REVIEW, TaskStatus } from '@features/tasks';

interface BoardTaskProps {
  backgroundColor: string;
  textColor: string;
}

interface TasksListTaskRowProps extends StackProps {
  statusId: string;
  isInTaskList?: boolean;
}

export const BoardTask = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status' && prop !== 'isInTaskList'
})<TasksListTaskRowProps>(({ statusId, isInTaskList, theme }) => {
  // Define an array for row styling based on TaskStatus
  const row: Record<string, BoardTaskProps> = {
    [DRAFT]: { backgroundColor: theme.palette.grey[600], textColor: theme.palette.primary.main },
    [ASSIGNED]: { backgroundColor: theme.palette.common.white, textColor: theme.palette.primary.main },
    [IN_REVIEW]: { backgroundColor: 'rgba(252, 199, 93, 0.30)', textColor: theme.palette.primary.main },
    [COMPLETED]: { backgroundColor: theme.palette.customTheme.selectLabel, textColor: theme.palette.primary.main }
  };

  return {
    backgroundColor: row[statusId].backgroundColor,
    color: row[statusId].textColor,
    position: 'relative',
    border: 0,
    borderRadius: 8,
    padding: isInTaskList ? 4 : 16
  };
});
