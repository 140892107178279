import { z } from 'zod';

export interface CreateNewTenantRequest {
  organizationId: string;
  organizationName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  subscriptionPlan: string;
  coupon: string;
}

export const basicInfoFormSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  emailAddress: z.string().email({ message: 'Invalid email address' }),
  organizationName: z.string().min(1, { message: 'Organization name is required' })
});

export type BasicInfoFormType = z.infer<typeof basicInfoFormSchema>;
