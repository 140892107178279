import { Typography } from '@mui/material';
import { StackCenterAlign, StyledHomeLinkButton } from '@/shared';
import { ErrorLayout } from '@app/error/components/ErrorLayout';
import { useTranslation } from 'react-i18next';

export const ForbiddenPage = () => {
  const { t } = useTranslation(['common']);

  return (
    <ErrorLayout>
      <StackCenterAlign gap={2}>
        <Typography variant="h4">{t('403_title', { ns: 'common' })}</Typography>
        <Typography>{t('403_copy', { ns: 'common' })}</Typography>
        <StyledHomeLinkButton
          variant="contained"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          {t('return_to_home', { ns: 'common' })}
        </StyledHomeLinkButton>
      </StackCenterAlign>
    </ErrorLayout>
  );
};
