import { IconButton, IconButtonProps, styled } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

interface TasksHeaderIconButtonProps extends IconButtonProps {
  isActive?: boolean;
}

export const TasksHeaderIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive' && shouldForwardProp(prop)
})<TasksHeaderIconButtonProps>(({ theme, isActive }) => ({
  width: 50,
  height: 50,
  padding: '12px 8px',
  borderRadius: '8px',
  backgroundColor: isActive ? theme.palette.customTheme.drawerBackground : '#F7F5F7',
  transition: theme.transitions.create(['background-color'])
}));
