import { DueDateType, RetrieveTasksResponse, RetrieveTasksViewsResponse, TaskType } from '@thrivea/organization-client';

export enum TaskViewMode {
  List,
  Board,
  // Timeline,
  // Calendar,
  SingleTask
}

export enum TasksGroupBy {
  DueDate,
  Status
}

export interface SelectTaskType {
  taskType: TaskType;
  isEnabled: boolean;
}

export interface TaskTypeOption {
  id: TaskType;
}

export const TASKS_DEFAULT_VIEW_ID = '1111c886-9e33-4c1f-90cf-d292b2b41111';

// Task Statuses
export const DRAFT = '26fd6642-4708-4b95-ad3d-4083e0363825';
export const ASSIGNED = '26fd6642-4708-4b95-ad3d-4083e0363826';
export const IN_REVIEW = '26fd6642-4708-4b95-ad3d-4083e0363827';
export const COMPLETED = '26fd6642-4708-4b95-ad3d-4083e0363828';

export const STATUS_TO_I8N_KEY = {
  [DRAFT]: 'status_draft',
  [ASSIGNED]: 'status_assigned',
  [IN_REVIEW]: 'status_in_review',
  [COMPLETED]: 'status_completed'
};

export const TASK_TYPE_TO_I8N_KEY = {
  [TaskType.SINGLE_TASK]: 'task_type_single_task',
  [TaskType.TASK_LIST]: 'task_type_tasks_list'
};

export enum TaskStatus {
  Draft,
  Assigned,
  InReview,
  Completed
}

export interface Task {
  status: TaskStatus;
  id: string;
  title: string;
  description: string;
  dueDate: string;
  reviewer: string;
  taskList?: Task[];
}

export interface LoadTasksInitially {
  tasksResponse: RetrieveTasksResponse;
  tasksViewsResponse: RetrieveTasksViewsResponse;
}

export interface PagingByDueDates {
  dueDateType: DueDateType;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

export interface PagingByStatuses {
  statusId: string;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

export enum SingleTaskTabs {
  Details,
  Assigned,
  Reviewers,
  Dependencies
}

export enum TaskListTaskTabs {
  Details,
  Tasks,
  Dependencies
}

export const DEPENDS_ON_ID = 'e19e4ad2-e566-4f23-ae23-7b65707722c7';
export const DEPENDS_FOR_ID = 'e19e4ad2-e566-4f23-ae23-7b65707722c8';
