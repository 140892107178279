import { TextField, TextFieldProps, styled } from '@mui/material';

export const StyledTextInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.customTheme.navColorSelected
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: theme.palette.customTheme.navColorSelected
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.customTheme.hoverItem
  },
  '&:hover .MuiInputLabel-root': {
    color: theme.palette.customTheme.hoverItem
  },
  '& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.customTheme.hoverItem
  },
  '&:hover .MuiInputLabel-root.Mui-error': {
    color: theme.palette.customTheme.hoverItem
  }
}));
