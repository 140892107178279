import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { EmployeeRecord, RetrieveEmployeeRecordRequest, UpdateErSectionRequest } from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { retrieveEmployeeRecord, updateErSection } from '@api/erp.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  retrieveEmployeeRecordFailed,
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  updateErSectionFailed,
  updateErSectionRequested,
  updateErSectionSucceeded
} from '@features/employee-record-page';
import { selectErTemplateSectionsByInitialId } from '@features/employee-record-builder';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { Empty } from '@bufbuild/protobuf';

function* retrieveEmployeeRecordRequestedGenerator(action: PayloadAction<RetrieveEmployeeRecordRequest>) {
  try {
    const response: EmployeeRecord = yield call(retrieveEmployeeRecord, action.payload);
    yield put(retrieveEmployeeRecordSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecordFailed());
  }
}

function* updateErSectionRequestedGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  const section = yield select(selectErTemplateSectionsByInitialId);

  try {
    yield call(updateErSection, action.payload);
    yield put(updateErSectionSucceeded(action.payload));

    yield put(showSuccess(t('updated_section', { ns: 'employee_record', name: section[action.payload.erTemplateSectionId].name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateErSectionFailed());
  }
}

function* retrieveEmployeeRecordRequestedWatcher() {
  yield takeLatest(retrieveEmployeeRecordRequested.type, retrieveEmployeeRecordRequestedGenerator);
}

function* updateErSectionRequestedWatcher() {
  yield takeLatest(updateErSectionRequested.type, updateErSectionRequestedGenerator);
}

export function* employeeRecordSagas() {
  yield all([fork(retrieveEmployeeRecordRequestedWatcher), fork(updateErSectionRequestedWatcher)]);
}
