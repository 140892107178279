import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RetrieveSiteListRequest, RetrieveSiteListResponse, RetrieveSitesResponse, Site, SiteListItem, SiteStatus } from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@shared/shared.model';
import { FileWithStatus, UploadedFile, UploadProgress } from '@api/blob-storage.api';
import { SasUri, RetrieveSiteDeleteSasTokenRequest, SasToken } from '@thrivea/auth-client';
import { trimQueryParams } from '@/utils';

interface EmployeesState {
  entities: {
    sites: {
      byId: { [key: string]: Site };
      allIds: string[];
    };
  };
  ui: {
    retrieveSitesRequestedStatus: ActionStatus;
    uploadSiteMediaStatus: ActionStatus;
    siteMediaFileWithStatus: FileWithStatus;
    fileUploadProgress: {
      fileProgress: number;
      totalProgress: number;
    };
  };
  siteWriteSasUri: string;
  siteDeleteSasToken: string;
  siteReadSasToken: string;
}

const initialState: EmployeesState = {
  entities: {
    sites: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveSitesRequestedStatus: ActionStatus.Idle,
    uploadSiteMediaStatus: ActionStatus.Idle,
    siteMediaFileWithStatus: {} as FileWithStatus,
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    }
  },
  siteDeleteSasToken: '',
  siteReadSasToken: '',
  siteWriteSasUri: ''
};

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    retrieveSitesRequested: (state) => {
      state.ui.retrieveSitesRequestedStatus = ActionStatus.Pending;
    },
    retrieveSitesSucceeded: (state, action: PayloadAction<RetrieveSitesResponse>) => {
      const sites = action.payload.sites.filter((d) => !state.entities.sites.byId.hasOwnProperty(d.id)).filter((d) => d.status !== SiteStatus.SITE_DELETED);

      for (const site of sites) {
        state.entities.sites.byId[site.id] = site;
        state.entities.sites.allIds.push(site.id);
      }

      state.ui.retrieveSitesRequestedStatus = ActionStatus.Idle;
    },
    retrieveSitesFailed: (state) => {
      state.ui.retrieveSitesRequestedStatus = ActionStatus.Failed;
    },
    uploadSiteMediaRequested: (state, action: PayloadAction<File>) => {
      state.ui.siteMediaFileWithStatus = {
        isUploaded: false,
        isUploading: false,
        name: action.payload.name,
        file: action.payload,
        url: ''
      } as FileWithStatus;

      state.ui.uploadSiteMediaStatus = ActionStatus.Pending;
    },
    uploadSiteMediaSucceeded: (state) => {
      state.ui.uploadSiteMediaStatus = ActionStatus.Idle;
    },
    uploadSiteMediaFailed: (state) => {
      state.ui.uploadSiteMediaStatus = ActionStatus.Failed;
      state.ui.siteMediaFileWithStatus = {} as FileWithStatus;
    },
    updateSiteFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      state.ui.fileUploadProgress = {
        fileProgress: action.payload.fileProgress,
        totalProgress: action.payload.totalProgress
      };
    },
    deleteSiteMediaRequested: (state, action: PayloadAction<string>) => {},
    deleteSiteMediaSucceeded: (state) => {
      state.ui.siteMediaFileWithStatus = {} as FileWithStatus;
    },
    deleteSiteMediaFailed: (state) => {},
    uploadSiteFile: (state, action: PayloadAction<FileWithStatus>) => {
      state.ui.siteMediaFileWithStatus.isUploading = true;
      state.ui.siteMediaFileWithStatus.isUploaded = false;
    },
    siteFileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      state.ui.siteMediaFileWithStatus.isUploading = false;
      state.ui.siteMediaFileWithStatus.isUploaded = true;
      state.ui.siteMediaFileWithStatus.url = trimQueryParams(action.payload.url);
    },
    retrieveSiteWriteSasUriRequested: (state) => {},
    retrieveSiteWriteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.siteWriteSasUri = action.payload.uri;
    },
    retrieveSiteWriteSasUriFailed: (state) => {},
    retrieveSiteDeleteSasTokenRequested: (state, action: PayloadAction<RetrieveSiteDeleteSasTokenRequest>) => {},
    retrieveSiteDeleteSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.siteDeleteSasToken = action.payload.token;
    },
    retrieveSiteDeleteSasTokenFailed: (state) => {},
    retrieveSiteReadSasTokenRequested: (state) => {},
    retrieveSiteReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.siteReadSasToken = action.payload.token;
    },
    retrieveSiteReadSasTokenFailed: (state) => {}
  }
});

const selectSitesIds = (state: RootState) => state.sites.entities.sites.allIds;
export const selectSitesById = (state: RootState) => state.sites.entities.sites.byId;

export const selectRetrieveSitesRequestedStatus = (state: RootState) => state.sites.ui.retrieveSitesRequestedStatus;
export const selectSites = createSelector([selectSitesById, selectSitesIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectSiteMediaFileWithStatus = (state: RootState) => state.sites.ui.siteMediaFileWithStatus;
export const selectUploadSiteMediaStatus = (state: RootState) => state.sites.ui.uploadSiteMediaStatus;
export const selectSiteFileUploadProgress = (state: RootState) => state.sites.ui.fileUploadProgress.fileProgress;

export const selectIsSiteMediaFileUploading = (state: RootState) => state.sites.ui.siteMediaFileWithStatus.isUploading;
export const selectIsSiteMediaFileUploaded = (state: RootState) => state.sites.ui.siteMediaFileWithStatus.isUploaded;
export const selectSiteMediaFileUrl = (state: RootState) => state.sites.ui.siteMediaFileWithStatus.url;

export const selectSiteWriteSasUri = (state: RootState) => state.sites.siteWriteSasUri;
export const selectSiteDeleteSasToken = (state: RootState) => state.sites.siteDeleteSasToken;
export const selectSiteReadSasToken = (state: RootState) => state.sites.siteReadSasToken;

export const {
  deleteSiteMediaFailed,
  deleteSiteMediaRequested,
  deleteSiteMediaSucceeded,
  retrieveSiteDeleteSasTokenFailed,
  retrieveSiteDeleteSasTokenRequested,
  retrieveSiteDeleteSasTokenSucceeded,
  retrieveSiteReadSasTokenFailed,
  retrieveSiteReadSasTokenRequested,
  retrieveSiteReadSasTokenSucceeded,
  retrieveSitesFailed,
  retrieveSitesRequested,
  retrieveSitesSucceeded,
  retrieveSiteWriteSasUriFailed,
  retrieveSiteWriteSasUriRequested,
  retrieveSiteWriteSasUriSucceeded,
  siteFileUploaded,
  updateSiteFileUploadProgress,
  uploadSiteFile,
  uploadSiteMediaFailed,
  uploadSiteMediaRequested,
  uploadSiteMediaSucceeded
} = sitesSlice.actions;
export default sitesSlice.reducer;
