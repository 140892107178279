import { NumberShortener, SeparatorsAndDecimals } from '@thrivea/organization-client';

// Function to format the number based on separators and shorteners
export const formatNumber = (value: number, separators: SeparatorsAndDecimals, shortener: NumberShortener) => {
  let formattedValue = value;

  if (!value) return '';

  // Apply the number shortener
  if (shortener === NumberShortener.K) {
    formattedValue = value / 1000;
    return `${formattedValue.toFixed(1)}K`;
  } else if (shortener === NumberShortener.M) {
    formattedValue = value / 1000000;
    return `${formattedValue.toFixed(1)}M`;
  } else if (shortener === NumberShortener.B) {
    formattedValue = value / 1000000000;
    return `${formattedValue.toFixed(1)}B`;
  }

  // Format with separators
  switch (separators) {
    case SeparatorsAndDecimals.COMMA_PERIOD:
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20
      })
        .format(formattedValue)
        .replace('.', ',');
    case SeparatorsAndDecimals.PERIOD_COMMA:
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20
      })
        .format(formattedValue)
        .replace(',', '.');
    case SeparatorsAndDecimals.SPACE_PERIOD:
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20
      })
        .format(formattedValue)
        .replace('.', ' ');
    case SeparatorsAndDecimals.SPACE_COMMA:
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20
      })
        .format(formattedValue)
        .replace(',', ' ');
    default:
      return formattedValue.toString();
  }
};
