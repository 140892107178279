import { Stack, StackProps, stackClasses, styled } from '@mui/material';

export const StyledCommentBox = styled(Stack)<StackProps>(({ theme }) => ({
  border: '1px solid',
  borderColor: 'transparent',
  position: 'relative',
  borderRadius: '8px',
  backgroundColor: theme.palette.customTheme.primaryA,
  width: '100%',
  minHeight: 42,
  flexDirection: 'row',
  alignItems: 'flex-end',
  padding: '0 16px',
  margin: 0,
  [`& .${stackClasses.root}`]: {
    padding: '11px 0'
  },
  '& .quill': {
    width: '100%',
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  '& .ql-container.ql-snow': {
    width: '100%'
  },
  '& .ql-customControl': {
    opacity: 0.5,
    marginBottom: '4px'
  },
  '& .ql-toolbar': {
    marginBottom: '-4px'
  },
  '&:focus-within': {
    borderColor: (theme) => theme.palette.secondary.main
  }
}));
