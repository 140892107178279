import { TextField, TextFieldProps, styled } from '@mui/material';

export const CouponTextInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    fontWeight: 700,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit'
    }
  },
  '& .MuiInputLabel-root': {
    fontWeight: 700,
    '&:hover': {
      color: 'inherit'
    },
    '&.Mui-focused': {
      color: 'inherit'
    }
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.customTheme.navColorSelected
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: theme.palette.customTheme.navColorSelected
  },
  '& .MuiFormHelperText-root': {
    margin: 0
  }
}));
