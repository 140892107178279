import { PopoverPaper, Select, SelectProps, styled } from '@mui/material';
import { ChevronDownIcon } from '@/shared';

const StyledMenuProps = {
  elevation: 0,
  sx: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiList-root': {
      margin: '8px 0',
      padding: 0,
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      borderRadius: 4
    },
    '& .MuiMenuItem-root': {
      height: 20,
      margin: 0,
      fontSize: 12,
      backgroundColor: (theme) => theme.palette.common.white,
      transition: (theme) =>
        theme.transitions.create('background-color', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
      '&.Mui-selected': {
        backgroundColor: (theme) => theme.palette.customTheme.selectItem
      },
      '&:hover': {
        backgroundColor: (theme) => theme.palette.common.white
      }
    }
  }
};

export const Dropdown = styled(({ MenuProps, ...props }: SelectProps<any>) => (
  <Select {...props} MenuProps={StyledMenuProps} IconComponent={ChevronDownIcon} />
))(({ theme }) => ({
  minWidth: 126,
  maxWidth: 290,
  height: 20,
  backgroundColor: theme.palette.customTheme.selectItem,
  borderRadius: '7px',
  padding: '0 16px',
  fontWeight: 'bold',
  color: '#1E1E1E',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '0',
    paddingRight: '16px',
    paddingTop: '4px',
    height: 20
  },
  '& svg': {
    width: 12,
    height: 12
  }
}));
