import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  retrievePostsSucceeded,
  retrievePostsFailed,
  publishShoutoutSucceeded,
  publishShoutoutFailed,
  retrievePostsRequested,
  publishShoutoutRequested,
  commentOnPostRequested,
  commentOnPostSucceeded,
  commentOnPostFailed,
  deleteCommentRequested,
  deleteCommentSucceeded,
  deleteCommentFailed,
  reactToPostRequested,
  reactToPostSucceeded,
  reactToPostFailed,
  reactToCommentRequested,
  reactToCommentSucceeded,
  reactToCommentFailed,
  withdrawPostReactionRequested,
  withdrawPostReactionSucceeded,
  withdrawPostReactionFailed,
  withdrawCommentReactionRequested,
  withdrawCommentReactionSucceeded,
  withdrawCommentReactionFailed,
  deletePostRequested,
  deletePostSucceeded,
  deletePostFailed,
  uploadPostFilesFailed,
  deletePostSingleFileRequested,
  deletePostSingleFileSucceeded,
  deletePostSingleFileFailed,
  retrieveEmployeeItemsSucceeded,
  retrieveEmployeeItemsFailed,
  retrieveEmployeeItemsRequested,
  retrievePostCreateDeleteSasUriRequested,
  retrievePostCreateDeleteSasUriSucceeded,
  retrievePostCreateDeleteSasUriFailed,
  selectPostCreateDeleteSasUri,
  selectPostCreateDeleteSasToken,
  retrievePostReadSasTokenRequested,
  retrievePostReadSasTokenSucceeded,
  retrievePostReadSasTokenFailed,
  refreshPostCreateDeleteSasUriRequested,
  refreshPostCreateDeleteSasUriSucceeded,
  refreshPostCreateDeleteSasUriFailed,
  handleFileUploaded,
  handleStartUploadingFile,
  handleUpdateFileUploadProgress,
  retrieveEmployeeRecentPostsSucceeded,
  retrieveEmployeeRecentPostsFailed,
  retrieveEmployeeRecentPostsRequested,
  saveDraftPostSucceeded,
  saveDraftPostFailed,
  retrieveLastDraftPostSucceeded,
  retrieveLastDraftPostFailed,
  deleteDraftPostsSucceeded,
  deleteDraftPostsFailed,
  saveDraftPostRequested,
  deleteDraftPostsRequested,
  retrieveLastDraftPostRequested,
  uploadPostFilesSucceeded,
  selectPostFilesWithStatusForUpload,
  uploadPostFilesRequested,
  editPostSucceeded,
  editPostFailed,
  editPostRequested,
  editCommentSucceeded,
  editCommentFailed,
  editCommentRequested,
  retrieveAudiencesWithPostingRightsByEmployeeIdSucceeded,
  retrieveAudiencesWithPostingRightsByEmployeeIdFailed,
  retrieveAudiencesWithPostingRightsByEmployeeIdRequested,
  retrieveMentionEmployeesSucceeded,
  retrieveMentionEmployeesFailed,
  retrieveMentionEmployeesRequested,
  retrievePostAnalyticsSucceeded,
  retrievePostAnalyticsFailed,
  retrievePostAnalyticsRequested,
  readPostSucceeded,
  readPostFailed,
  readPostRequested,
  retrieveArePostsReadSucceeded,
  retrieveArePostsReadFailed,
  retrieveArePostsReadRequested,
  retrievePostReadAnalyticsSucceeded,
  retrievePostReadAnalyticsFailed,
  retrievePostReadAnalyticsRequested,
  selectPostById,
  setCurrentPostAnalytic,
  retrieveCurrentPostAnalyticAudienceLengthSucceeded,
  retrieveCurrentPostAnalyticAudienceLengthFailed,
  retrieveCurrentPostAnalyticAudienceLengthRequested,
  selectCurrentPostAnalyticId
} from '@features/homepage';
import {
  CommentOnPostRequest,
  Comment,
  PublishShoutoutRequest,
  RetrievePostsRequest,
  RetrievePostsResponse,
  DeleteCommentRequest,
  Post,
  ReactToPostRequest,
  Reaction,
  ReactToCommentRequest,
  WithdrawPostReactionRequest,
  WithdrawCommentReactionRequest,
  DeletePostRequest,
  RetrieveRecentPostsByAuthorRequest,
  RetrieveRecentPostsByAuthorResponse,
  SaveDraftPostRequest,
  RetrieveLastDraftPostRequest,
  DeleteDraftPostsRequest,
  RetrieveAudiencesByIdsRequest,
  Shoutout,
  EditShoutoutRequest,
  EditCommentRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdResponse,
  RetrieveEmployeeIdsByAudienceIdRequest,
  RetrieveEmployeeIdsByAudienceIdResponse,
  ReadPostRequest,
  RetrieveArePostsReadRequest,
  RetrieveArePostsReadResponse,
  RetrievePostReadAnalyticsRequest,
  RetrievePostReadAnalyticsResponse,
  RetrieveAudienceByIdRequest,
  RetrieveAudiencesByIdsResponse
} from '@thrivea/networking-client';
import {
  retrievePosts,
  publishShoutout,
  commentOnPost,
  deleteComment,
  reactToPost,
  reactToComment,
  withdrawPostReaction,
  withdrawCommentReaction,
  deletePost,
  retrieveRecentPostsByAuthor,
  saveDraftPost,
  retrieveLastDraftPost,
  deleteDraftPosts,
  editShoutout,
  editComment,
  readPost,
  retrieveArePostsRead,
  retrievePostReadAnalytics
} from '@api/post.api';
import { Empty } from '@bufbuild/protobuf';
import { deleteFile, FileWithStatus, uploadFilesWithProgressInDirectory, UploadFilesInDirectoryWithProgressRequest } from '@api/blob-storage.api';
import { RetrieveEmployeeItemsResponse, RetrieveEmployeesByIdsRequest } from '@thrivea/organization-client';

import { extractTempIdFromSasUri } from '@utils/extractTempPostIdFromUri';
import { retrieveEmployeeItems } from '@api/employee-profile.api';
import * as Sentry from '@sentry/react';
import { t } from 'i18next';
import { showSuccess, showWarning } from '@features/snackbar';
import { RefreshPostCreateDeleteSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';
import { refreshPostCreateDeleteSasUri, retrievePostReadSasToken, retrievePostCreateDeleteSasUri } from '@api/shared-access-signature.api';
import {
  retrieveAudienceById,
  retrieveAudiencesByIds,
  retrieveAudiencesWithPostingRightsByEmployeeId,
  retrieveEmployeeIdsByAudienceId
} from '@api/audience.api';
import { retrieveEmployeesByIds } from '@api/employees.api';
import uniq from 'lodash/uniq';
import { RetrievePostAnalyticsRequest } from '@thrivea/networking-analytics-client';
import { retrievePostAnalytics } from '@api/network-analytics.api';
import { selectCurrentEmployeeId, selectSignalRConnectionId } from '@features/shared';
import { retrieveAudienceByIdSucceeded } from '@features/admin-settings';
import { DrawerType, closeDrawer, selectCurrentDrawerType } from '@features/drawer';

const MAX_RETRY_COUNT = 5;

function* retrievePostsRequestedGenerator(action: PayloadAction<RetrievePostsRequest>) {
  try {
    const postsResponse: RetrievePostsResponse = yield call(retrievePosts, action.payload);
    const employeeId = yield select(selectCurrentEmployeeId);

    const audienceIds: string[] = [];

    // optimize, maybe fetch comment or reaction authors only when someone wants to see them
    for (const post of postsResponse.posts.map((p) => p.content.value as Shoutout)) {
      audienceIds.push(post.audienceIds[0]);
    }

    const uniqueAudienceIds = uniq(audienceIds);
    let audiencesResponse: RetrieveAudiencesByIdsResponse | undefined;

    if (uniqueAudienceIds.length > 0) {
      audiencesResponse = yield call(retrieveAudiencesByIds, new RetrieveAudiencesByIdsRequest({ audienceIds: uniqueAudienceIds }));
    }

    yield put(retrievePostsSucceeded({ postsResponse, audiencesResponse }));

    if (postsResponse.posts.length > 0) {
      yield put(
        retrieveArePostsReadRequested(
          new RetrieveArePostsReadRequest({
            postIds: postsResponse.posts.map((post) => post.content.value!.id),
            employeeId
          })
        )
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePostsFailed());
  }
}

function* publishShoutoutGenerator(action: PayloadAction<PublishShoutoutRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    const post: Post = yield call(publishShoutout, action.payload, signalRConnectionId);
    yield put(publishShoutoutSucceeded(post));
  } catch (error) {
    Sentry.captureException(error);
    yield put(publishShoutoutFailed());
  }
}

function* commentOnPostGenerator(action: PayloadAction<CommentOnPostRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    const comment: Comment = yield call(commentOnPost, action.payload, signalRConnectionId);
    yield put(
      commentOnPostSucceeded({
        id: comment.id,
        text: comment.text,
        reactions: comment.reactions,
        authorId: action.payload.authorId,
        commentTime: comment.commentTime,
        editedTime: comment.editedTime
      } as Comment)
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(commentOnPostFailed());
  }
}

function* deleteCommentGenerator(action: PayloadAction<DeleteCommentRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    const empty: Empty = yield call(deleteComment, action.payload, signalRConnectionId);
    yield put(deleteCommentSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteCommentFailed());
  }
}

function* reactToPostRequestedGenerator(action: PayloadAction<ReactToPostRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    yield call(reactToPost, action.payload, signalRConnectionId);
    yield put(reactToPostSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(reactToPostFailed(action.payload));
  }
}

function* withdrawPostReactionGenerator(action: PayloadAction<WithdrawPostReactionRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    yield call(withdrawPostReaction, action.payload, signalRConnectionId);
    yield put(withdrawPostReactionSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(withdrawPostReactionFailed(action.payload));
  }
}

function* reactToCommentGenerator(action: PayloadAction<ReactToCommentRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    const _: Reaction = yield call(reactToComment, action.payload, signalRConnectionId);
    yield put(reactToCommentSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(reactToCommentFailed(action.payload));
  }
}

function* withdrawCommentReactionGenerator(action: PayloadAction<WithdrawCommentReactionRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    const _: Empty = yield call(withdrawCommentReaction, action.payload, signalRConnectionId);
    yield put(withdrawCommentReactionSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(withdrawCommentReactionFailed(action.payload));
  }
}

function* deletePostGenerator(action: PayloadAction<DeletePostRequest>) {
  const signalRConnectionId: string = yield select(selectSignalRConnectionId);
  try {
    const _: Empty = yield call(deletePost, action.payload, signalRConnectionId);
    const successMessage = t('post_deleted_success', { ns: 'homepage' });

    const currentPostAnalytic: string = yield select(selectCurrentPostAnalyticId);
    const currentDrawerType: DrawerType | undefined = yield select(selectCurrentDrawerType);
    if (currentDrawerType === DrawerType.PostAnalytics && currentPostAnalytic === action.payload.postId) {
      yield put(closeDrawer());
    }
    yield put(showSuccess(successMessage));
    yield put(deletePostSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deletePostFailed());
  }
}

function* uploadPostFilesRequestedGenerator() {
  let retryCount = 0;
  const postFilesWithStatus: FileWithStatus[] = yield select(selectPostFilesWithStatusForUpload);
  let lastError: unknown = undefined;

  while (retryCount < MAX_RETRY_COUNT) {
    const sasUri: string = yield select(selectPostCreateDeleteSasUri);

    const request: UploadFilesInDirectoryWithProgressRequest = {
      sasUri,
      filesWithStatus: postFilesWithStatus,
      handleUpdateFileUploadProgress,
      handleStartUploadingFile,
      handleFileUploaded
    };

    try {
      yield call(uploadFilesWithProgressInDirectory, request);
      yield put(uploadPostFilesSucceeded());

      return;
    } catch (error) {
      retryCount++;
      lastError = error;

      if (sasUri === '') {
        const newSasUri: SasUri = yield call(retrievePostCreateDeleteSasUri, new Empty());
        yield put(retrievePostCreateDeleteSasUriSucceeded(newSasUri));
      } else {
        const newSasUri: SasUri = yield call(
          refreshPostCreateDeleteSasUri,
          new RefreshPostCreateDeleteSasUriRequest({ postDirectoryName: extractTempIdFromSasUri(sasUri) })
        );
        yield put(refreshPostCreateDeleteSasUriSucceeded(newSasUri));
      }

      yield delay(500 * retryCount + 1);
    }
  }

  Sentry.captureException(lastError);
  yield put(uploadPostFilesFailed());
}

function* deletePostSingleMediaGenerator(action: PayloadAction<string>) {
  let retryCount = 0;
  let lastError: unknown = undefined;

  while (retryCount < MAX_RETRY_COUNT) {
    const sasToken: string = yield select(selectPostCreateDeleteSasToken);

    try {
      yield call(deleteFile, sasToken, action.payload);
      yield put(deletePostSingleFileSucceeded(action.payload));
      return;
    } catch (error) {
      retryCount++;
      lastError = error;

      const sasUri: string = yield select(selectPostCreateDeleteSasUri);

      if (sasUri === '') {
        const sasUri: SasUri = yield call(retrievePostCreateDeleteSasUri, new Empty());
        yield put(retrievePostCreateDeleteSasUriSucceeded(sasUri));
      } else {
        const newSasUri: SasUri = yield call(
          refreshPostCreateDeleteSasUri,
          new RefreshPostCreateDeleteSasUriRequest({ postDirectoryName: extractTempIdFromSasUri(sasUri) })
        );
        yield put(refreshPostCreateDeleteSasUriSucceeded(newSasUri));
      }

      yield delay(500 * retryCount + 1);
    }
  }

  Sentry.captureException(lastError);
  yield put(deletePostSingleFileFailed(action.payload));
}

function* retrieveEmployeeItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeeItemsResponse>) {
  try {
    const employeeItems: RetrieveEmployeeItemsResponse = yield call(retrieveEmployeeItems, action.payload);
    yield put(retrieveEmployeeItemsSucceeded(employeeItems));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeItemsFailed());
  }
}

function* saveDraftPostGenerator(action: PayloadAction<SaveDraftPostRequest>) {
  try {
    yield call(saveDraftPost, action.payload);
    yield put(saveDraftPostSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(saveDraftPostFailed());
  }
}

function* retrieveLastDraftPostGenerator(action: PayloadAction<RetrieveLastDraftPostRequest>) {
  try {
    const draftPost = yield call(retrieveLastDraftPost, action.payload);
    yield put(retrieveLastDraftPostSucceeded(draftPost));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveLastDraftPostFailed());
  }
}

function* deleteDraftPostGenerator(action: PayloadAction<DeleteDraftPostsRequest>) {
  try {
    yield call(deleteDraftPosts, action.payload);
    yield put(deleteDraftPostsSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteDraftPostsFailed());
  }
}

function* editPostGenerator(action: PayloadAction<EditShoutoutRequest>) {
  const successMessage = t('post_updated', {
    ns: 'homepage'
  });
  const failMessage = t('post_update_failed', {
    ns: 'homepage'
  });

  try {
    yield call(editShoutout, action.payload);
    yield put(editPostSucceeded(action.payload));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editPostFailed());
    yield put(showWarning(failMessage));
  }
}

function* retrieveMentionEmployeesRequestedGenerator(action: PayloadAction<RetrieveEmployeeIdsByAudienceIdRequest>) {
  try {
    const response: RetrieveEmployeeIdsByAudienceIdResponse = yield call(retrieveEmployeeIdsByAudienceId, action.payload);
    const { employees } = yield call(
      retrieveEmployeesByIds,
      new RetrieveEmployeesByIdsRequest({
        employeeIds: response.employees.map((e) => e.employeeId)
      })
    );
    yield put(retrieveMentionEmployeesSucceeded(employees));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveMentionEmployeesFailed());
  }
}

function* retrievePostCreateDeleteSasUriRequestedGenerator() {
  try {
    const sasUri: SasUri = yield call(retrievePostCreateDeleteSasUri, new Empty());
    yield put(retrievePostCreateDeleteSasUriSucceeded(sasUri));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePostCreateDeleteSasUriFailed());
  }
}

function* retrievePostReadSasTokenRequestedGenerator() {
  try {
    const sasToken: SasToken = yield call(retrievePostReadSasToken, new Empty());
    yield put(retrievePostReadSasTokenSucceeded(sasToken));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePostReadSasTokenFailed());
  }
}

function* refreshPostCreateDeleteSasUriRequestedGenerator(action: PayloadAction<RefreshPostCreateDeleteSasUriRequest>) {
  try {
    const sasUri: SasUri = yield call(refreshPostCreateDeleteSasUri, action.payload);
    yield put(refreshPostCreateDeleteSasUriSucceeded(sasUri));
  } catch (error) {
    Sentry.captureException(error);
    yield put(refreshPostCreateDeleteSasUriFailed());
  }
}

function* retrieveEmployeeRecentPostsGenerator(action: PayloadAction<RetrieveRecentPostsByAuthorRequest>) {
  try {
    const response: RetrieveRecentPostsByAuthorResponse = yield call(retrieveRecentPostsByAuthor, action.payload);
    yield put(retrieveEmployeeRecentPostsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecentPostsFailed());
  }
}

function* editCommentGenerator(action: PayloadAction<EditCommentRequest>) {
  const successMessage = t('comment_updated', {
    ns: 'homepage'
  });
  const failMessage = t('comment_update_failed', {
    ns: 'homepage'
  });

  try {
    yield call(editComment, action.payload);
    yield put(editCommentSucceeded(action.payload));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editCommentFailed());
    yield put(showWarning(failMessage));
  }
}

function* retrieveAudiencesWithPostingRightsByEmployeeIdRequestedGenerator(action: PayloadAction<RetrieveAudiencesWithPostingRightsByEmployeeIdRequest>) {
  try {
    const response: RetrieveAudiencesWithPostingRightsByEmployeeIdResponse = yield call(retrieveAudiencesWithPostingRightsByEmployeeId, action.payload);
    yield put(retrieveAudiencesWithPostingRightsByEmployeeIdSucceeded(response));
    if (response.audiences.length > 0) {
      yield put(
        retrieveMentionEmployeesRequested(
          new RetrieveEmployeeIdsByAudienceIdRequest({
            audienceId: response.audiences[0].audienceId
          })
        )
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudiencesWithPostingRightsByEmployeeIdFailed());
  }
}

function* retrievePostAnalyticsRequestedGenerator(action: PayloadAction<RetrievePostAnalyticsRequest>) {
  const post = yield select((state) => selectPostById(state, action.payload.postId));
  try {
    const { postAnalytics, audience } = yield all({
      postAnalytics: call(retrievePostAnalytics, action.payload),
      audience: retrieveAudienceById(
        new RetrieveAudienceByIdRequest({
          audienceId: post.audienceIds[0]
        })
      )
    });

    yield put(retrievePostAnalyticsSucceeded(postAnalytics));
    yield put(retrieveAudienceByIdSucceeded(audience));
    yield put(setCurrentPostAnalytic(post));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePostAnalyticsFailed());
  }
}

function* readPostRequestedGenerator(action: PayloadAction<ReadPostRequest>) {
  try {
    const response: Empty = yield call(readPost, action.payload);
    yield put(readPostSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(readPostFailed());
  }
}

function* retrieveArePostsReadRequestedGenerator(action: PayloadAction<RetrieveArePostsReadRequest>) {
  try {
    const response: RetrieveArePostsReadResponse = yield call(retrieveArePostsRead, action.payload);
    yield put(retrieveArePostsReadSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveArePostsReadFailed());
  }
}

function* retrievePostReadAnalyticsRequestedGenerator(action: PayloadAction<RetrievePostReadAnalyticsRequest>) {
  try {
    const response: RetrievePostReadAnalyticsResponse = yield call(retrievePostReadAnalytics, action.payload);
    yield put(retrievePostReadAnalyticsSucceeded(response));
  } catch (error) {
    // Sentry.captureException(error);
    yield put(retrievePostReadAnalyticsFailed());
  }
}

function* retrieveCurrentPostAnalyticAudienceLengthRequestedGenerator(action: PayloadAction<RetrieveEmployeeIdsByAudienceIdRequest>) {
  try {
    const response: RetrieveEmployeeIdsByAudienceIdResponse = yield call(retrieveEmployeeIdsByAudienceId, action.payload);
    const { employees } = yield call(
      retrieveEmployeesByIds,
      new RetrieveEmployeesByIdsRequest({
        employeeIds: response.employees.map((e) => e.employeeId)
      })
    );
    yield put(retrieveCurrentPostAnalyticAudienceLengthSucceeded(employees));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveCurrentPostAnalyticAudienceLengthFailed());
  }
}

function* retrievePostsRequestedWatcher() {
  yield takeLatest(retrievePostsRequested.type, retrievePostsRequestedGenerator);
}

function* publishShoutoutRequestedWatcher() {
  yield takeLatest(publishShoutoutRequested.type, publishShoutoutGenerator);
}

function* commentOnPostRequestedWatcher() {
  yield takeLatest(commentOnPostRequested.type, commentOnPostGenerator);
}

function* deleteCommentRequestedWatcher() {
  yield takeLatest(deleteCommentRequested.type, deleteCommentGenerator);
}

function* reactToPostRequestedWatcher() {
  yield takeLatest(reactToPostRequested.type, reactToPostRequestedGenerator);
}

function* withdrawPostReactionRequestedWatcher() {
  yield takeLatest(withdrawPostReactionRequested.type, withdrawPostReactionGenerator);
}

function* reactToCommentRequestedWatcher() {
  yield takeLatest(reactToCommentRequested.type, reactToCommentGenerator);
}

function* withdrawCommentReactionRequestedWatcher() {
  yield takeLatest(withdrawCommentReactionRequested.type, withdrawCommentReactionGenerator);
}

function* deletePostRequestedWatcher() {
  yield takeLatest(deletePostRequested.type, deletePostGenerator);
}

function* uploadPostFilesRequestedWatcher() {
  yield takeLatest(uploadPostFilesRequested.type, uploadPostFilesRequestedGenerator);
}

function* deletePostSingleMediaWatcher() {
  yield takeLatest(deletePostSingleFileRequested.type, deletePostSingleMediaGenerator);
}

function* retrieveEmployeeItemsRequestedWatcher() {
  yield takeLatest(retrieveEmployeeItemsRequested.type, retrieveEmployeeItemsRequestedGenerator);
}

function* retrievePostCreateDeleteSasUriRequestedWatcher() {
  yield takeLatest(retrievePostCreateDeleteSasUriRequested.type, retrievePostCreateDeleteSasUriRequestedGenerator);
}

function* retrievePostReadSasTokenRequestedWatcher() {
  yield takeLatest(retrievePostReadSasTokenRequested.type, retrievePostReadSasTokenRequestedGenerator);
}

function* refreshPostCreateDeleteSasUriRequestedWatcher() {
  yield takeLatest(refreshPostCreateDeleteSasUriRequested.type, refreshPostCreateDeleteSasUriRequestedGenerator);
}

function* retrieveEmployeeRecentPostsWatcher() {
  yield takeLatest(retrieveEmployeeRecentPostsRequested.type, retrieveEmployeeRecentPostsGenerator);
}

function* saveDraftPostWatcher() {
  yield takeLatest(saveDraftPostRequested.type, saveDraftPostGenerator);
}

function* retrieveLastDraftPostWatcher() {
  yield takeLatest(retrieveLastDraftPostRequested.type, retrieveLastDraftPostGenerator);
}

function* deleteDraftPostWatcher() {
  yield takeLatest(deleteDraftPostsRequested.type, deleteDraftPostGenerator);
}
function* editPostWatcher() {
  yield takeLatest(editPostRequested.type, editPostGenerator);
}

function* editCommentWatcher() {
  yield takeLatest(editCommentRequested.type, editCommentGenerator);
}

function* retrieveAudiencesWithPostingRightsByEmployeeIdRequestedWatcher() {
  yield takeLatest(retrieveAudiencesWithPostingRightsByEmployeeIdRequested.type, retrieveAudiencesWithPostingRightsByEmployeeIdRequestedGenerator);
}

function* retrieveMentionEmployeesRequestedWatcher() {
  yield takeLatest(retrieveMentionEmployeesRequested.type, retrieveMentionEmployeesRequestedGenerator);
}

function* retrievePostAnalyticsRequestedWatcher() {
  yield takeLatest(retrievePostAnalyticsRequested.type, retrievePostAnalyticsRequestedGenerator);
}

function* readPostRequestedWatcher() {
  yield takeLatest(readPostRequested.type, readPostRequestedGenerator);
}

function* retrieveArePostsReadRequestedWatcher() {
  yield takeLatest(retrieveArePostsReadRequested.type, retrieveArePostsReadRequestedGenerator);
}

function* retrievePostReadAnalyticsRequestedWatcher() {
  yield takeLatest(retrievePostReadAnalyticsRequested.type, retrievePostReadAnalyticsRequestedGenerator);
}

function* retrieveCurrentPostAnalyticAudienceLengthRequestedWatcher() {
  yield takeLatest(retrieveCurrentPostAnalyticAudienceLengthRequested.type, retrieveCurrentPostAnalyticAudienceLengthRequestedGenerator);
}

export function* postsSagas() {
  yield all([
    fork(retrievePostsRequestedWatcher),
    fork(publishShoutoutRequestedWatcher),
    fork(commentOnPostRequestedWatcher),
    fork(deleteCommentRequestedWatcher),
    fork(reactToPostRequestedWatcher),
    fork(withdrawPostReactionRequestedWatcher),
    fork(reactToCommentRequestedWatcher),
    fork(withdrawCommentReactionRequestedWatcher),
    fork(deletePostRequestedWatcher),
    fork(uploadPostFilesRequestedWatcher),
    fork(deletePostSingleMediaWatcher),
    fork(retrieveEmployeeRecentPostsWatcher),
    fork(saveDraftPostWatcher),
    fork(deleteDraftPostWatcher),
    fork(retrieveLastDraftPostWatcher),
    fork(editPostWatcher),
    fork(editCommentWatcher),
    fork(retrieveAudiencesWithPostingRightsByEmployeeIdRequestedWatcher),
    fork(retrieveMentionEmployeesRequestedWatcher),
    fork(retrieveEmployeeItemsRequestedWatcher),
    fork(retrievePostCreateDeleteSasUriRequestedWatcher),
    fork(retrievePostReadSasTokenRequestedWatcher),
    fork(retrievePostAnalyticsRequestedWatcher),
    fork(refreshPostCreateDeleteSasUriRequestedWatcher),
    fork(readPostRequestedWatcher),
    fork(retrieveArePostsReadRequestedWatcher),
    fork(retrievePostReadAnalyticsRequestedWatcher),
    fork(retrieveCurrentPostAnalyticAudienceLengthRequestedWatcher)
  ]);
}
