import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  WorkCategoryTable,
  createWorkSchema,
  removeNewWorkEvent,
  selectEmployeeItems,
  selectEmployeeProfileAndCoverReadSasToken,
  selectNewWorkEvents,
  selectProfileNotification,
  selectWorkCategory,
  selectWorkCategoryTableData,
  updateWorkRequested
} from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Avatar, Box, Button, CircularProgress, IconButton, ListItem, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  ChevronDownIcon,
  StyledFilledInput,
  StyledFormWrapper,
  StyledFormHeader,
  ActionStatus,
  StyledAutocomplete,
  StyledFormLabel
} from '@/shared';
import { UpdateWorkRequest, Work } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { initials } from '@utils/initials';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { blobWithSasTokenUrl } from '@utils/blobWithSasTokenUrl';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';
import { openDrawer, DrawerType, OpenDrawerRequest } from '@features/drawer';

interface WorkCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const WorkCategory = forwardRef<HTMLDivElement, WorkCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('work', { ns: 'employee_profile' });
  const id = useId();
  const dispatch = useAppDispatch();
  const workCategory = useAppSelector(selectWorkCategory);
  const workEvents = useAppSelector(selectWorkCategoryTableData);
  const newEvents = useAppSelector(selectNewWorkEvents);
  const employeeItems = useAppSelector(selectEmployeeItems);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'work';
  const [isEditable, setIsEditable] = useState(false);
  const workSchema = useMemo(() => createWorkSchema(t), [t]);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const {
    formState: { dirtyFields, errors },
    control,
    handleSubmit,
    getValues,
    reset,
    setValue
  } = useForm<Work>({
    mode: 'all',
    resolver: zodResolver(workSchema),
    defaultValues: {
      buddyId: workCategory.buddyId,
      hrbpId: workCategory.buddyId,
      payrollManagerId: workCategory.payrollManagerId,
      itAdminId: workCategory.itAdminId,
      customEmployeeId: workCategory.customEmployeeId,
      isManager: workCategory.isManager,
      workEvents: workCategory.workEvents
    }
  });

  const handleCloseEditable = () => {
    dispatch(removeNewWorkEvent());
    setIsEditable(false);
    reset({
      buddyId: workCategory.buddyId,
      hrbpId: workCategory.buddyId,
      payrollManagerId: workCategory.payrollManagerId,
      itAdminId: workCategory.itAdminId,
      customEmployeeId: workCategory.customEmployeeId,
      isManager: workCategory.isManager,
      workEvents: workCategory.workEvents
    });
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleSetEditable = () => {
    setIsEditable(true);
  };

  const onSubmit = (data: Work) => {
    dispatch(
      updateWorkRequested({
        employeeId,
        buddyId: data.buddyId,
        hrbpId: data.hrbpId,
        payrollManagerId: data.payrollManagerId,
        itAdminId: data.itAdminId,
        customEmployeeId: data.customEmployeeId,
        workEvents: workEvents
      } as UpdateWorkRequest)
    );
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      buddyId: workCategory.buddyId,
      hrbpId: workCategory.hrbpId,
      payrollManagerId: workCategory.payrollManagerId,
      itAdminId: workCategory.itAdminId,
      customEmployeeId: workCategory.customEmployeeId,
      isManager: workCategory.isManager
    });
  }, [workCategory, newEvents]);

  useEffect(() => {
    // Check if newEvents exists which means new event is added and set isDirty to true
    if (newEvents.length > 0) {
      setValue('workEvents', workCategory.workEvents, { shouldDirty: true });
    }
  }, [newEvents]);

  const handleEmployeeDrawerOpen = (employeeId: string) => {
    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader className="Mui-ProfileFiledHeader" isEditable={isEditable}>
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'work' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <Button
                variant="contained"
                onClick={handleCloseEditable}
                startIcon={<CancelEditIcon />}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.black,
                  color: (theme) => theme.palette.common.white,
                  padding: '12px 20px 12px 26px',
                  height: 43
                }}
              >
                {t('cancel', { ns: 'common' })}
              </Button>
              <Button
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.dark,
                  color: (theme) => theme.palette.common.white,
                  padding: '12px 24px 12px 20px',
                  height: 43
                }}
              >
                Done
              </Button>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="buddyId"
                control={control}
                render={({ field }) => (
                  <StyledAutocomplete
                    {...field}
                    disabled={!isEditable}
                    popupIcon={<ChevronDownIcon />}
                    disableClearable={field.value !== null}
                    id={id + field.name}
                    options={employeeItems.allIds}
                    getOptionLabel={(option) => employeeItems.byId[option].displayName}
                    value={employeeItems.allIds.find((ei) => ei === getValues('buddyId')) || null}
                    onChange={(_, value) => {
                      field.onChange(value!);
                    }}
                    renderInput={(params) =>
                      !isEditable ? (
                        <>
                          <StyledFormLabel shrink disabled sx={{ pl: 1.5 }}>
                            {t(snakeCase(field.name), { ns: 'employee_profile' })}
                          </StyledFormLabel>
                          <Button
                            onClick={() => handleEmployeeDrawerOpen(field.value)}
                            variant="text"
                            sx={{
                              color: (theme) => theme.palette.customTheme.focusItem,
                              pl: 1,
                              pb: 0.5,
                              backgroundColor: 'transparent',
                              textDecoration: 'underline',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {employeeItems.byId.hasOwnProperty(field.value) ? employeeItems.byId[field.value].displayName : ''}
                          </Button>
                        </>
                      ) : (
                        <StyledFilledInput {...params} label={t(snakeCase(field.name), { ns: 'employee_profile' })} />
                      )
                    }
                    renderOption={(props, option) => (
                      <ListItem {...props} key={crypto.randomUUID()}>
                        <RowCenterStack
                          gap={2}
                          sx={{
                            my: 1,
                            px: 2
                          }}
                        >
                          <Avatar src={blobWithSasTokenUrl(employeeItems.byId[option].profilePictureUrl, employeeProfileSasToken)}>
                            {initials(employeeItems.byId[option].displayName)}
                          </Avatar>
                          <Typography>{employeeItems.byId[option].displayName}</Typography>
                        </RowCenterStack>
                      </ListItem>
                    )}
                  />
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="hrbpId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disabled={!isEditable}
                    disableClearable={field.value !== null}
                    popupIcon={<ChevronDownIcon />}
                    id={id + field.name}
                    options={employeeItems.allIds}
                    getOptionLabel={(option) => employeeItems.byId[option].displayName}
                    value={employeeItems.allIds.find((ei) => ei === getValues('hrbpId')) || null}
                    onChange={(_, value) => {
                      field.onChange(value!);
                    }}
                    renderInput={(params) =>
                      !isEditable ? (
                        <>
                          <StyledFormLabel shrink disabled sx={{ pl: 1.5 }}>
                            {t(snakeCase(field.name), { ns: 'employee_profile' })}
                          </StyledFormLabel>
                          <Button
                            onClick={() => handleEmployeeDrawerOpen(field.value)}
                            variant="text"
                            sx={{
                              color: (theme) => theme.palette.customTheme.focusItem,
                              pl: 1,
                              pb: 0.5,
                              backgroundColor: 'transparent',
                              textDecoration: 'underline',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {employeeItems.byId.hasOwnProperty(field.value) ? employeeItems.byId[field.value].displayName : ''}
                          </Button>
                        </>
                      ) : (
                        <StyledFilledInput {...params} label={t(snakeCase(field.name), { ns: 'employee_profile' })} />
                      )
                    }
                    renderOption={(props, option) => (
                      <ListItem {...props} key={crypto.randomUUID()}>
                        <RowCenterStack
                          gap={2}
                          sx={{
                            my: 1,
                            px: 2
                          }}
                        >
                          <Avatar src={blobWithSasTokenUrl(employeeItems.byId[option].profilePictureUrl, employeeProfileSasToken)}>
                            {initials(employeeItems.byId[option].displayName)}
                          </Avatar>
                          <Typography>{employeeItems.byId[option].displayName}</Typography>
                        </RowCenterStack>
                      </ListItem>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="payrollManagerId"
                control={control}
                render={({ field }) => (
                  <StyledAutocomplete
                    disabled={!isEditable}
                    disableClearable={field.value !== null}
                    popupIcon={<ChevronDownIcon />}
                    id={id + field.name}
                    options={employeeItems.allIds}
                    getOptionLabel={(option) => employeeItems.byId[option].displayName}
                    value={employeeItems.allIds.find((ei) => ei === getValues(field.name)) || null}
                    onChange={(_, value) => {
                      field.onChange(value!);
                    }}
                    renderInput={(params) =>
                      !isEditable ? (
                        <>
                          <StyledFormLabel shrink disabled sx={{ pl: 1.5 }}>
                            {t(snakeCase(field.name), { ns: 'employee_profile' })}
                          </StyledFormLabel>
                          <Button
                            onClick={() => handleEmployeeDrawerOpen(field.value)}
                            variant="text"
                            sx={{
                              color: (theme) => theme.palette.customTheme.focusItem,
                              pl: 1,
                              pb: 0.5,
                              backgroundColor: 'transparent',
                              textDecoration: 'underline',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {employeeItems.byId.hasOwnProperty(field.value) ? employeeItems.byId[field.value].displayName : ''}
                          </Button>
                        </>
                      ) : (
                        <StyledFilledInput {...params} label={t(snakeCase(field.name), { ns: 'employee_profile' })} />
                      )
                    }
                    renderOption={(props, option) => (
                      <ListItem {...props} key={crypto.randomUUID()}>
                        <RowCenterStack
                          gap={2}
                          sx={{
                            my: 1,
                            px: 2
                          }}
                        >
                          <Avatar src={blobWithSasTokenUrl(employeeItems.byId[option].profilePictureUrl, employeeProfileSasToken)}>
                            {initials(employeeItems.byId[option].displayName)}
                          </Avatar>
                          <Typography>{employeeItems.byId[option].displayName}</Typography>
                        </RowCenterStack>
                      </ListItem>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="itAdminId"
                control={control}
                render={({ field }) => (
                  <StyledAutocomplete
                    disabled={!isEditable}
                    disableClearable={field.value !== null}
                    popupIcon={<ChevronDownIcon />}
                    id={id + field.name}
                    options={employeeItems.allIds}
                    getOptionLabel={(option) => employeeItems.byId[option].displayName}
                    value={employeeItems.allIds.find((ei) => ei === getValues(field.name)) || null}
                    onChange={(_, value) => {
                      field.onChange(value!);
                    }}
                    renderInput={(params) =>
                      !isEditable ? (
                        <>
                          <StyledFormLabel shrink disabled sx={{ pl: 1.5 }}>
                            {t(snakeCase(field.name), { ns: 'employee_profile' })}
                          </StyledFormLabel>
                          <Button
                            onClick={() => handleEmployeeDrawerOpen(field.value)}
                            variant="text"
                            sx={{
                              color: (theme) => theme.palette.customTheme.focusItem,
                              pl: 1,
                              pb: 0.5,
                              backgroundColor: 'transparent',
                              textDecoration: 'underline',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {employeeItems.byId.hasOwnProperty(field.value) ? employeeItems.byId[field.value].displayName : ''}
                          </Button>
                        </>
                      ) : (
                        <StyledFilledInput {...params} label={t(snakeCase(field.name), { ns: 'employee_profile' })} />
                      )
                    }
                    renderOption={(props, option) => (
                      <ListItem {...props} key={crypto.randomUUID()}>
                        <RowCenterStack
                          gap={2}
                          sx={{
                            my: 1,
                            px: 2
                          }}
                        >
                          <Avatar src={blobWithSasTokenUrl(employeeItems.byId[option].profilePictureUrl, employeeProfileSasToken)}>
                            {initials(employeeItems.byId[option].displayName)}
                          </Avatar>
                          <Typography>{employeeItems.byId[option].displayName}</Typography>
                        </RowCenterStack>
                      </ListItem>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="customEmployeeId"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    disabled
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="isManager"
                control={control}
                disabled
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    disabled
                    value={getValues(field.name) ? 'Yes' : 'No'}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <WorkCategoryTable handleSetEditable={handleSetEditable} employeeId={employeeId} />
    </StyledFormWrapper>
  );
});
