import { Chip, chipClasses, ChipProps, styled } from '@mui/material';
import { EmployeeStatus } from '@thrivea/organization-client';

interface EmployeeStatusChipStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface EmployeeStatusChipProps extends ChipProps {
  status: EmployeeStatus;
}

export const EmployeeStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status'
})<EmployeeStatusChipProps>(({ status, theme }) => {
  const row: Record<string, EmployeeStatusChipStyleProps> = {
    [EmployeeStatus.ACTIVE]: { backgroundColor: theme.palette.success.main, textColor: theme.palette.common.white },
    [EmployeeStatus.INVITED]: { backgroundColor: theme.palette.warning.main, textColor: theme.palette.common.white },
    [EmployeeStatus.INACTIVE]: { backgroundColor: theme.palette.error.main, textColor: theme.palette.common.white }
  };

  return {
    backgroundColor: row[status].backgroundColor,
    color: row[status].textColor,
    width: 90,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
