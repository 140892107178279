import {
  Box,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  TableSortLabel,
  Select,
  MenuItem,
  Pagination,
  PaginationItem
} from '@mui/material';
import { ActionStatus, IconSortDown, IconSortNeutral, IconSortUp, RowCenterStack, StyledTableHead } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  AssetsListViewSkeleton,
  sortFilesBy,
  sortByField,
  invertToOrderDirection,
  invertToOrder,
  toSortFile,
  toOrderDirection} from '@features/assets';
import { OrderDirection, RetrieveFilesRequest, SortFile } from '@thrivea/organization-client';
import { ExpandMore } from '@mui/icons-material';
import { selectCurrentEmployeeId } from '@features/shared';
import { TasksAssetsNoFiles } from './TasksAssetsNoFiles';
import { TasksAssetsModalFileRow } from './TasksAssetsModalFileRow';
import {
  retrieveTaskFilesRequested,
  selectEditableComment,
  selectMemoizedTaskFilePagesStatusText,
  selectNewCommentUrls,
  selectRetrieveTaskFilesStatus,
  selectSingleTaskView,
  selectTaskFilesOnly,
  selectTaskFilesPageCount,
  selectTaskFilesPageNumber,
  selectTaskFilesPageSize,
  selectTaskSelectedFolderId,
  selectTasksMyFilesSortBySortFile,
  selectTasksMyFilesSorting,
  setCommentAttachmentUrls,
  setNewCommentAttachmentUrls
} from '@features/tasks';

const FILE_PAGE_SIZES = [5, 10, 25];

export const TaskNewCommentAssetsListView = () => {
  const dispatch = useAppDispatch();

  const selectedTaskFolderId = useAppSelector(selectTaskSelectedFolderId);
  const taskFiles = useAppSelector(selectTaskFilesOnly);
  const taskFilesStatus = useAppSelector(selectRetrieveTaskFilesStatus);
  const taskFilesPageSize = useAppSelector(selectTaskFilesPageSize);
  const taskFilesPageNumber = useAppSelector(selectTaskFilesPageNumber);
  const taskFilesPageCount = useAppSelector(selectTaskFilesPageCount);
  const taskFilePageStatusText = useAppSelector(selectMemoizedTaskFilePagesStatusText);
  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const taskFilesSorting = useAppSelector(selectTasksMyFilesSorting);
  const taskSortFile = useAppSelector(selectTasksMyFilesSortBySortFile);
  const editableComment = useAppSelector(selectEditableComment);
  const taskFilesTotalCount = taskFiles.length;

  const singleTaskView = useAppSelector(selectSingleTaskView);
  const newCommentAttachmentUrls = useAppSelector(selectNewCommentUrls);

  const isSelected = (url: string) => newCommentAttachmentUrls.includes(url) || singleTaskView.attachmentUrls?.includes(url);

  const handleSortLabelClick = (sortFile: SortFile) => {
    dispatch(sortFilesBy({ sortBy: sortByField(sortFile), sortDirection: invertToOrder(taskFilesSorting.sortDirection) }));
    dispatch(
      retrieveTaskFilesRequested(
        new RetrieveFilesRequest({
          employeeId,
          folderId: selectedTaskFolderId,
          pageNumber: taskFilesPageNumber,
          pageSize: taskFilesPageSize,
          sortBy: sortFile,
          sortDirection: invertToOrderDirection(taskFilesSorting.sortDirection)
        })
      )
    );
  };

  const handleResultsPerPageSelect = (pageSize: number) => {
    dispatch(
      retrieveTaskFilesRequested(
        new RetrieveFilesRequest({
          employeeId,
          folderId: selectedTaskFolderId,
          pageNumber: taskFilesPageNumber,
          pageSize,
          sortBy: toSortFile(taskFilesSorting.sortBy),
          sortDirection: toOrderDirection(taskFilesSorting.sortDirection)
        })
      )
    );
  };

  const handlePaginationChange = (_event: unknown, newPage: number) => {
    if (newPage < 1 || newPage > taskFilesPageCount) return;

    dispatch(
      retrieveTaskFilesRequested(
        new RetrieveFilesRequest({
          employeeId,
          folderId: selectedTaskFolderId,
          pageNumber: newPage,
          pageSize: taskFilesPageSize,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.ASCENDING
        })
      )
    );
  };

  const handleSelectSingleClick = (url: string) => {
    const selectedIndex = newCommentAttachmentUrls.indexOf(url);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...newCommentAttachmentUrls, url];
    } else {
      newSelected = newCommentAttachmentUrls.filter((item) => item !== url);
    }

    if (editableComment) {
      dispatch(setCommentAttachmentUrls(newSelected));
    } else {
      dispatch(setNewCommentAttachmentUrls(newSelected));
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    >
      {taskFilesTotalCount === 0 && <TasksAssetsNoFiles />}
      {taskFilesTotalCount > 0 && (
        <Box
          sx={{
            position: 'relative',
            height: '100%'
          }}
        >
          <TableContainer
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              maxHeight: 'calc(100% - 60px)',
              backgroundColor: (theme) => theme.palette.common.white,
              '& .os-scrollbar-horizontal': {
                paddingLeft: '550px'
              }
            }}
          >
            <Table stickyHeader aria-labelledby="tableTitle" size="medium">
              <StyledTableHead
                sx={{
                  padding: 2
                }}
              >
                <TableRow
                  sx={{
                    backgroundColor: (theme) => theme.palette.common.white,
                    '& .MuiTableCell-root': {
                      '&:nth-of-type(1)': {
                        border: 0,
                        borderRight: '1px solid',
                        borderColor: (theme) => theme.palette.secondary.main
                      }
                    }
                  }}
                >
                  <TableCell
                    sx={{
                      width: 702,
                      padding: '0 20px 0 16px'
                    }}
                    align="left"
                  >
                    <RowCenterStack sx={{ gap: '20px', position: 'relative' }}>
                      {/* <StyledCheckbox
                        indeterminate={newCommentAttachmentUrls.length >= 1 && newCommentAttachmentUrls.length < taskFilesTotalCount}
                        checked={newCommentAttachmentUrls.length === taskFilesTotalCount}
                        onChange={handleSelectAllClick}
                        icon={<ThinBorderCheckbox sx={{ fontSize: 28 }} />}
                      /> */}
                      <TableSortLabel
                        active={taskSortFile === SortFile.NAME}
                        direction={taskFilesSorting.sortDirection}
                        onClick={() => handleSortLabelClick(SortFile.NAME)}
                        sx={{
                          gap: '20px',
                          position: 'relative'
                        }}
                        IconComponent={() => {
                          if (taskSortFile !== SortFile.NAME) return <IconSortNeutral color={'#D5E8E5'} />;

                          return taskFilesSorting.sortDirection === 'asc' ? <IconSortUp /> : <IconSortDown />;
                        }}
                      >
                        <Typography sx={{ fontWeight: 700, fontSize: 14 }}>Document</Typography>
                      </TableSortLabel>
                    </RowCenterStack>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 260,
                      fontWeight: 700
                    }}
                    align="left"
                  >
                    <TableSortLabel
                      active={taskSortFile === SortFile.TYPE}
                      direction={taskFilesSorting.sortDirection}
                      onClick={() => handleSortLabelClick(SortFile.TYPE)}
                      sx={{
                        gap: '20px'
                      }}
                      IconComponent={() => {
                        if (taskSortFile !== SortFile.TYPE) return <IconSortNeutral color={'#D5E8E5'} />;

                        return taskFilesSorting.sortDirection === 'asc' ? <IconSortUp /> : <IconSortDown />;
                      }}
                    >
                      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>Type</Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 260,
                      fontWeight: 700
                    }}
                    align="left"
                  >
                    <TableSortLabel
                      active={taskSortFile === SortFile.SIZE}
                      direction={taskFilesSorting.sortDirection}
                      onClick={() => handleSortLabelClick(SortFile.SIZE)}
                      sx={{
                        gap: '20px'
                      }}
                      IconComponent={() => {
                        if (taskSortFile !== SortFile.SIZE) return <IconSortNeutral color={'#D5E8E5'} />;

                        return taskFilesSorting.sortDirection === 'asc' ? <IconSortUp /> : <IconSortDown />;
                      }}
                    >
                      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>Size</Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 100,
                      fontWeight: 700
                    }}
                    align="right"
                  />
                </TableRow>
              </StyledTableHead>
              <TableBody
                sx={{
                  width: '100%',
                  backgroundColor: (theme) => theme.palette.common.white,
                  zIndex: (theme) => theme.zIndex.tooltip
                }}
              >
                {taskFiles.length === 0 && (
                  <TableRow>
                    <Box>No files found</Box>
                  </TableRow>
                )}
                {taskFilesStatus === ActionStatus.Pending && <AssetsListViewSkeleton />}
                {taskFilesStatus === ActionStatus.Idle &&
                  taskFiles.map((file) => {
                    const isItemSelected = isSelected(file.url);

                    return <TasksAssetsModalFileRow key={file.id} file={file} isSelected={isItemSelected} handleSelectSingleClick={handleSelectSingleClick} />;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <RowCenterStack
            sx={{
              width: '100%',
              minHeight: 'unset',
              backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
              pl: 2,
              pr: 4,
              py: 1,
              justifyContent: {
                xs: 'flex-start',
                md: 'flex-end'
              },
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              position: 'sticky',
              left: 0
            }}
          >
            <RowCenterStack>
              <RowCenterStack>
                <Typography sx={{ fontSize: 14, fontWeight: 500, mr: 2 }}>Results per page</Typography>
                <Select
                  IconComponent={ExpandMore}
                  value={taskFilesPageSize}
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    '& fieldset': {
                      border: 0
                    }
                  }}
                >
                  {FILE_PAGE_SIZES.map((option) => (
                    <MenuItem sx={{ fontSize: 14, fontWeight: 500 }} key={option} value={option} onClick={() => handleResultsPerPageSelect(option)}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <Typography sx={{ fontSize: 14, fontWeight: 500, marginLeft: 2, marginRight: 2 }}>{taskFilePageStatusText}</Typography>
              </RowCenterStack>
              <Pagination
                count={taskFilesPageCount}
                page={taskFilesPageNumber}
                onChange={handlePaginationChange}
                shape="rounded"
                siblingCount={1}
                boundaryCount={1}
                showFirstButton
                showLastButton
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    disabled={item.page === taskFilesPageNumber} // Disable the current page button
                  />
                )}
              />
            </RowCenterStack>
          </RowCenterStack>
        </Box>
      )}
    </Box>
  );
};
