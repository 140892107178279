import { Stack, StackProps, styled } from '@mui/material';

export const ModalContent = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  backgroundColor: theme.palette.customTheme.contBgr,
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 'auto',
  height: '80%',
  padding: '120px',
  display: 'flex',
  borderRadius: '20px',
  boxShadow: 'none',
  [theme.breakpoints.up('sm')]: {
    minWidth: '780px'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'calc(100% - 12px)'
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: '670px',
    height: 'auto'
  }
}));
