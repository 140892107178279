import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as VirtualizedList, ListChildComponentProps } from 'react-window';
import { FormControlLabel, TextField, InputAdornment, Stack, Typography, Collapse, ClickAwayListener } from '@mui/material';
import {
  ChevronDownIcon,
  CustomCheckBox,
  HighlightText,
  RowCenterStack,
  StyledAutocompleteListItem,
  StyledCheckbox,
  StyledTransparentButton,
  MagnifyingGlassIcon,
  PrimaryCancelButton,
  PrimaryApplyButton,
  StyledAvatar,
  PictureSizeSuffix,
  ThinBorderCheckbox,
  StyledAutocompleteListItemButton
} from '@/shared';
import { DropDownSearchBox } from '@features/tasks';
import Grid from '@mui/material/Grid2';
import { EmployeeListItem } from '@thrivea/organization-client';
import { pictureUrl } from 'src/utils';
import { useAppSelector } from 'src/app/hooks';
import { selectEmployeeProfileAndCoverReadSasToken } from 'src/features/employee-profile';

// Hook for filtering options
const useFilteredFields = (options: EmployeeListItem[], value: string) => {
  const [fields, setFields] = useState<EmployeeListItem[]>([]);

  const filterFields = useCallback(() => options.filter((option) => option.displayName.toLowerCase().includes(value.toLowerCase())), [options, value]);

  useEffect(() => {
    setFields(filterFields());
  }, [filterFields]);

  return fields;
};

interface EmployeeDropdownSearchProps {
  options: EmployeeListItem[];
  values?: string[];
  label: string;
  onOptionSelect: (selectedOption: EmployeeListItem) => void;
  onApply: () => void;
  onCancel: (selectedOptionIds: string[]) => void;
}

export const EmployeeDropdownSearch: React.FC<EmployeeDropdownSearchProps> = ({ options, values = [], label, onOptionSelect, onApply, onCancel }) => {
  const { t } = useTranslation(['common']);

  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const filteredFields = useFilteredFields(options, searchText);
  const isOpened = Boolean(anchorEl);

  const allSelected = values.length === options.length && options.length > 0;
  const partiallySelected = values.length > 0 && values.length < options.length;

  const handleToggleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyButtonClick = () => {
    onApply();
    setAnchorEl(null);
    onCancel(values);
  };

  const handleCancelButtonClick = () => {
    onCancel(values);
    setAnchorEl(null);
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    if (checked) {
      // Select all options
      options.forEach((option) => onOptionSelect(option));
    } else {
      // Deselect all options
      onCancel([]);
    }
  };

  const Row = ({ index, style }: ListChildComponentProps) => {
    // Special case: Render "Select All" as the first option
    if (index === 0) {
      return (
        <div style={style}>
          <StyledAutocompleteListItemButton>
            <FormControlLabel
              control={
                <StyledCheckbox
                  icon={<CustomCheckBox size={18} />}
                  checked={allSelected}
                  indeterminate={partiallySelected}
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              }
              label={
                <Typography variant="body2">
                  <strong>Select All</strong>
                </Typography>
              }
              sx={{ width: '100%', gap: 1, alignItems: 'center', marginLeft: 0 }}
            />
          </StyledAutocompleteListItemButton>
        </div>
      );
    }

    // Render other options
    const field = filteredFields[index - 1]; // Adjust index because "Select All" is at 0
    return (
      <div style={style}>
        <StyledAutocompleteListItemButton key={field.employeeId} selected={values.includes(field.employeeId)}>
          <FormControlLabel
            key={field.employeeId}
            control={
              <StyledCheckbox icon={<ThinBorderCheckbox size={18} />} checked={values.includes(field.employeeId)} onChange={() => onOptionSelect(field)} />
            }
            label={
              <RowCenterStack gap={1}>
                <StyledAvatar
                  width={34}
                  height={34}
                  src={pictureUrl(field.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                  alt={field.displayName}
                />
                <Typography>
                  <HighlightText text={field.displayName} highlight={searchText} />
                </Typography>
              </RowCenterStack>
            }
            sx={{ width: '100%', gap: 1, alignItems: 'center', marginLeft: 0 }}
          />
        </StyledAutocompleteListItemButton>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <DropDownSearchBox isOpen={isOpened}>
        <Stack
          onClick={handleToggleClick}
          sx={{
            width: '100%',
            pl: 1,
            pr: 2,
            py: 1,
            height: 50,
            gap: 0,
            borderRadius: 1,
            position: 'relative'
          }}
        >
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              top: 3,
              left: 5,
              color: (theme) => theme.palette.customTheme.globalContentColorMuted
            }}
          >
            {label}
          </Typography>
          <RowCenterStack
            sx={{
              justifyContent: 'space-between'
            }}
          >
            <TextField
              fullWidth
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyingGlassIcon width={16} height={16} />
                    </InputAdornment>
                  )
                }
              }}
              sx={{
                borderRadius: 4,
                fontSize: 14,
                '& .MuiInputBase-root': {
                  paddingLeft: 0
                },
                '& input': {
                  padding: 1
                },
                '& fieldset': {
                  border: '0 !important'
                }
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <StyledTransparentButton
              sx={{
                transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            >
              <ChevronDownIcon />
            </StyledTransparentButton>
          </RowCenterStack>
        </Stack>
        <Collapse
          in={isOpened}
          sx={{
            position: 'absolute',
            top: 50,
            left: -1,
            right: -1,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            border: isOpened ? '1px solid' : 0,
            borderTop: 0,
            borderColor: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.customTheme.primaryA
          }}
        >
          {isOpened && (
            <Stack
              gap={1}
              sx={{
                padding: '8px',
                backgroundColor: (theme) => theme.palette.common.white,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8
              }}
            >
              {filteredFields.length === 0 ? (
                <StyledAutocompleteListItem>
                  <Typography>{t('no_options_found', { ns: 'common' })}</Typography>
                </StyledAutocompleteListItem>
              ) : (
                <VirtualizedList
                  height={270}
                  width="100%"
                  itemSize={50}
                  itemCount={filteredFields.length + 1} // +1 for "Select All"
                  style={{ overflowY: 'auto' }}
                >
                  {Row}
                </VirtualizedList>
              )}
              <Grid container columnSpacing={1}>
                <Grid size={6}>
                  <PrimaryCancelButton onClick={handleCancelButtonClick}>Cancel</PrimaryCancelButton>
                </Grid>
                <Grid size={6}>
                  <PrimaryApplyButton onClick={handleApplyButtonClick}>Apply</PrimaryApplyButton>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Collapse>
      </DropDownSearchBox>
    </ClickAwayListener>
  );
};
