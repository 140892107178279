import Quill from 'quill';
import { store } from '@app/store';
import { DrawerType, OpenDrawerRequest, openDrawer } from '@features/drawer';

const Embed = Quill.import('blots/embed') as any;

export class EmployeeBlot extends Embed {
  static create(data) {
    const node = super.create();
    node.setAttribute('data-id', data.id);
    node.setAttribute('data-name', data.name);
    node.setAttribute('class', 'mention');
    node.textContent = `@${data.name}`;

    node.addEventListener('click', () => {
      store.dispatch(openDrawer({ type: DrawerType.PostAnalytics, request: { employeeId: data.id } } as unknown as OpenDrawerRequest));
    });
    return node;
  }

  static value(node) {
    return {
      id: node.getAttribute('data-id'),
      name: node.getAttribute('data-name')
    };
  }
}

EmployeeBlot.blotName = 'employee';
EmployeeBlot.tagName = 'span';
