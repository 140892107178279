import React, { useState } from 'react';
import { MenuItem, Menu, Typography, Box, Stack, IconButton } from '@mui/material';
import {
  AssetsSharePeopleIcon,
  BinIcon,
  ChevronDownIcon,
  EmailIcon,
  PictureSizeSuffix,
  PrimaryDarkButton,
  RowCenterJustifyBetweenStack,
  RowCenterStack,
  StyledAvatar,
  StyledBlurryModal,
  StyledModalContent
} from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectEmployeeListItemsById } from '@features/shared';
import { selectEmployeeProfileAndCoverReadSasToken } from '@/features/employee-profile';
import { initials, pictureUrl } from 'src/utils';
import { DeactivateEmployeesRequest, ResendInvitationEmailRequest } from '@thrivea/organization-client';
import {
  resendInvitationEmailRequested,
  deactivateEmployeesRequested,
  removeSelectedEmployeeRowIds,
  selectSelectedEmployeeRowIds,
  selectAreAllEmployeesInInvitedStatus,
  selectAreAllEmployeesInInActiveStatus
} from '@features/people-directory';
import { AssetsTooltip } from '@features/assets';

interface PeopleDirectoryTableActionsProps {
  isDisabled?: boolean;
}

export const PeopleDirectoryTableActions: React.FC<PeopleDirectoryTableActionsProps> = ({ isDisabled }) => {
  const dispatch = useAppDispatch();

  const employeeListItemById = useAppSelector(selectEmployeeListItemsById);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const selectedEmployeeIds = useAppSelector(selectSelectedEmployeeRowIds);

  const areAllEmployeesInInvitedState = useAppSelector(selectAreAllEmployeesInInvitedStatus);
  const areAllEmployeesInInActiveStatus = useAppSelector(selectAreAllEmployeesInInActiveStatus);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResendEmailButtonClick = () => {
    setIsResendEmailModalOpen(true);
  };

  const handleDeactivateButtonClick = () => {
    setIsDeactivateModalOpen(true);
  };

  const handleSubmitEmailsButtonClick = () => {
    dispatch(
      resendInvitationEmailRequested(
        new ResendInvitationEmailRequest({
          employeeIds: selectedEmployeeIds!
        })
      )
    );
    setIsResendEmailModalOpen(false);
  };

  const handleDeactivateEmailsButtonClick = () => {
    dispatch(
      deactivateEmployeesRequested(
        new DeactivateEmployeesRequest({
          employeeIds: selectedEmployeeIds!
        })
      )
    );
    setIsDeactivateModalOpen(false);
  };

  return (
    <Box>
      <PrimaryDarkButton
        disabled={isDisabled}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ChevronDownIcon width={14} height={14} color={isDisabled ? 'rgba(0, 0, 0, 0.12)' : '#FFFFFF'} />}
        sx={{
          width: 172,
          justifyContent: 'space-between',
          borderBottomLeftRadius: open ? 0 : '10px',
          borderBottomRightRadius: open ? 0 : '10px'
        }}
      >
        Actions
      </PrimaryDarkButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleResendEmailButtonClick} disableRipple disabled={!areAllEmployeesInInvitedState}>
          <AssetsTooltip title="Only employees that have invited status can be resent invitation email.">
            <RowCenterStack gap={1}>
              <EmailIcon size={16} />
              <Typography variant="body2">Resend invitation email</Typography>
            </RowCenterStack>
          </AssetsTooltip>
        </MenuItem>

        <MenuItem onClick={handleDeactivateButtonClick} disableRipple disabled={!areAllEmployeesInInActiveStatus}>
          <AssetsTooltip title="Only employees that have active status can be deactivated.">
            <RowCenterStack gap={1}>
              <BinIcon width={16} height={16} />
              <Typography variant="body2">Deactivate accounts</Typography>
            </RowCenterStack>
          </AssetsTooltip>
        </MenuItem>
      </Menu>
      <StyledBlurryModal open={isResendEmailModalOpen} onClose={() => setIsResendEmailModalOpen(false)}>
        <StyledModalContent
          sx={{
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minWidth: {
              xs: 'auto',
              lg: '670px'
            },
            height: {
              xs: '80%',
              lg: 'auto'
            },
            padding: '120px',
            display: 'flex',
            borderRadius: '20px',
            boxShadow: 'none'
          }}
        >
          <Stack gap={2} sx={{ width: '100%' }}>
            <Typography variant="h3">Resend invitations ?</Typography>
            <Typography variant="body2">Below is the list of employees to whom emails will be resent:</Typography>
            {selectedEmployeeIds?.map((employeeId) => {
              const employee = employeeListItemById[employeeId];

              return (
                <RowCenterJustifyBetweenStack>
                  <RowCenterStack gap={1} key={employeeId}>
                    <StyledAvatar
                      variant="rounded"
                      src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                      width={36}
                      height={36}
                    >
                      {initials(employee.displayName)}
                    </StyledAvatar>
                    <Stack>
                      <Typography variant="body2">{employee.displayName}</Typography>
                      <Typography variant="caption">{employee.emailAddress}</Typography>
                    </Stack>
                  </RowCenterStack>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(removeSelectedEmployeeRowIds(employee.employeeId));
                    }}
                  >
                    <AssetsSharePeopleIcon width={24} height={24} />
                  </IconButton>
                </RowCenterJustifyBetweenStack>
              );
            })}
            <RowCenterStack gap={1}>
              <PrimaryDarkButton variant="contained" color="primary" onClick={() => setIsResendEmailModalOpen(false)}>
                Cancel
              </PrimaryDarkButton>
              <PrimaryDarkButton variant="contained" color="primary" onClick={handleSubmitEmailsButtonClick}>
                Resend
              </PrimaryDarkButton>
            </RowCenterStack>
          </Stack>
        </StyledModalContent>
      </StyledBlurryModal>
      <StyledBlurryModal open={isDeactivateModalOpen} onClose={() => setIsDeactivateModalOpen(false)}>
        <StyledModalContent
          sx={{
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minWidth: {
              xs: 'auto',
              lg: '670px'
            },
            height: {
              xs: '80%',
              lg: 'auto'
            },
            padding: '120px',
            display: 'flex',
            borderRadius: '20px',
            boxShadow: 'none'
          }}
        >
          <Stack gap={2} sx={{ width: '100%' }}>
            <Typography variant="h3">Ready to Disable Accounts?</Typography>
            <Typography variant="body2">Here’s the list of employees whose accounts will be deactivated. Please review carefully before proceeding.</Typography>
            {selectedEmployeeIds?.map((employeeId) => {
              const employee = employeeListItemById[employeeId];

              return (
                <RowCenterJustifyBetweenStack>
                  <RowCenterStack gap={1} key={employeeId}>
                    <StyledAvatar
                      variant="rounded"
                      src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                      width={36}
                      height={36}
                    >
                      {initials(employee.displayName)}
                    </StyledAvatar>
                    <Stack>
                      <Typography variant="body2">{employee.displayName}</Typography>
                      <Typography variant="caption">{employee.emailAddress}</Typography>
                    </Stack>
                  </RowCenterStack>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(removeSelectedEmployeeRowIds(employee.employeeId));
                    }}
                  >
                    <AssetsSharePeopleIcon width={24} height={24} />
                  </IconButton>
                </RowCenterJustifyBetweenStack>
              );
            })}
            <RowCenterStack gap={1}>
              <PrimaryDarkButton variant="contained" color="primary" onClick={() => setIsDeactivateModalOpen(false)}>
                Cancel
              </PrimaryDarkButton>
              <PrimaryDarkButton variant="contained" color="primary" onClick={handleDeactivateEmailsButtonClick}>
                Deactivate
              </PrimaryDarkButton>
            </RowCenterStack>
          </Stack>
        </StyledModalContent>
      </StyledBlurryModal>
    </Box>
  );
};
