import { SyntheticEvent } from 'react';
import { Avatar, Tabs, tabsClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  changeTasksScope,
  changeTasksView,
  changeTasksViewMode,
  selectActiveTasksViewId,
  selectTasksScopeMode,
  TASKS_DEFAULT_VIEW_ID,
  TaskViewMode
} from '@features/tasks';
import { FilterTab } from '@features/people-directory';
import { PeopleFilledIcon, PictureSizeSuffix, RowCenterStack } from '@/shared';
import { selectEmployeeProfileAndCoverReadSasToken } from '@/features/employee-profile';
import { pictureUrl } from '@/utils';
import { TaskScope } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { selectCurrentUserInitials, selectCurrentUserProfilePictureUrl } from '@features/shared';

export const TasksTabs = () => {
  const { t } = useTranslation(['tasks']);
  const dispatch = useAppDispatch();

  const scope = useAppSelector(selectTasksScopeMode);
  const activeTaskViewId = useAppSelector(selectActiveTasksViewId);

  const currentUserInitials = useAppSelector(selectCurrentUserInitials);
  const currentUserProfilePicture = useAppSelector(selectCurrentUserProfilePictureUrl);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const handleTaskViewTabsChange = (_event: SyntheticEvent, newValue: TaskScope) => {
    dispatch(changeTasksViewMode(TaskViewMode.List));
    dispatch(changeTasksView(TASKS_DEFAULT_VIEW_ID));
    dispatch(changeTasksScope(newValue));
  };

  return (
    <Tabs
      value={activeTaskViewId === TASKS_DEFAULT_VIEW_ID ? scope : false}
      onChange={handleTaskViewTabsChange}
      sx={{
        minWidth: 250,
        minHeight: 43,
        borderRadius: 2,
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      <FilterTab
        isActive={scope === TaskScope.ALL_TASKS && activeTaskViewId === TASKS_DEFAULT_VIEW_ID}
        value={TaskScope.ALL_TASKS}
        label={
          <RowCenterStack gap={'12px'}>
            <PeopleFilledIcon />
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>{t('all_tasks', { ns: 'tasks' })}</Typography>
          </RowCenterStack>
        }
      />
      <FilterTab
        isActive={scope === TaskScope.MY_TASKS && activeTaskViewId === TASKS_DEFAULT_VIEW_ID}
        value={TaskScope.MY_TASKS}
        label={
          <RowCenterStack gap={2}>
            <Avatar
              sx={{
                width: 34,
                height: 34,
                borderRadius: '6px',
                border: '1px solid',
                borderColor: (theme) => theme.palette.common.black
              }}
              src={pictureUrl(currentUserProfilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
              alt="Profile image"
            >
              {currentUserInitials}
            </Avatar>
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>{t('my_tasks', { ns: 'tasks' })}</Typography>
          </RowCenterStack>
        }
      />
    </Tabs>
  );
};
