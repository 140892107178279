import { Button, ButtonProps, styled } from '@mui/material';

export const SubmitButton = styled(Button)<ButtonProps>(({ theme, disabled }) => ({
  backgroundColor: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '10px',
  padding: '12px 32px',
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 'normal'
}));
