import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addPaymentMethodFailed,
  addPaymentMethodRequested,
  addPaymentMethodSucceeded,
  changePrimaryPaymentMethodFailed,
  changePrimaryPaymentMethodRequested,
  changePrimaryPaymentMethodSucceeded,
  editInvoiceSettingsFailed,
  editInvoiceSettingsRequested,
  editInvoiceSettingsSucceeded,
  loadBillingFailed,
  LoadBillingInitially,
  loadBillingRequested,
  loadBillingSucceeded
} from '@features/admin-settings';
import { selectCurrentUserTenantId } from '@features/shared';
import { retrieveEmployeesTotalCount } from '@api/employees.api';
import { Empty } from '@bufbuild/protobuf';
import { retrieveTotalStorageUsage } from '@api/assets.api';
import { RetrieveTotalStorageUsageRequest } from '@thrivea/organization-client';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addPaymentMethod,
  changePrimaryPaymentMethod,
  editInvoiceSettings,
  retrieveInvoices,
  retrieveInvoiceSettings,
  retrievePaymentMethods,
  retrieveSubscription
} from '@api/payment.api';
import { AddPaymentMethodRequest, ChangePrimaryPaymentMethodRequest, EditInvoiceSettingsRequest, RetrieveInvoicesResponse, RetrievePaymentMethodsResponse } from '@thrivea/payment-client';
import * as Sentry from '@sentry/react';
import { showSuccess, showWarning } from '@features/snackbar';

function* loadBillingRequestedGenerator() {
  const tenantId: string = yield select(selectCurrentUserTenantId);

  try {
    const [
      employeesCountResponse,
      totalStorageUsageResponse,
      subscription,
      invoiceSettingsResponse,
      invoicesResponse,
      paymentMethodsResponse
    ] = yield all([
      call(retrieveEmployeesTotalCount, new Empty()),
      call(retrieveTotalStorageUsage, new RetrieveTotalStorageUsageRequest({ tenantId })),
      call(retrieveSubscription, new Empty()),
      call(retrieveInvoiceSettings, new Empty()),
      call(retrieveInvoices, new Empty()),
      call(retrievePaymentMethods, new Empty())
    ]);

    const loadTasksInitially: LoadBillingInitially = {
      employeesCountResponse,
      totalStorageUsageResponse,
      subscription,
      invoiceSettingsResponse,
      invoicesResponse,
      paymentMethodsResponse
    };

    yield put(loadBillingSucceeded(loadTasksInitially));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadBillingFailed());
  }
}

function* editInvoiceSettingsRequestedGenerator(action: PayloadAction<EditInvoiceSettingsRequest>) {
  try {
    const response: Empty = yield call(editInvoiceSettings, action.payload);

    yield put(editInvoiceSettingsSucceeded());
    yield put(showSuccess('Invoice settings updated successfully'));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editInvoiceSettingsFailed());
    yield put(showWarning('Failed to update invoice settings'));
  }
}

function* changePrimaryPaymentMethodRequestedGenerator(action: PayloadAction<ChangePrimaryPaymentMethodRequest>) {
  try {
    const response: Empty = yield call(changePrimaryPaymentMethod, action.payload);

    yield put(changePrimaryPaymentMethodSucceeded(action.payload));
    yield put(showSuccess('Primary payment method changed successfully'));
  } catch (error) {
    Sentry.captureException(error);
    yield put(changePrimaryPaymentMethodFailed());
    yield put(showWarning('Failed to change primary payment method'));
  }
}

function* addPaymentMethodRequestedGenerator(action: PayloadAction<AddPaymentMethodRequest>) {
  try {
    const response: Empty = yield call(addPaymentMethod, action.payload);

    const retrievePaymentMethodsResponse = yield call(retrievePaymentMethods, new Empty());
    yield put(addPaymentMethodSucceeded(retrievePaymentMethodsResponse.paymentMethods));
    yield put(showSuccess('Payment method added successfully'));
  } catch (error) {
    Sentry.captureException(error);
    yield put(addPaymentMethodFailed());
    yield put(showWarning('Failed to add payment method'));
  }
}

function* loadBillingRequestedWatcher() {
  yield takeLatest(loadBillingRequested.type, loadBillingRequestedGenerator);
}

function* editInvoiceSettingsRequestedWatcher() {
  yield takeLatest(editInvoiceSettingsRequested.type, editInvoiceSettingsRequestedGenerator);
}

function* changePrimaryPaymentMethodRequestedWatcher() {
  yield takeLatest(changePrimaryPaymentMethodRequested.type, changePrimaryPaymentMethodRequestedGenerator);
}

function* addPaymentMethodRequestedWatcher() {
  yield takeLatest(addPaymentMethodRequested.type, addPaymentMethodRequestedGenerator);
}

export function* billingSagas() {
  yield all([
    fork(loadBillingRequestedWatcher),
    fork(editInvoiceSettingsRequestedWatcher),
    fork(changePrimaryPaymentMethodRequestedWatcher),
    fork(addPaymentMethodRequestedWatcher)
  ]);
}
