import { Stack, StackProps, styled } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

interface ChosenPlanBoxProps extends StackProps {
  isActive?: boolean;
}

const customShouldForwardProp = (prop: PropertyKey) => prop !== 'isActive' && shouldForwardProp(prop);

export const PlanBox = styled(Stack, { shouldForwardProp: customShouldForwardProp })<ChosenPlanBoxProps>(({ theme, isActive }) => ({
  cursor: 'pointer',
  padding: '16px',
  borderRadius: '12px',
  backgroundColor: theme.palette.customTheme.bgr,
  border: '2px solid',
  borderColor: isActive ? theme.palette.customTheme.navColorSelected : 'transparent',
  transition: theme.transitions.create('border-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  [theme.breakpoints.down('sm')]: {
    padding: '8px',
    fontSize: '14px'
  },
  [theme.breakpoints.down('xs')]: {
    padding: '4px',
    fontSize: '12px'
  },
  [theme.containerQueries.up(350)]: {
    flexDirection: 'row'
  }
}));
