// stripe.js (or any central utility file)
import { loadStripe } from 'retry-stripe-loader';
import * as Sentry from '@sentry/react';

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
  retryOptions: {
    onFailedAttempt: (error) => {
      console.error(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
      // 1st request => Attempt 1 failed. There are 4 retries left.
      // 2nd request => Attempt 2 failed. There are 3 retries left.
      // …
      Sentry.captureException(error);
    },
    retries: 5
  }
});
