import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { z } from 'zod';
import { ErTemplateScalarField, PhoneNumberOptions } from '@thrivea/organization-client';
import { StyledFilledInput } from '@/shared';
import { IMaskInput } from 'react-imask';
import { PhoneInTalk } from '@mui/icons-material';

const PhoneNumberOptionsSchema = (options: PhoneNumberOptions) =>
  z.object({
    phoneNumber: z
      .string()
      .refine(
        (value) => {
          const phoneRegex = /^[+]?[0-9\s-]{7,15}$/;

          // Check for country code
          if (options.enableCountryCode) {
            return phoneRegex.test(value);
          }

          // Check for area code if necessary
          if (options.enableAreaCode) {
            const areaCodeRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
            return areaCodeRegex.test(value) || phoneRegex.test(value);
          }

          return phoneRegex.test(value);
        },
        {
          message: 'Invalid phone number format.'
        }
      )
      .or(z.literal(''))
  });

// Custom masked input component
const PhoneMaskInput = React.forwardRef<HTMLInputElement, any>(function PhoneMaskInput(props, ref) {
  const { onChange, ...other } = props;

  // Determine the mask based on options
  const mask = props.enableCountryCode ? '+0 (000) 000-0000' : props.enableAreaCode ? '(000) 000-0000' : '000-000-0000';

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={{
        '0': /[0-9]/ // Allow only digits
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

interface EmployeeRecordPhoneFieldProps {
  control: Control<FieldValues, any>;
  fieldId: string;
  isEditable: boolean;
  templateField: ErTemplateScalarField;
}

export const EmployeeRecordPhoneField: React.FC<EmployeeRecordPhoneFieldProps> = ({ control, fieldId, isEditable, templateField }) => {
  const phoneFieldOptions = templateField.erbScalarField!.type!.options!.kind.value as PhoneNumberOptions;

  const options = new PhoneNumberOptions({
    enableCountryCode: phoneFieldOptions.enableCountryCode,
    enableAreaCode: phoneFieldOptions.enableAreaCode
  });

  return (
    <Controller
      name={fieldId}
      control={control}
      rules={{
        validate: (value) => {
          const schema = PhoneNumberOptionsSchema(options);
          const result = schema.safeParse({ phoneNumber: value });

          if (!result.success) {
            return result.error.errors.map((err) => err.message).join(', ');
          }
          return true;
        }
      }}
      render={({ field, fieldState }) => (
        <StyledFilledInput
          {...field}
          value={field.value}
          label={templateField.name}
          disabled={!isEditable}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          fullWidth
          slotProps={{
            input: {
              inputComponent: PhoneMaskInput as any,
              startAdornment: <PhoneInTalk />
            }
          }}
        />
      )}
    />
  );
};
