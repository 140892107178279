import React, { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import { Dictionary } from 'lodash';
import { Typography, IconButton, Stack } from '@mui/material';
import { RowCenterStack, StyledTooltip } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RenderEmoji, reactToPostRequested, withdrawPostReactionRequested } from '@features/homepage';
import { ReactToPostRequest, Reaction, WithdrawPostReactionRequest } from '@thrivea/networking-client';
import { selectCurrentEmployeeId } from '@features/shared';
import { fetchEmojisWithShortcodes } from '@utils/emojiMartShortcode';
import { useTranslation } from 'react-i18next';
import { selectEmployeeListItemsById } from '@features/shared';

interface BasePostReactionsProps {
  postId: string;
  reactionsGroupedByEmoji: Dictionary<Reaction[]>;
}

export const BasePostReactions: React.FC<BasePostReactionsProps> = ({ postId, reactionsGroupedByEmoji }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const authors = useAppSelector(selectEmployeeListItemsById);
  const [emojisWithShortcodes, setEmojisWithShortcodes] = useState<{ [key: string]: string }>({});

  const handleEmojiToggle = (emoji: string) => {
    if (reactionsGroupedByEmoji[emoji].some((reaction) => reaction.authorId === currentUserId)) {
      dispatch(
        withdrawPostReactionRequested({
          postId,
          emoji,
          authorId: currentUserId
        } as WithdrawPostReactionRequest)
      );
    } else {
      dispatch(
        reactToPostRequested({
          postId,
          emoji,
          authorId: currentUserId
        } as ReactToPostRequest)
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const emojis = await fetchEmojisWithShortcodes(reactionsGroupedByEmoji);
      setEmojisWithShortcodes(emojis);
    };

    fetchData();
  }, [reactionsGroupedByEmoji]);

  return (
    <RowCenterStack
      gap={1}
      sx={{
        padding: '0',
        flexWrap: 'wrap',
        maxWidth: '80%'
      }}
    >
      {keys(reactionsGroupedByEmoji).map((emoji) => {
        return (
          <StyledTooltip
            key={emoji}
            isVisible
            title={
              <Stack
                direction="row"
                gap={2}
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  borderRadius: (theme) => theme.spacing(1),
                  padding: (theme) => theme.spacing(2),
                  '& img': {
                    maxWidth: '26px !important',
                    maxHeight: '26px !important'
                  }
                }}
              >
                <RenderEmoji native={emoji || ''} />
                <RowCenterStack
                  sx={{
                    flexWrap: 'wrap'
                  }}
                >
                  {reactionsGroupedByEmoji[emoji].slice(0, 10).map((reaction, index) => (
                    <Typography key={reaction.authorId}>
                      {authors.hasOwnProperty(reaction.authorId) ? authors[reaction.authorId].displayName : ''}
                      {index === reactionsGroupedByEmoji[emoji].length - 1 ? ' ' : ', '}
                    </Typography>
                  ))}
                  {reactionsGroupedByEmoji[emoji].length > 10 && (
                    <Typography>{t('reaction_with_count', { ns: 'common', count: reactionsGroupedByEmoji[emoji].length - 10 })}</Typography>
                  )}
                  <Typography>
                    {t('reacted_with_prefix', { ns: 'common' })}{' '}
                    <Typography component="span" fontWeight={700}>
                      {emojisWithShortcodes[emoji]}
                    </Typography>
                  </Typography>
                </RowCenterStack>
              </Stack>
            }
            enterDelay={500}
            leaveDelay={200}
            placement="top"
          >
            <IconButton
              onClick={() => handleEmojiToggle(emoji)}
              sx={{
                bgcolor: (theme) =>
                  reactionsGroupedByEmoji[emoji].some((reaction) => reaction.authorId === currentUserId)
                    ? theme.palette.customTheme.drawerBackground
                    : theme.palette.grey[300],
                minWidth: 32,
                maxHeight: 28,
                padding: '0 7px',
                borderRadius: '7px',
                '& img': {
                  width: 14,
                  height: 14
                }
              }}
            >
              <RowCenterStack
                gap={1}
                sx={{
                  justifyContent: 'center'
                }}
              >
                <RenderEmoji native={emoji || ''} />
                <Typography
                  variant="caption"
                  sx={{
                    height: '100%',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {reactionsGroupedByEmoji[emoji].length}
                </Typography>
              </RowCenterStack>
            </IconButton>
          </StyledTooltip>
        );
      })}
    </RowCenterStack>
  );
};
