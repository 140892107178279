import { Stack, StackProps, styled } from '@mui/material';

export const AudienceMemberCountStack = styled(Stack)<StackProps>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: 'unset',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  padding: 16,
  justifyContent: 'space-between'
}));
