import { Alert, Box, Snackbar, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  PostView,
  closeNewPostNotification,
  retrievePostsRequested,
  selectCurrentPostsPage,
  selectHasMorePosts,
  selectPostIds,
  selectPostReadSasToken,
  selectPosts,
  selectIsNewPostPublished,
  PostViewReadConfirmation
} from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { init } from 'emoji-mart';
import data from '@emoji-mart/data/sets/14/apple.json';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { RetrievePostsRequest } from '@thrivea/networking-client';
import { AlertIcon, RowCenterStack } from '@/shared';
import { DateTime } from 'luxon';
import { PostIdWithTime, SendPostAnalyticsRequest } from '@thrivea/networking-analytics-client';
import { selectCurrentEmployeeId } from '@features/shared';
import { sendPostAnalytics } from '@api/network-analytics.api';
import { DrawerType, OpenDrawerRequest, openDrawer } from '@features/drawer';

// init emoji mart data
init({ data });

export const PostFeed = () => {
  const { t } = useTranslation(['homepage']);
  const dispatch = useAppDispatch();
  const postIds = useAppSelector(selectPostIds);
  const posts = useAppSelector(selectPosts);
  const isNewPostPublished = useAppSelector<boolean>(selectIsNewPostPublished);

  const postReadSasToken = useAppSelector(selectPostReadSasToken);
  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const observer = useRef<IntersectionObserver>();
  const postsObserver = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);
  const postRefs = postIds.reduce(
    (acc, id) => {
      acc[id] = createRef<HTMLDivElement>();
      return acc;
    },
    {} as Record<string, React.RefObject<HTMLDivElement>>
  );

  const hasMore = useAppSelector<boolean>(selectHasMorePosts);
  const pageNumber = useAppSelector<number>(selectCurrentPostsPage);
  const [hasRetrievedAllPosts, setHasRetrievedAllPosts] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (hasMore) {
      dispatch(retrievePostsRequested(new RetrievePostsRequest({ pageNumber: pageNumber + 1, pageSize: 10 })));
    } else {
      setHasRetrievedAllPosts(true);
    }
  }, [hasMore, pageNumber]);

  const dispatchActionToService = async (posts: PostIdWithTime[], employeeId: string) => {
    try {
      await sendPostAnalytics({
        posts,
        employeeId
      } as SendPostAnalyticsRequest).then(() => {});
      localStorage.setItem('viewedPosts', '[]');
    } catch (error) {
      console.error('Error in dispatchActionToService:', error);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const worker = new Worker(new URL('@app/workers/posts_worker.ts', import.meta.url), { type: 'module' });

    if (posts && employeeId) {
      // Handle messages from the web worker
      worker.onmessage = (event) => {
        const { type, data } = event.data;
        if (type === 'SEND_POST_ANALYTICS') {
          // Dispatch action to service with stored IDs
          // Check if there are any new IDs to send
          const viewedPosts = JSON.parse(localStorage.getItem('viewedPosts') || '[]');
          if (viewedPosts.length > 0) {
            dispatchActionToService(viewedPosts, employeeId);
          }
        }
      };

      // Request stored IDs from the main thread every 15 seconds
      const sendIdsToWorker = () => {
        const ids = JSON.parse(localStorage.getItem('viewedPosts') || '[]'); // or sessionStorage
        worker.postMessage({ type: 'SEND_IDS', data: ids });
      };

      // Initial request and set up interval
      sendIdsToWorker();
      intervalId = setInterval(sendIdsToWorker, 15000);
    }

    // Clean up the worker and interval on component unmount
    return () => {
      worker.terminate();
      clearInterval(intervalId);
    };
  }, [posts, employeeId]);

  useEffect(() => {
    postsObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && postsObserver.current) {
            const postId = entry.target.id;
            const viewedAt = DateTime.utc().toISO();
            storeIdInLocalStorage({
              postId,
              viewedAt
            } as PostIdWithTime);
            postsObserver.current.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.5
      }
    );

    postIds.forEach((id) => {
      const postRef = postRefs[id].current;
      if (postsObserver.current && postRef) {
        postsObserver.current.observe(postRef);
      }
    });

    //cleanup
    return () => {
      postIds.forEach((id) => {
        const postRef = postRefs[id].current;
        if (postsObserver.current && postRef) {
          postsObserver.current.unobserve(postRef);
        }
      });
    };
  }, [postIds]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const mentions = document.querySelectorAll('.mention');

    mentions.forEach((mention) => {
      mention.addEventListener('click', (e) => {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (e.currentTarget instanceof Element) {
          const employeeId = e.currentTarget.getAttribute('data-id')!;
          dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
        }
      });
    });
  }, [postIds]);

  const handleNewPostNotificationClose = () => {
    dispatch(closeNewPostNotification());
  };

  const storeIdInLocalStorage = (post: PostIdWithTime) => {
    const viewedPosts = JSON.parse(localStorage.getItem('viewedPosts') || '[]') as PostIdWithTime[];
    const postExists = viewedPosts.some((viewedPost) => viewedPost.postId === post.postId);

    if (!postExists) {
      viewedPosts.push(post);
      localStorage.setItem('viewedPosts', JSON.stringify(viewedPosts));
    }
  };

  return (
    <>
      <Snackbar
        open={isNewPostPublished}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="New post arrived"
        autoHideDuration={3000}
        sx={{
          width: 'calc(100% - 32px)',
          borderRadius: (theme) => theme.spacing(2),
          backgroundColor: (theme) => theme.palette.secondary.main,
          color: (theme) => theme.palette.common.white,
          '& svg': {
            color: (theme) => theme.palette.common.white
          }
        }}
      >
        <Alert
          onClose={handleNewPostNotificationClose}
          severity="success"
          sx={{ width: '100%', display: 'flex', alignItems: 'center', color: (theme) => theme.palette.common.white }}
        >
          <Typography fontWeight={700}>{t('post_created', { ns: 'homepage' })}</Typography>
          <Typography>{t('new_post_created', { ns: 'homepage' })}</Typography>
        </Alert>
      </Snackbar>
      <Grid
        container
        gap={'20px 0'}
        sx={{
          position: 'relative'
        }}
      >
        {postIds.length === 0 && (
          <RowCenterStack gap={1} sx={{ padding: 2, width: '100%' }}>
            <AlertIcon />
            <Typography fontWeight={600}>{t('blank_posts', { ns: 'homepage' })}</Typography>
          </RowCenterStack>
        )}
        {postIds.map((id) => {
          const post = posts[id];
          return (
            <Grid size={12} key={post.id}>
              {post.case === 'shoutout' && (
                <Box ref={postRefs[post.id]} id={post.id}>
                  <PostViewReadConfirmation requiresReadConfirmation={post.hasReadConfirmation} postId={post.id} authorId={post.authorId} />
                  <PostView
                    postId={post.id}
                    date={post.publishTime}
                    authorId={post.authorId}
                    message={post.text}
                    files={post.mediaUrls.map((mu) => ({
                      alt: 'Media',
                      src: new URL(postReadSasToken, mu).toString(),
                      name: mu
                    }))}
                    docs={post.docUrls.map((du) => ({
                      alt: 'Document',
                      src: new URL(postReadSasToken, du).toString(),
                      name: du
                    }))}
                    audienceId={post.audienceIds[0]}
                    editedTime={post.editedTime}
                    isImporant={post.isImportant}
                    requiresReadConfirmation={post.hasReadConfirmation}
                  />
                </Box>
              )}
            </Grid>
          );
        })}
        {hasRetrievedAllPosts && postIds.length > 0 && (
          <RowCenterStack gap={1} sx={{ padding: 2, width: '100%' }}>
            <AlertIcon />
            <Typography fontWeight={600}>{t('all_posts_retrieved', { ns: 'homepage' })}</Typography>
          </RowCenterStack>
        )}
      </Grid>
      <div ref={bottomElementRef} style={{ width: '100%', height: '50px' }}></div>
    </>
  );
};
