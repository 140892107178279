import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { EmployeeListItem, RetrieveAllEmployeesResponse, TextErbFieldValue, UpdateErSectionRequest } from '@thrivea/organization-client';
import { retrieveAllEmployees } from '@api/employees.api';
import {
  retrieveEmployeesRequested,
  retrieveEmployeesSucceeded,
  retrieveEmployeesFailed,
  updateEmployee,
  selectEmployeeListItemById} from '@features/shared';
import { Empty } from '@bufbuild/protobuf';
import { updateErSectionSucceeded } from '@features/employee-record-page';
import { PayloadAction } from '@reduxjs/toolkit';

function* retrieveEmployeesRequestedGenerator() {
  try {
    const response: RetrieveAllEmployeesResponse = yield call(retrieveAllEmployees, new Empty());
    yield put(retrieveEmployeesSucceeded(response));
  } catch (error) {
    yield put(retrieveEmployeesFailed());
  }
}

const ERB_FIELD_DISPLAY_NAME_ID = '11183939-f57c-49fe-8a25-48c4a30f3a15';

function* updateErSectionSucceededGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  const { employeeId, changedFieldValues } = action.payload;

  const changedDisplayName = changedFieldValues.find((fv) => fv.erbFieldId === ERB_FIELD_DISPLAY_NAME_ID);

  if (!changedDisplayName) {
    return;
  }

  const previousEmployeeListItem: EmployeeListItem = yield select(selectEmployeeListItemById, employeeId);
  const displayName = (changedDisplayName.kind.value as TextErbFieldValue).value;

  const employeeListItem = new EmployeeListItem({
    ...previousEmployeeListItem,
    displayName
  });

  yield put(updateEmployee(employeeListItem));
}

function* updateErSectionSucceededWatcher() {
  yield takeLatest(updateErSectionSucceeded.type, updateErSectionSucceededGenerator);
}

function* retrieveEmployeesRequestedWatcher() {
  yield takeLatest(retrieveEmployeesRequested.type, retrieveEmployeesRequestedGenerator);
}

export function* employeesSagas() {
  yield all([fork(retrieveEmployeesRequestedWatcher), fork(updateErSectionSucceededWatcher)]);
}
