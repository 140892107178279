import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';

export const CouponCodeButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  minWidth: 92,
  display: 'flex',
  padding: '6px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  background: '#BEBABA',
  color: '#000',
  textAlign: 'center',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}));
