const generateHash = async (fingerprint: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(fingerprint);

  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

/**
 * Generate a unique public ID for a SingleTask entity.
 * @param task - The SingleTask object to generate the public ID for.
 * @returns A string representing the public ID.
 */
export const generateSingleTaskPublicId = async (): Promise<string> => {
  // Step 1: Create a fingerprint based on task properties
  // const fingerprint = `${task.taskListId}:${task.title}:${task.description}:${task.createdAt}`;
  const fingerprint = crypto.randomUUID();

  // Step 2: Generate a hash from the fingerprint using SHA-256
  const hash = await generateHash(fingerprint);

  // Step 3: Optionally shorten the hash for readability (e.g., use first 8 characters)
  const publicId = hash.substring(0, 8).toUpperCase();

  // Prefix with "T-"
  return `T-${publicId}`;
};

/**
 * Generate a unique public ID for a SingleTask entity.
 * @param task - The SingleTask object to generate the public ID for.
 * @returns A string representing the public ID.
 */
export const generateTaskListPublicId = async (): Promise<string> => {
  // Step 1: Create a fingerprint based on task properties
  // const fingerprint = `${task.taskListId}:${task.title}:${task.description}:${task.createdAt}`;
  const fingerprint = crypto.randomUUID();

  // Step 2: Generate a hash from the fingerprint using SHA-256
  const hash = await generateHash(fingerprint);

  // Step 3: Optionally shorten the hash for readability (e.g., use first 8 characters)
  const publicId = hash.substring(0, 8).toUpperCase();

  // Prefix with "T-"
  return `TL-${publicId}`;
};
