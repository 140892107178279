import { Typography, Button } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { RowCenterStack, CheckedIcon } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { readPostRequested, selectIsPostRead } from '@features/homepage';
import { selectCurrentEmployeeId } from '@features/shared';
import { DateTime } from 'luxon';
import { ReadPostRequest } from '@thrivea/networking-client';
import { useTranslation } from 'react-i18next';
import { showSuccess } from '@features/snackbar';

interface PostViewReadConfirmationProps {
  requiresReadConfirmation: boolean;
  postId: string;
  authorId: string;
}

export const PostViewReadConfirmation: React.FC<PostViewReadConfirmationProps> = ({ requiresReadConfirmation, postId, authorId }) => {
  const { t } = useTranslation(['common', 'homepage']);
  const dispatch = useAppDispatch();
  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const readPostById = useAppSelector((state) => selectIsPostRead(state, postId));
  const isEmployeeAuthor = employeeId === authorId;
  const [confirmedRead, setConfirmedRead] = useState(false);

  const handleReadPostButtonClicked = () => {
    dispatch(
      readPostRequested({
        postId,
        employeeId,
        readAt: DateTime.utc().toISO()
      } as ReadPostRequest)
    );
    dispatch(showSuccess(t('read_confirmed', { ns: 'homepage' })));
    setConfirmedRead(true);
  };

  return (
    requiresReadConfirmation &&
    readPostById &&
    !readPostById.isRead &&
    !isEmployeeAuthor &&
    !confirmedRead && (
      <RowCenterStack
        gap={2}
        sx={{
          backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
          padding: 2,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          justifyContent: 'flex-end'
        }}
      >
        <Typography>{t('post_read_confirmation', { ns: 'homepage' })}</Typography>
        <Button onClick={handleReadPostButtonClicked} startIcon={<CheckedIcon />} variant="contained" color="primary" sx={{ borderRadius: 2 }}>
          Confirm
        </Button>
      </RowCenterStack>
    )
  );
};
