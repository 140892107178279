import { Close } from '@mui/icons-material';
import { OneColumnIcon, RowCenterStack, TwoColumnIcon } from '@/shared';
import { a11yProps } from '@/utils';
import { createContext, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EmployeeRecordDragHandle, EmployeeRecordFieldChip, FieldTabs, FieldTab, EmployeeRecordFieldIcon } from '@features/drawer';
import { ErbFieldWidth, ErTemplateField } from '@thrivea/organization-client';
import { useAppDispatch } from '@app/hooks';
import { removeFieldFromSection, changeFieldWidth, getErTemplateScalarField } from '@features/employee-record-builder';

interface EmployeeRecordDraggableFieldProps {
  field: ErTemplateField;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

export const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {}
});

export const EmployeeRecordDraggableField: React.FC<EmployeeRecordDraggableFieldProps> = ({ field }) => {
  const dispatch = useAppDispatch();
  const [isTableField, _] = useState(field.kind.case === 'tableField');

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, isDragging, transform, transition } = useSortable({
    id: field.kind.value ? field.kind.value.id : ''
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  return (
    <SortableItemContext.Provider value={context}>
      <RowCenterStack
        ref={setNodeRef}
        sx={{
          justifyContent: 'space-between',
          opacity: isDragging ? 0.25 : 1,
          transform: CSS.Translate.toString(transform),
          transition
        }}
      >
        <RowCenterStack>
          <EmployeeRecordDragHandle />
          <EmployeeRecordFieldChip
            icon={<EmployeeRecordFieldIcon field={field} isSelected={true} />}
            label={field.kind.value!.name}
            isSelected={true}
            isPresentInOtherSection={false}
            isCalculated={field.kind.case === 'scalarField' && field.kind.value.erbScalarField?.isCalculated}
            isHistoryTable={field.kind.case === 'tableField'}
          />
        </RowCenterStack>
        <RowCenterStack gap={1}>
          <FieldTabs value={isTableField ? ErbFieldWidth.FULL - 1 : getErTemplateScalarField(field).width - 1} aria-label="Field width">
            <FieldTab
              onClick={() =>
                dispatch(
                  changeFieldWidth({
                    fieldId: field.kind.value!.id,
                    width: ErbFieldWidth.THIRD
                  })
                )
              }
              disabled={isTableField}
              label={<TwoColumnIcon color={isTableField ? '#1314140d' : '#000000'} />}
              {...a11yProps('employee_record_three_column_width', 0)}
            />
            /* todo - updated colors later */
            <FieldTab
              onClick={() =>
                dispatch(
                  changeFieldWidth({
                    fieldId: field.kind.value!.id,
                    width: ErbFieldWidth.HALF
                  })
                )
              }
              disabled={isTableField}
              label={<TwoColumnIcon color={isTableField ? '#1314140d' : '#000000'} />}
              {...a11yProps('employee_record_two_column_width', 1)}
            />
            <FieldTab
              onClick={() =>
                dispatch(
                  changeFieldWidth({
                    fieldId: field.kind.value!.id,
                    width: ErbFieldWidth.FULL
                  })
                )
              }
              disabled={isTableField}
              label={<OneColumnIcon />}
              {...a11yProps('employee_record_one_column_width', 2)}
            />
          </FieldTabs>
          <IconButton
            onClick={() => dispatch(removeFieldFromSection(field.kind.value!.id))}
            sx={{
              width: 16,
              height: 16,
              padding: 0,
              backgroundColor: (theme) => theme.palette.common.black,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.black
              }
            }}
          >
            <Close sx={{ fill: (theme) => theme.palette.common.white, fontSize: '0.8rem' }} />
          </IconButton>
        </RowCenterStack>
      </RowCenterStack>
    </SortableItemContext.Provider>
  );
};
