import { styled, TableRow, TableRowProps } from '@mui/material';
import { ASSIGNED, COMPLETED, DRAFT, IN_REVIEW, TaskStatus } from '@features/tasks';

interface TasksListRowStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface TasksListTaskRowProps extends TableRowProps {
  statusId: string;
  isDivider?: boolean;
}

export const TasksListTaskRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'statusId' && prop !== 'isDivider'
})<TasksListTaskRowProps>(({ statusId, isDivider, theme }) => {
  // Define an array for row styling based on TaskStatus
  const row: Record<string, TasksListRowStyleProps> = {
    [DRAFT]: { backgroundColor: theme.palette.grey[600], textColor: theme.palette.primary.main },
    [ASSIGNED]: { backgroundColor: theme.palette.common.white, textColor: theme.palette.primary.main },
    [IN_REVIEW]: { backgroundColor: 'rgba(252, 199, 93, 0.30)', textColor: theme.palette.primary.main },
    [COMPLETED]: { backgroundColor: theme.palette.customTheme.selectLabel, textColor: theme.palette.primary.main }
  };

  return {
    height: isDivider ? 8 : 'auto',
    backgroundColor: row[statusId].backgroundColor,
    color: row[statusId].textColor,
    border: 0,
    position: 'relative'
  };
});
