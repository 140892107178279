import prettyBytes from 'pretty-bytes';
import { FileItem, FileType } from '@thrivea/organization-client';
import { TableRow, TableCell, Typography, IconButton } from '@mui/material';
import { RowCenterStack, StyledCheckbox, ThinBorderCheckbox } from '@/shared';
import { AssetsFileCard, selectSelectedFileIds } from '@features/assets';
import { useAppSelector } from '@app/hooks';
import { extension } from '@utils/getExtensionFromName';

interface AssetsFileTableRowProps {
  file: FileItem;
  isSelected: boolean;
  handleSelectSingleClick: (url: string) => void;
}

export const TasksAssetsModalFileRow: React.FC<AssetsFileTableRowProps> = ({ file, isSelected, handleSelectSingleClick }) => {
  const selectedFileIds = useAppSelector(selectSelectedFileIds);

  return (
    <TableRow
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        padding: 0,
        position: 'relative',
        '& .MuiTableCell-root': {
          border: 0,
          '&:nth-of-type(1)': {
            borderRight: '1px solid',
            borderColor: (theme) => theme.palette.secondary.main
          }
        }
      }}
    >
      <TableCell
        sx={{
          fontWeight: 700,
          padding: '0 20px 0 16px',
          backgroundColor: selectedFileIds.some((id) => id === file.id) ? (theme) => theme.palette.customTheme.selectItem : 'transparent'
        }}
        align="left"
      >
        <RowCenterStack gap={'20px'}>
          <StyledCheckbox icon={<ThinBorderCheckbox sx={{ fontSize: 28 }} />} checked={isSelected} onChange={(event) => handleSelectSingleClick(file.url)} />
          <IconButton sx={{ padding: 0 }}>
            <AssetsFileCard fileType={file.type} labelText={extension(file.url)} />
          </IconButton>
          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{file.name}</Typography>
        </RowCenterStack>
      </TableCell>
      <TableCell align="left">
        <Typography sx={{ fontWeight: 400, fontSize: 12 }}> {FileType[file.type]}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{prettyBytes(Number(file.sizeInBytes))}</Typography>
      </TableCell>
      <TableCell align="right" />
    </TableRow>
  );
};
