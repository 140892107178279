import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

export const BillingEmailChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.drawerBackground,
  padding: '3px 7px',
  borderRadius: '8px',
  minHeight: 20,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 22,
  border: 0,
  marginRight: '8px',
  gap: 1,
  [`& .${chipClasses.label}`]: {
    padding: 0,
    margin: 0,
    fontSize: 14,
    color: theme.palette.primary.main
  },
  '& .MuiChip-deleteIcon': {
    margin: 0,
    color: theme.palette.primary.main
  }
}));
