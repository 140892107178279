import React from 'react';
import { Box, Stack, Drawer, List, ListItem, ListItemButton, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import {
  BenefitsIcon,
  DocsIcon,
  HeartIcon,
  HomeIcon,
  OperationsIcon,
  OrganizationIcon,
  PeopleIcon,
  PictureSizeSuffix,
  TalkIcon,
  TimeIcon,
  WorkIcon
} from '@/shared';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectOrganizationLogoFile, selectOrganizationLogoImageUrl, selectOrganizationLogoReadSasToken } from '@features/admin-settings';
import { NavListItem, NavSettingsListItem, openNavigationDrawer } from '@features/navigation';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';
import { isSvgUrl, pictureUrl, scrollbarStyles } from 'src/utils';
import { selectCurrentEmployeeId } from '@features/shared';

interface NavigationProps {
  width: number;
  isOpen: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ width, isOpen }) => {
  const dispatch = useAppDispatch();
  const logoUrl = useAppSelector(selectOrganizationLogoImageUrl);
  const logoFile = useAppSelector(selectOrganizationLogoFile);
  const organizationSasToken = useAppSelector(selectOrganizationLogoReadSasToken);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowLgHeight = useMediaQuery('(max-height: 900px)');
  const breakingHeight = useMediaQuery('(max-height: 740px)');

  const getLogo = () => {
    if (logoFile) {
      return URL.createObjectURL(logoFile.sm);
    } else if (logoUrl) {
      if (isSvgUrl(logoUrl)) {
        return new URL(organizationSasToken, decodeURIComponent(logoUrl)).toString();
      }
      return pictureUrl(logoUrl, organizationSasToken, PictureSizeSuffix.lg);
    } else {
      return '/images/logo-placeholder.png';
    }
  };

  const navContent = (
    <Stack
      component={'nav'}
      gap={'12px'}
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: (theme) => theme.palette.customTheme.navBgr,
        border: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster,
        borderRadius: {
          xs: 0,
          md: 4
        }
      }}
    >
      <Toolbar
        sx={{
          minHeight: {
            xs: '90px'
          },
          padding: {
            xs: '1rem',
            md: '1rem'
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          {isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-expanded.png"
              alt="Thrivea logo"
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
          {!isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-default.png"
              alt="Thrivea logo"
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
        </Stack>
      </Toolbar>
      <List
        sx={{
          ...scrollbarStyles(theme),
          color: 'inherit',
          width: '100%',
          maxHeight: breakingHeight ? 468 : 600,
          padding: 0,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        <AllowedTo
          perform={GroupPermissions.ACCESS_NEWS_FEED}
          data={currentEmployeeId}
          no={() => <NavListItem isDisabled={true} name="Home" url="/" icon={<HomeIcon />} />}
        >
          <NavListItem isDisabled={false} name="Home" url="/" icon={<HomeIcon />} />
        </AllowedTo>
        <AllowedTo
          perform={GroupPermissions.ACCESS_SETTINGS}
          data={currentEmployeeId}
          no={() => <NavListItem isDisabled={true} name="People" url="/people-directory" icon={<PeopleIcon />} />}
        >
          <NavListItem isDisabled={false} name="People" url="/people-directory" icon={<PeopleIcon />} />
        </AllowedTo>
        {/* <NavListItem isDisabled={true} name="Org" url="/organization" icon={<OrganizationIcon />} /> */}
        <NavListItem isDisabled={false} name="Assets" url="/assets" icon={<DocsIcon />} />
        {/* <NavListItem isDisabled={true} name="Feedback" url="/feedback" icon={<HeartIcon />} /> */}
        <NavListItem isDisabled={false} name="Operations" url="/operations/my-tasks" icon={<OperationsIcon />} />
        {/* <NavListItem isDisabled={true} name="Time" url="/time" icon={<TimeIcon />} /> */}
        {/* <NavListItem isDisabled={true} name="Comms" url="/comms" icon={<TalkIcon />} /> */}
        {/* <NavListItem isDisabled={true} name="Work" url="/work" icon={<WorkIcon />} /> */}
        {/* <NavListItem isDisabled={true} name="Payroll" url="/payroll" icon={<BenefitsIcon />} /> */}
      </List>
      <List
        sx={{
          ...scrollbarStyles(theme),
          width: '100%',
          padding: 0,
          backgroundColor: (theme) => theme.palette.common.white,
          marginTop: 'auto'
        }}
      >
        <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS} data={currentEmployeeId} no={() => <NavSettingsListItem isDisabled={true} />}>
          <NavSettingsListItem isDisabled={false} />
        </AllowedTo>
        <ListItem
          sx={{
            padding: '0'
          }}
        >
          <ListItemButton
            disableGutters
            component={Link}
            to="#"
            sx={{
              justifyContent: 'center',
              '&:hover': {
                ' .hoverText': {
                  opacity: '1'
                }
              }
            }}
          >
            <Stack
              gap={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {isSvgUrl(logoUrl) && (
                <svg width="46" height="46" xmlns="http://www.w3.org/2000/svg">
                  <image href={getLogo()} height="46" width="46" />
                </svg>
              )}
              {!isSvgUrl(logoUrl) && (
                <Box
                  component="img"
                  src={getLogo()}
                  alt="Organization Logo"
                  width="46"
                  height="46"
                  sx={{
                    width: 46,
                    height: 46,
                    borderRadius: 2,
                    backgroundColor: 'transparent',
                    objectFit: 'contain'
                  }}
                />
              )}
            </Stack>
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );

  const navigationDrawerOpenButtonClick = () => {
    dispatch(openNavigationDrawer());
  };

  return (
    <>
      {!isTablet && (
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={navigationDrawerOpenButtonClick}
          sx={{
            display: {
              xs: 'block',
              lg: 'none'
            },
            '& .MuiDrawer-paper': {
              width: 150
            },
            '& .MuiBackdrop-root': {
              background: 'rgba(217, 217, 217, 0.60)',
              backdropFilter: 'blur(10px)'
            }
          }}
        >
          {navContent}
        </Drawer>
      )}
      {isTablet && <>{navContent}</>}
    </>
  );
};
