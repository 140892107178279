import React, { SyntheticEvent, useState } from 'react';
import { CheckedIcon, FilterIcon, RowCenterStack, StyledRenameInput, TicketIcon } from '@/shared';
import { Close } from '@mui/icons-material';
import { RenameTasksViewRequest, RetrieveSingleTaskRequest, TaskScope, TasksView, UpdateTasksViewRequest } from '@thrivea/organization-client';
import { Box, Chip, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { FilterTab } from '@features/people-directory';
import {
  changeTasksScope,
  changeTasksView,
  changeTasksViewMode,
  openSingleTask,
  renameTasksViewRequested,
  retrieveSingleTaskRequested,
  selectActiveTasksViewId,
  selectIsViewChanged,
  selectRetrieveTasksRequest,
  selectTasksScopeMode,
  toggleVisibilityTasksView,
  updateTasksViewRequested,
  TaskViewMode
} from '@features/tasks';
import { selectCurrentEmployeeId } from '@features/shared';

interface PeopleDirectoryViewFilterTabProps {
  view: TasksView;
}

export const TasksViewsTab: React.FC<PeopleDirectoryViewFilterTabProps> = ({ view }) => {
  const dispatch = useAppDispatch();

  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const retrieveTasksRequest = useAppSelector((state) => selectRetrieveTasksRequest(state, employeeId));
  const activeTasksViewId = useAppSelector(selectActiveTasksViewId);
  const isViewChanged = useAppSelector((state) => selectIsViewChanged(state, view.tasksViewId));
  const taskScope = useAppSelector(selectTasksScopeMode);

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(view.name);
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleTabClick = (_event: SyntheticEvent<HTMLDivElement, MouseEvent>, tasksViewId: string): void => {
    if (activeTasksViewId === tasksViewId) return;

    dispatch(changeTasksView(tasksViewId));
    if (view.kind.case === 'filters') {
      dispatch(changeTasksViewMode(TaskViewMode.List));
      dispatch(changeTasksScope(TaskScope.ALL_TASKS));
    } else if (view.kind.case === 'single') {
      dispatch(
        retrieveSingleTaskRequested({
          singleTaskId: view.kind.value.singleTaskId
        } as RetrieveSingleTaskRequest)
      );
      dispatch(openSingleTask(view.kind.value.singleTaskId));
    }
  };

  const handleTabDoubleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(true);
  };

  const handleClick = (e) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    } else {
      const timeout = setTimeout(() => {
        handleTabClick(e, view.tasksViewId);
        setClickTimeout(null);
      }, 300); // Adjust the delay as needed
      setClickTimeout(timeout);
    }
  };

  const handleTabInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.relatedTarget && event.relatedTarget.tagName === 'BUTTON') {
      return;
    }

    setIsEditing(false);
  };

  const handleTabCheckedButtonClick = () => {
    dispatch(
      renameTasksViewRequested(
        new RenameTasksViewRequest({
          name: name,
          taskViewId: view.tasksViewId
        })
      )
    );

    setIsEditing(false);
  };

  const handleHideTabButtonClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(changeTasksViewMode(TaskViewMode.List));
    dispatch(changeTasksScope(TaskScope.ALL_TASKS));
    dispatch(toggleVisibilityTasksView({ viewId: view.tasksViewId, hide: true }));
  };

  const handleTabInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        renameTasksViewRequested(
          new RenameTasksViewRequest({
            name: name,
            taskViewId: view.tasksViewId
          })
        )
      );

      setIsEditing(false);
    }
  };

  const handleUpdateChipClick = (view: TasksView) => {
    if (view.kind.case === 'filters') {
      dispatch(
        updateTasksViewRequested(
          new UpdateTasksViewRequest({
            taskViewId: view.tasksViewId,
            name: view.name,
            type: view.kind.value.type,
            statusIds: view.kind.value.statusIds,
            ownerIds: view.kind.value.ownerIds,
            assigneeIds: view.kind.value.assigneeIds,
            reviewerIds: view.kind.value.reviewerIds,
            dueDateRange: view.kind.value.dueDateRange,
            searchQuery: view.kind.value.searchQuery
          })
        )
      );
    }
  };

  return (
    <FilterTab
      isActive={activeTasksViewId === view.tasksViewId}
      isEditable={isEditing}
      value={view.tasksViewId}
      label={
        isEditing ? (
          <StyledRenameInput
            width={name.length * 10 - 10}
            value={name}
            onChange={(e) => {
              e.stopPropagation();
              setName(e.target.value);
            }}
            onKeyDown={handleTabInputKeyDown}
            autoFocus
            size="small"
            onBlur={handleTabInputBlur}
            onFocus={(event) => {
              event.target.select();
            }}
            endAdornment={
              <IconButton onClick={handleTabCheckedButtonClick}>
                <CheckedIcon />
              </IconButton>
            }
          />
        ) : (
          <RowCenterStack gap={1}>
            {view.kind.case === 'single' && <TicketIcon width={16} height={16} />}
            {view.kind.case === 'filters' && <FilterIcon width={16} height={16} />}
            {name}
            {isViewChanged && <Chip onClick={() => handleUpdateChipClick(view)} label="Update View" color="secondary" />}
          </RowCenterStack>
        )
      }
      onClick={handleClick}
      onDoubleClick={(e) => {
        if (clickTimeout) {
          clearTimeout(clickTimeout);
          setClickTimeout(null);
        }
        handleTabDoubleClick(e);
      }}
      icon={
        <Box
          className="Mui-DeleteFilterButton"
          sx={{
            opacity: 0,
            padding: 0,
            transition: (theme) =>
              theme.transitions.create('opacity', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
              })
          }}
          onClick={(e) => handleHideTabButtonClick(e)}
        >
          <Close />
        </Box>
      }
      iconPosition="end"
      sx={{
        position: 'relative',
        '&:hover': {
          '& .Mui-DeleteFilterButton': {
            display: 'flex',
            opacity: 1
          }
        }
      }}
    />
  );
};
