import { gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';
import { PeopleIcon, RowCenterStack } from '@/shared';
import { Typography } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { selectEmployeeRecordsTotalCount } from '@features/people-directory';
import { useTranslation } from 'react-i18next';

const Pagination = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      showFirstButton
      showLastButton
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
};

export const DataGridPagination = (props: any) => {
  const { t } = useTranslation(['common', 'settings_permissions']);

  const employeeRecordsTotalCount = useAppSelector(selectEmployeeRecordsTotalCount);

  return (
    <RowCenterStack
      sx={{
        width: '100%',
        minHeight: 'unset',
        backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
        pl: 2,
        pr: 4,
        py: 1,
        justifyContent: {
          xs: 'flex-start',
          md: 'space-between'
        },
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        position: 'sticky',
        left: 0
      }}
    >
      <RowCenterStack gap={2} sx={{ flexWrap: 'wrap' }}>
        <PeopleIcon />
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{t('employee_with_count', { ns: 'common', count: employeeRecordsTotalCount })}</Typography>
      </RowCenterStack>
      <GridPagination ActionsComponent={Pagination} {...props} />
    </RowCenterStack>
  );
};
