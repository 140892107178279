import { Skeleton, TableCell, TableRow } from '@mui/material';

export const AssetsListViewSkeleton = () => {
  return Array.from({ length: 12 }).map((_, index) => (
    <TableRow
      key={index}
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        padding: 0,
        '& .MuiTableCell-root': {
          border: 0,
          '&:nth-of-type(2)': {
            borderRight: '1px solid',
            borderColor: (theme) => theme.palette.secondary.main
          }
        }
      }}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <TableCell key={index} component="th" scope="row" sx={{ padding: 1 }}>
          <Skeleton animation="wave" variant="text" sx={{ height: 40, transform: 'none' }} />
        </TableCell>
      ))}
    </TableRow>
  ));
};
