import { Box, Link, Skeleton, Typography } from '@mui/material';
import { ActionStatus } from 'src/shared';
import { Site } from '@thrivea/organization-client';
import { useAppSelector } from '@app/hooks';
import { PpdColumn, selectRetrieveTwoStepDataStatus } from '@features/people-directory';
import { MouseEventHandler } from 'react';

interface PeopleDirectoryTableSiteEntityCellProps {
  column: PpdColumn;
  site: Site;
  handleSiteLinkClick: MouseEventHandler<HTMLAnchorElement>;
}

export const PeopleDirectoryTableSiteEntityCell: React.FC<PeopleDirectoryTableSiteEntityCellProps> = ({ site, handleSiteLinkClick, column }) => {
  const retrieveTwoStepDataStatus = useAppSelector(selectRetrieveTwoStepDataStatus);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      }}
    >
      {retrieveTwoStepDataStatus === ActionStatus.Pending && <Skeleton animation="wave" />}
      {retrieveTwoStepDataStatus === ActionStatus.Idle && (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          <Link
            onClick={handleSiteLinkClick}
            sx={{
              color: (theme) => theme.palette.customTheme.focusItem,
              fontWeight: 700,
              cursor: 'pointer'
            }}
          >
            {site.name}
          </Link>
        </Typography>
      )}
    </Box>
  );
};
