import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Typography, debounce } from '@mui/material';
import Search from '@mui/icons-material/Search';
import {
  ActionStatus,
  CancelEditIcon,
  HighlightText,
  RowCenterStack,
  StyledBlurryModal,
  PrimaryDarkButton,
  StyledFormHeader,
  StyledFormSubmitButton,
  StyledTableRow,
  StyledTableHead,
  IconSortNeutral,
  IconSortUp,
  IconSortDown,
  AddIcon,
  PrimaryTypographyHeaderTitleCase,
  StyledTableCell
} from '@/shared';
import {
  AdminSettingsWorkingPatternAdd,
  WorkingPatternActions,
  deleteWorkingPatternByIdRequested,
  retrieveWorkingPatternItemsRequested,
  selectTotalNumberOfWorkingPatterns,
  selectWorkingPatternListItemsStatus,
  selectHasMoreWorkingPatternListItems,
  selectCurrentWorkingPatternListItemPage,
  AdminSettingsSearch,
  SettingsContainer,
  SettingsActionsHeader,
  ButtonTextLink,
  ModalContent,
  ModalContentStack,
  ActionCopy,
  selectWorkingPatternListItems
} from '@features/admin-settings';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  DeleteWorkingPatternByIdRequest,
  OrderDirection,
  RetrieveWorkingPatternListItemsRequest,
  WorkingPatternListColumnName
} from '@thrivea/organization-client';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';
import { Navigate } from 'react-router-dom';
import { selectCurrentEmployeeId } from '@features/shared';

const workingPatternListColumns = Object.keys(WorkingPatternListColumnName)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) // remove first option
  .map((key) => ({
    id: parseInt(key), // deduct by 1 as we are removing first option
    name: WorkingPatternListColumnName[Number(key)].toLowerCase()
  }));

const nameColumn = workingPatternListColumns.find((wplc) => wplc.name === 'working_pattern_name')!;
const weeklyTotalHoursColumn = workingPatternListColumns.find((wplc) => wplc.name === 'working_pattern_weekly_working_hours')!;

export const AdminSettingsWorkingPatternsLibrary = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'settings_working_patterns']);

  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const workingPatternItems = useAppSelector(selectWorkingPatternListItems);

  const workingPatternListItemsStatus = useAppSelector(selectWorkingPatternListItemsStatus);
  const hasMoreWorkingPatterns = useAppSelector(selectHasMoreWorkingPatternListItems);
  const currentWorkingPatternListItemPage = useAppSelector(selectCurrentWorkingPatternListItemPage);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeAddWorkingPatternModal, setActiveAddWorkingPatternModal] = useState(false);

  const [search, setSearch] = useState('');
  const [activeColumn, setActiveColumn] = useState(null);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState(OrderDirection.ASCENDING);

  const totalNumberOfWorkingPatterns = useAppSelector(selectTotalNumberOfWorkingPatterns);

  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (hasMoreWorkingPatterns) {
      dispatch(
        retrieveWorkingPatternItemsRequested(
          new RetrieveWorkingPatternListItemsRequest({
            pageNumber: currentWorkingPatternListItemPage + 1,
            pageSize: 10,
            orderDirection: sortOrder,
            searchText: search
          })
        )
      );
    }
  }, [hasMoreWorkingPatterns, currentWorkingPatternListItemPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && workingPatternListItemsStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (workingPatternItems.length === 0) {
      dispatch(
        retrieveWorkingPatternItemsRequested(
          new RetrieveWorkingPatternListItemsRequest({
            searchText: search,
            pageNumber: 1,
            pageSize: 10
          })
        )
      );
    }
  }, []);

  const toggleMenu = (menuName: string) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  const closeMenu = (menuName: string) => {
    if (openMenu === menuName) {
      setOpenMenu(null);
    }
  };

  const handleOpenAddWorkingPatternModal = () => {
    setActiveAddWorkingPatternModal(true);
  };

  const handleCloseAddWorkingPatternModal = () => {
    setActiveAddWorkingPatternModal(false);
  };

  const handleSearchWorkingPatterns = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
    dispatch(
      retrieveWorkingPatternItemsRequested(
        new RetrieveWorkingPatternListItemsRequest({
          searchText: e.target.value,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  };

  const debouncedSearch = debounce(handleSearchWorkingPatterns, 500);

  const handleDeleteWorkingPatternById = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, wpId: string) => {
    e.stopPropagation();
    dispatch(
      deleteWorkingPatternByIdRequested(
        new DeleteWorkingPatternByIdRequest({
          workingPatternId: wpId
        })
      )
    );
    setIsDeleteModalOpen(false);
  };

  const handleShowWorkingPattern = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, workingPatternId: string, workingPatternName: string) => {
    e.stopPropagation();

    dispatch(openDrawer({ type: DrawerType.WorkingPattern, request: { workingPatternId, workingPatternName } } as OpenDrawerRequest));
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleRequestSort = (column) => {
    setSortOrder(sortOrder === OrderDirection.ASCENDING ? OrderDirection.DESCENDING : OrderDirection.ASCENDING);
    setActiveColumn(column.name);
    // Use column.id for orderBy because the state of orderByColumn variable wont be updated in time
    dispatch(
      retrieveWorkingPatternItemsRequested(
        new RetrieveWorkingPatternListItemsRequest({
          pageNumber: 1,
          pageSize: 10,
          orderBy: column.id,
          orderDirection: sortOrder,
          searchText: search
        })
      )
    );
  };

  return (
    <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS_WP} data={employeeId} no={() => <Navigate to="/403" />}>
      <SettingsContainer>
        <StyledFormHeader>
          <PrimaryTypographyHeaderTitleCase variant="subtitle1">{t('title', { ns: 'settings_working_patterns' })}</PrimaryTypographyHeaderTitleCase>
        </StyledFormHeader>
        <SettingsActionsHeader gap={1}>
          <RowCenterStack gap={1}>
            <PrimaryDarkButton variant="contained" startIcon={<AddIcon color="#FFF" />} onClick={handleOpenAddWorkingPatternModal} sx={{ my: 1 }}>
              {t('btn_txt', { ns: 'settings_working_patterns' })}
            </PrimaryDarkButton>
            <Typography fontWeight={700}>
              {t('total', { ns: 'common' })}: {totalNumberOfWorkingPatterns}
            </Typography>
          </RowCenterStack>
          <Box>
            <AdminSettingsSearch
              placeholder={t('search', { ns: 'common' })}
              slotProps={{
                input: {
                  startAdornment: <Search />
                }
              }}
              onChange={debouncedSearch}
            />
          </Box>
        </SettingsActionsHeader>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell
                  sortDirection={activeColumn === nameColumn.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                  align="left"
                >
                  <TableSortLabel
                    active={activeColumn === nameColumn.name}
                    direction={activeColumn === nameColumn.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                    onClick={() => handleRequestSort(nameColumn)}
                    IconComponent={() => {
                      if (activeColumn === nameColumn.name) return <IconSortNeutral color={'#D5E8E5'} />;

                      return sortOrder === OrderDirection.ASCENDING ? <IconSortUp /> : <IconSortDown />;
                    }}
                  >
                    {t(nameColumn.name, { ns: 'settings_working_patterns' })}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">{t('used_by', { ns: 'settings_working_patterns' })}</StyledTableCell>
                <StyledTableCell align="left">{t('description', { ns: 'settings_working_patterns' })}</StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    active={activeColumn === weeklyTotalHoursColumn.name}
                    direction={activeColumn === weeklyTotalHoursColumn.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                    onClick={() => handleRequestSort(weeklyTotalHoursColumn)}
                    IconComponent={() => {
                      if (activeColumn === nameColumn.name) return <IconSortNeutral color={'#D5E8E5'} />;

                      return sortOrder === OrderDirection.ASCENDING ? <IconSortUp /> : <IconSortDown />;
                    }}
                  >
                    {t(weeklyTotalHoursColumn.name, { ns: 'settings_working_patterns' })}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">{t('default_working_hours', { ns: 'settings_working_patterns' })}</StyledTableCell>
                <StyledTableCell align="left" />
              </StyledTableRow>
            </StyledTableHead>
            <TableBody>
              {workingPatternItems.map((wp) => (
                <StyledTableRow key={wp.id}>
                  <StyledTableCell>
                    <Typography variant="body2">
                      <HighlightText text={wp.name} highlight={search} />
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <ButtonTextLink variant="text" disableElevation onClick={(e) => handleShowWorkingPattern(e, wp.id, wp.name)}>
                      {t('working_pattern_with_count_sites', { count: wp.countOfSites, ns: 'settings_working_patterns' })},{' '}
                      {t('people_with_count', { count: wp.countOfEmployees, ns: 'settings_working_patterns' })}
                    </ButtonTextLink>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">
                      <HighlightText text={wp.description} highlight={search} />
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">{wp.weeklyHours}</StyledTableCell>
                  <StyledTableCell align="left">{Math.floor(wp.defaultWorkingHours)}</StyledTableCell>
                  <StyledTableCell align="left">
                    <WorkingPatternActions
                      workingPatternListItem={wp}
                      menuName={wp.id}
                      isWorkingPatternMenuOpen={openMenu === wp.id}
                      handleWorkingPatternMenuToggle={toggleMenu}
                      closeMenu={closeMenu}
                    />
                  </StyledTableCell>
                  <StyledBlurryModal component="div" open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
                    <ModalContent>
                      <ModalContentStack>
                        <Typography variant="subtitle1">{t('delete_site', { ns: 'settings_sites' })}</Typography>
                        <ActionCopy variant="body1">
                          {t('delete_question', { ns: 'settings_sites' })} <Box component="b">{wp.name}</Box>
                        </ActionCopy>
                        <RowCenterStack gap={2}>
                          <PrimaryDarkButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose}>
                            {t('cancel', { ns: 'common' })}
                          </PrimaryDarkButton>
                          <StyledFormSubmitButton variant="contained" onClick={(e) => handleDeleteWorkingPatternById(e, wp.id)}>
                            {t('delete', { ns: 'common' })}
                          </StyledFormSubmitButton>
                        </RowCenterStack>
                      </ModalContentStack>
                    </ModalContent>
                  </StyledBlurryModal>
                </StyledTableRow>
              ))}
              <div ref={bottomElementRef} style={{ width: '100%', height: '50px' }}></div>
              {workingPatternListItemsStatus === ActionStatus.Pending && (
                <Fragment>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton width={236} height={60} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={100} height={60} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={100} height={60} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton width={50} height={60} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton width={50} height={60} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={180} height={60} />
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {activeAddWorkingPatternModal && (
          <AdminSettingsWorkingPatternAdd isOpen={activeAddWorkingPatternModal} handleCloseModal={handleCloseAddWorkingPatternModal} />
        )}
      </SettingsContainer>
    </AllowedTo>
  );
};
