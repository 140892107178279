import { styled } from '@mui/material';
import { Gauge, GaugeProps, gaugeClasses } from '@mui/x-charts';

export const BillingChart = styled(Gauge, {
  shouldForwardProp: (prop) => prop !== 'valueColor' && prop !== 'referenceColor'
})<GaugeProps & { valueColor?: string; referenceColor?: string }>(({ valueColor = '#8c52ff', referenceColor = '#EAECF0' }) => ({
  [`& .${gaugeClasses.valueText}`]: {
    fontSize: 20 ,
    fontWeight: 700
  },
  [`& .${gaugeClasses.valueArc}`]: {
    fill: valueColor
  },
  [`& .${gaugeClasses.referenceArc}`]: {
    fill: referenceColor
  }
}));
