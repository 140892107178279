import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ADDITIONAL_SEATS_PRICE_IN_USD,
  ADDITIONAL_STORAGE_PRICE_IN_USD,
  BillingPeriod,
  LoadBillingInitially,
  SubscriptionInfo
} from '@features/admin-settings';
import { subscriptionPlans, SubscriptionPlan } from '@features/admin-settings';
import { RootState } from '@app/store';
import {
  AddPaymentMethodRequest,
  Card,
  ChangePrimaryPaymentMethodRequest,
  EditInvoiceSettingsRequest,
  Invoice,
  PaymentMethod,
  RetrieveInvoiceSettingsResponse,
  Subscription
} from '@thrivea/payment-client';
import { ActionStatus } from 'src/shared';
import { capitalize, values } from 'lodash';
import { DateTime } from 'luxon';

export interface BillingState {
  entities: {
    employeesCount: number;
    storageUsageSizeInBytes: string;
    subscription: Subscription;
    invoiceSettings: {
      initial: RetrieveInvoiceSettingsResponse;
      updated: RetrieveInvoiceSettingsResponse;
      hasChanged: boolean;
    };
    invoices: Invoice[];
    paymentMethods: PaymentMethod[];
  };
  ui: {
    initialLoadStatus: ActionStatus;
    editInvoiceSettingsStatus: ActionStatus;
    changePrimaryPaymentMethodStatus: ActionStatus;
  };
  additionalSeats: number;
  additionalStorage: number;
}

const initialState: BillingState = {
  entities: {
    employeesCount: 0,
    storageUsageSizeInBytes: '0',
    subscription: new Subscription({
      stripeLookupKey: ''
    }),
    invoiceSettings: {
      initial: new RetrieveInvoiceSettingsResponse({
        billingEmailAddresses: [],
        companyDetails: ''
      }),
      updated: new RetrieveInvoiceSettingsResponse({
        billingEmailAddresses: [],
        companyDetails: ''
      }),
      hasChanged: false
    },
    invoices: [],
    paymentMethods: []
  },
  ui: {
    initialLoadStatus: ActionStatus.Idle,
    editInvoiceSettingsStatus: ActionStatus.Idle,
    changePrimaryPaymentMethodStatus: ActionStatus.Idle
  },
  additionalSeats: 0,
  additionalStorage: 0
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    loadBillingRequested: (state) => {
      state.ui.initialLoadStatus = ActionStatus.Pending;
    },
    loadBillingSucceeded: (state, action: PayloadAction<LoadBillingInitially>) => {
      const { employeesCountResponse, totalStorageUsageResponse, subscription, invoiceSettingsResponse, invoicesResponse, paymentMethodsResponse } =
        action.payload;

      state.entities.employeesCount = employeesCountResponse.totalCount;
      state.entities.storageUsageSizeInBytes = totalStorageUsageResponse.totalStorageInBytes;
      state.entities.subscription = subscription;
      state.entities.invoiceSettings.initial = invoiceSettingsResponse;
      state.entities.invoiceSettings.updated = invoiceSettingsResponse;
      state.entities.invoices = invoicesResponse.invoices;
      state.entities.paymentMethods = paymentMethodsResponse.paymentMethods;

      state.additionalSeats = subscription.addons
        .filter((addon) => addon.stripeLookupKey.startsWith('additional_seat'))
        .reduce((prev, current) => prev + current.quantity, 0);
      state.additionalStorage = subscription.addons
        .filter((addon) => addon.stripeLookupKey.startsWith('additional_storage'))
        .reduce((prev, current) => prev + current.quantity, 0);

      state.ui.initialLoadStatus = ActionStatus.Idle;
    },
    loadBillingFailed: (state) => {
      state.ui.initialLoadStatus = ActionStatus.Failed;
    },
    loadPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.entities.paymentMethods = action.payload;
    },
    addBillingEmail: (state, action: PayloadAction<string>) => {
      state.entities.invoiceSettings.updated.billingEmailAddresses.push(action.payload);
      state.entities.invoiceSettings.hasChanged = !state.entities.invoiceSettings.initial.equals(state.entities.invoiceSettings.updated);
    },
    removeBillingEmail: (state, action: PayloadAction<string>) => {
      state.entities.invoiceSettings.updated = {
        ...state.entities.invoiceSettings.updated,
        billingEmailAddresses: state.entities.invoiceSettings.updated.billingEmailAddresses.filter((email) => email !== action.payload)
      };
      state.entities.invoiceSettings.hasChanged = !state.entities.invoiceSettings.initial.equals(state.entities.invoiceSettings.updated);
    },
    changeInvoiceSettings: (state, action: PayloadAction<string>) => {
      state.entities.invoiceSettings.updated.companyDetails = action.payload;
      state.entities.invoiceSettings.hasChanged = !state.entities.invoiceSettings.initial.equals(state.entities.invoiceSettings.updated);
    },
    editInvoiceSettingsRequested: (state, action: PayloadAction<EditInvoiceSettingsRequest>) => {
      state.ui.editInvoiceSettingsStatus = ActionStatus.Pending;
    },
    editInvoiceSettingsSucceeded: (state) => {
      state.entities.invoiceSettings.initial = state.entities.invoiceSettings.updated;
      state.entities.invoiceSettings.hasChanged = false;

      state.ui.editInvoiceSettingsStatus = ActionStatus.Idle;
    },
    editInvoiceSettingsFailed: (state) => {
      state.entities.invoiceSettings.updated = state.entities.invoiceSettings.initial;
      state.entities.invoiceSettings.hasChanged = false;

      state.ui.editInvoiceSettingsStatus = ActionStatus.Failed;
    },
    updateAdditionalSeats: (state, action: PayloadAction<number>) => {
      state.additionalSeats = action.payload;
    },
    updateAdditionalStorage: (state, action: PayloadAction<number>) => {
      state.additionalStorage = action.payload;
    },
    changePrimaryPaymentMethodRequested: (state, action: PayloadAction<ChangePrimaryPaymentMethodRequest>) => {
      state.ui.changePrimaryPaymentMethodStatus = ActionStatus.Pending;
    },
    changePrimaryPaymentMethodSucceeded: (state, action: PayloadAction<ChangePrimaryPaymentMethodRequest>) => {
      const { paymentMethodId } = action.payload;

      state.entities.paymentMethods = state.entities.paymentMethods.map(
        (paymentMethod) =>
          new PaymentMethod({
            paymentMethodId: paymentMethod.paymentMethodId,
            kind: {
              case: paymentMethod.kind.case!,
              value: new Card({
                ...paymentMethod.kind.value,
                isDefault: paymentMethod.paymentMethodId === paymentMethodId
              })
            }
          })
      );

      state.ui.changePrimaryPaymentMethodStatus = ActionStatus.Idle;
    },
    changePrimaryPaymentMethodFailed: (state) => {
      state.ui.changePrimaryPaymentMethodStatus = ActionStatus.Failed;
    },
    resetAddons: (state) => {
      state.additionalSeats = state.entities.subscription.addons
        .filter((addon) => addon.stripeLookupKey.startsWith('additional_seat'))
        .reduce((prev, current) => prev + current.quantity, 0);
      state.additionalStorage = state.entities.subscription.addons
        .filter((addon) => addon.stripeLookupKey.startsWith('additional_storage'))
        .reduce((prev, current) => prev + current.quantity, 0);
    },
    addPaymentMethodRequested: (state, action: PayloadAction<AddPaymentMethodRequest>) => {},
    addPaymentMethodSucceeded: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.entities.paymentMethods = action.payload;
    },
    addPaymentMethodFailed: (state) => {}
  }
});

const gibiBytes = 1024 ** 3;

const bytesToGb = (bytesString: string): number => {
  return parseInt(bytesString, 10) / gibiBytes; // Convert bytes to GB
};

export const selectEmployeesCount = (state: RootState) => state.billing.entities.employeesCount;
export const selectStorageUsageSizeInGb = (state: RootState) => bytesToGb(state.billing.entities.storageUsageSizeInBytes);
export const selectSubscription = (state: RootState) => state.billing.entities.subscription;

const formatPlanName = (planName: string): string => {
  return capitalize(planName.split('-')[0]);
};

export const selectSubscriptionInfo = createSelector(selectSubscription, (s) => {
  const subscriptionPlan = values(subscriptionPlans).find((plan: SubscriptionPlan) => plan.stripeLookupKey === s?.stripeLookupKey);

  if (!subscriptionPlan) {
    return {
      name: '',
      freeTrialEndingDate: DateTime.local(),
      price: '$ 0',
      priceValue: 0,
      seats: 0,
      storage: 0,
      seatAddonsQuantity: 0,
      storageAddonsQuantity: 0,
      billingPeriod: BillingPeriod.Monthly
    } as SubscriptionInfo;
  }

  return {
    name: formatPlanName(subscriptionPlan.name),
    freeTrialEndingDate: DateTime.fromISO(s.trialEndsAt),
    price: `$ ${subscriptionPlan.price}`,
    priceValue: subscriptionPlan.price,
    seats: subscriptionPlan.usersCount,
    storage: subscriptionPlan.storage,
    seatAddonsQuantity: s.addons.filter((addon) => addon.stripeLookupKey.startsWith('additional_seat')).reduce((prev, current) => prev + current.quantity, 0),
    storageAddonsQuantity: s.addons
      .filter((addon) => addon.stripeLookupKey.startsWith('additional_storage'))
      .reduce((prev, current) => prev + current.quantity, 0),
    billingPeriod: subscriptionPlan.billingPeriod
  } as SubscriptionInfo;
});

export const selectInitialLoadStatus = (state: RootState) => state.billing.ui.initialLoadStatus;
export const selectIsOnFreePlan = createSelector(selectSubscription, (s) => s.stripeLookupKey.startsWith('free'));

export const selectInvoiceSettings = (state: RootState) => state.billing.entities.invoiceSettings.updated;
export const selectInvoiceSettingsHasChanged = (state: RootState) => state.billing.entities.invoiceSettings.hasChanged;
export const selectEditInvoiceSettingsStatus = (state: RootState) => state.billing.ui.editInvoiceSettingsStatus;

export const selectInvoices = (state: RootState) => state.billing.entities.invoices;
export const selectPaymentMethods = (state: RootState) => state.billing.entities.paymentMethods;
export const selectPaymentMethodIsAvailable = (state: RootState) => state.billing.entities.paymentMethods.length > 0;

export const selectCurrentSubscriptionAmount = (state: RootState) => state.billing.entities.subscription.amount;
export const selectAdditionalSeats = (state: RootState) => state.billing.additionalSeats;
export const selectAdditionalStorage = (state: RootState) => state.billing.additionalStorage;

export const selectTotalCost = createSelector(
  [selectSubscriptionInfo, selectAdditionalSeats, selectAdditionalStorage],
  (subscriptionInfo, additionalSeats, additionalStorage) => {
    return subscriptionInfo.priceValue + additionalSeats * ADDITIONAL_SEATS_PRICE_IN_USD + additionalStorage * ADDITIONAL_STORAGE_PRICE_IN_USD;
  }
);

export const selectChangePrimaryPaymentMethodStatus = (state: RootState) => state.billing.ui.changePrimaryPaymentMethodStatus;

export const {
  loadBillingRequested,
  loadBillingSucceeded,
  loadBillingFailed,
  loadPaymentMethods,
  addBillingEmail,
  removeBillingEmail,
  changeInvoiceSettings,
  editInvoiceSettingsRequested,
  editInvoiceSettingsSucceeded,
  editInvoiceSettingsFailed,
  updateAdditionalSeats,
  updateAdditionalStorage,
  changePrimaryPaymentMethodRequested,
  changePrimaryPaymentMethodSucceeded,
  changePrimaryPaymentMethodFailed,
  resetAddons,
  addPaymentMethodRequested,
  addPaymentMethodSucceeded,
  addPaymentMethodFailed
} = billingSlice.actions;

export default billingSlice.reducer;
