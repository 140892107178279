import { styled, Typography, TypographyProps } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

interface PriceTypographyProps extends TypographyProps {
  isCouponApplied?: boolean;
}

export const PriceTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCouponApplied' && shouldForwardProp(prop)
})<PriceTypographyProps>(({ theme, isCouponApplied }) => ({
  display: 'inline',
  color: isCouponApplied ? theme.palette.primary.dark : theme.palette.primary.main,
  textDecoration: isCouponApplied ? 'line-through' : 'none',
  fontSize: 'inherit',
  fontWeight: 'inherit'
}));
