import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  approveTask,
  archiveTask,
  commentOnTask,
  createSingleTask,
  createTaskList,
  createTaskView,
  deleteSingleTask,
  deleteTaskComment,
  deleteTaskList,
  deleteTasksView,
  editTask,
  editTaskComment,
  editTaskList,
  markTaskAsCompleted,
  reactToTask,
  reactToTaskComment,
  renameTasksView,
  retrieveSingleTask,
  retrieveSingleTaskRelations,
  retrieveSingleTasksForTaskList,
  retrieveSingleTasksInTaskList,
  retrieveTaskListRelations,
  retrieveTasks,
  retrieveTasksForDependencies,
  retrieveTasksViews,
  returnTaskToAssignee,
  returnToReviewer,
  sendTaskToReviewer,
  updateTasksView,
  withdrawTaskCommentReaction,
  withdrawTaskReaction
} from '@api/tasks.api';
import {
  CommentOnTaskRequest,
  CreateSingleTaskRequest,
  CreateTasksViewRequest,
  EditTaskRequest,
  ReactToTaskCommentRequest,
  ReactToTaskRequest,
  RenameTasksViewRequest,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveSingleTaskResponse,
  RetrieveSingleTasksInTaskListResponse,
  RetrieveTasksForDependenciesResponse,
  RetrieveTasksRequest,
  RetrieveTasksResponse,
  RetrieveTasksViewsRequest,
  SingleTaskId,
  TaskListId,
  SingleTaskIdWithEmployeeId,
  WithdrawTaskCommentReactionRequest,
  WithdrawTaskReactionRequest,
  DeleteTasksViewRequest,
  RetrieveSingleTasksForTaskListResponse,
  CreateTaskListRequest,
  RetrieveSingleTaskRelationsResponse,
  RetrieveSingleTaskRequest,
  DeleteSingleTaskRequest,
  EditTaskListRequest,
  DeleteTaskListRequest,
  UpdateTasksViewRequest,
  DeleteTaskCommentRequest,
  EditTaskCommentRequest,
  TaskListItem,
  SimpleTask,
  TaskType,
  TaskKindWithPrivate,
  RetrieveFilesRequest,
  RetrieveFilesResponse
} from '@thrivea/organization-client';
import {
  loadTasksRequested,
  loadTasksSucceeded,
  LoadTasksInitially,
  loadTasksFailed,
  changeTasksGroupBy,
  selectRetrieveTasksRequest,
  retrieveTasksSucceeded,
  retrieveTasksFailed,
  changeTasksScope,
  nextPageByDueDate,
  nextPageByStatus,
  createSingleTaskSucceeded,
  createSingleTaskFailed,
  createSingleTaskRequested,
  retrieveAssigneeAutocompleteItemsSucceeded,
  retrieveAssigneeAutocompleteItemsFailed,
  retrieveAssigneeAutocompleteItemsRequested,
  retrieveReviewerAutocompleteItemsSucceeded,
  retrieveReviewerAutocompleteItemsFailed,
  retrieveReviewerAutocompleteItemsRequested,
  deleteSingleTaskSucceeded,
  deleteSingleTaskFailed,
  deleteSingleTaskRequested,
  retrieveSingleTaskSucceeded,
  retrieveSingleTaskFailed,
  retrieveSingleTaskRequested,
  reactToTaskSucceeded,
  reactToTaskRequested,
  reactToTaskFailed,
  withdrawTaskReactionSucceeded,
  withdrawTaskReactionFailed,
  withdrawTaskReactionRequested,
  commentOnTaskSucceeded,
  commentOnTaskFailed,
  commentOnTaskRequested,
  reactToTaskCommentSucceeded,
  reactToTaskCommentFailed,
  reactToTaskCommentRequested,
  withdrawTaskCommentReactionRequested,
  withdrawTaskCommentReactionSucceeded,
  withdrawTaskCommentReactionFailed,
  selectTaskPublicIdById,
  retrieveTasksForDependenciesSucceeded,
  retrieveTasksForDependenciesFailed,
  retrieveTasksForDependenciesRequested,
  editTaskSucceeded,
  editTaskFailed,
  editTaskRequested,
  archiveTaskSucceeded,
  archiveTaskFailed,
  archiveTaskRequested,
  retrieveSingleTasksInTaskListSucceeded,
  retrieveSingleTasksInTaskListFailed,
  retrieveSingleTasksInTaskListRequested,
  markAsCompleteSucceeded,
  markAsCompleteFailed,
  markAsCompleteRequested,
  sendToReviewerSucceeded,
  sendToReviewerFailed,
  sendToReviewerRequested,
  returnToAssigneeRequested,
  returnToAssigneeSucceeded,
  approveTaskRequested,
  approveTaskSucceeded,
  returnToAssigneeFailed,
  approveTaskFailed,
  returnToReviewerRequested,
  returnToReviewerSucceeded,
  returnToReviewerFailed,
  retrieveTasksRequested,
  createTaskViewSucceeded,
  createTaskViewFailed,
  createTaskViewRequested,
  renameTasksViewSucceeded,
  renameTasksViewFailed,
  renameTasksViewRequested,
  deleteTasksViewSucceeded,
  deleteTasksViewFailed,
  deleteTasksViewRequested,
  retrieveSingleTasksForTaskListSucceeded,
  retrieveSingleTasksForTaskListFailed,
  retrieveSingleTasksForTaskListRequested,
  createTaskListSucceeded,
  createTaskListFailed,
  createTaskListRequested,
  resetTaskListRequestForm,
  retrieveSingleTaskRelationsSucceeded,
  retrieveSingleTaskRelationsFailed,
  retrieveSingleTaskRelationsRequested,
  deleteTaskListSucceeded,
  deleteTaskListFailed,
  deleteTaskListRequested,
  retrieveTaskListRelationsSucceeded,
  retrieveTaskListRelationsFailed,
  retrieveTaskListRelationsRequested,
  editTaskListSucceeded,
  editTaskListFailed,
  editTaskListRequested,
  updateTasksViewSucceeded,
  updateTasksViewFailed,
  updateTasksViewRequested,
  deleteTaskCommentSucceeded,
  deleteTaskCommentFailed,
  deleteTaskCommentRequested,
  editTaskCommentSucceeded,
  editTaskCommentFailed,
  editTaskCommentRequested,
  retrieveSingleTasksInTaskListForDrawerFailed,
  retrieveSingleTasksInTaskListForDrawerSucceeded,
  retrieveSingleTasksInTaskListForDrawerRequested,
  resetEditTaskListRequestForm,
  selectTasksViewById,
  selectTasksViewsSavedIds,
  retrieveTaskFilesSucceeded,
  retrieveTaskFilesFailed,
  retrieveTaskFilesRequested,
  selectTasksByTaskId
} from '@features/tasks';
import * as Sentry from '@sentry/react';
import { selectCurrentEmployeeId } from '@features/shared';
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveEmployeesByQuery } from '@/api/employees.api';
import { closeDrawer } from '../drawer';
import { showSuccess, showWarning } from '../snackbar';
import { t } from 'i18next';
import { Empty } from '@bufbuild/protobuf';
import { retrieveFiles } from '@/api/assets.api';

function* loadTasksRequestedGenerator() {
  const employeeId: string = yield select(selectCurrentEmployeeId);
  const request: RetrieveTasksRequest = yield select(selectRetrieveTasksRequest, employeeId);

  try {
    if (request) {
      const loadTasksInitially: LoadTasksInitially = yield all({
        tasksResponse: call(retrieveTasks, request),
        tasksViewsResponse: call(retrieveTasksViews, new RetrieveTasksViewsRequest({ employeeId }))
      });
      yield put(loadTasksSucceeded(loadTasksInitially));
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadTasksFailed());
  }
}

function* retrieveGroupedTasks() {
  const employeeId: string = yield select(selectCurrentEmployeeId);
  const request: RetrieveTasksRequest = yield select(selectRetrieveTasksRequest, employeeId);

  try {
    if (request) {
      const response: RetrieveTasksResponse = yield call(retrieveTasks, request);
      yield put(retrieveTasksSucceeded(response));
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTasksFailed());
  }
}

function* retrieveTasksRequestedGenerator(action: PayloadAction<RetrieveTasksRequest>) {
  try {
    const response: RetrieveTasksResponse = yield call(retrieveTasks, action.payload);
    yield put(retrieveTasksSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTasksFailed());
  }
}

function* createSingleTaskRequestedGenerator(action: PayloadAction<CreateSingleTaskRequest>) {
  try {
    const response: SingleTaskId = yield call(createSingleTask, action.payload);
    yield put(createSingleTaskSucceeded(response));
    yield put(closeDrawer());
    yield put(showSuccess(t('created_task_successfully', { ns: 'tasks', name: action.payload.publicId })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createSingleTaskFailed());
  }
}

function* retrieveAssigneeAutocompleteItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveAssigneeAutocompleteItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAssigneeAutocompleteItemsFailed());
  }
}

function* retrieveReviewerAutocompleteItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveReviewerAutocompleteItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveReviewerAutocompleteItemsFailed());
  }
}

function* deleteSingleTaskRequestedGenerator(action: PayloadAction<DeleteSingleTaskRequest>) {
  const taskPublicId = yield select((state) => selectTaskPublicIdById(state, action.payload.singleTaskId));

  try {
    yield call(deleteSingleTask, action.payload);
    yield put(deleteSingleTaskSucceeded(action.payload.singleTaskId));
    yield put(showSuccess(t('deleted_task_successfully', { ns: 'tasks', name: taskPublicId })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteSingleTaskFailed());
  }
}

function* retrieveSingleTaskRequestedGenerator(action: PayloadAction<RetrieveSingleTaskRequest>) {
  try {
    const response: RetrieveSingleTaskResponse = yield call(retrieveSingleTask, action.payload);
    yield put(retrieveSingleTaskSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSingleTaskFailed());
  }
}

function* reactToTaskRequestedGenerator(action: PayloadAction<ReactToTaskRequest>) {
  try {
    yield call(reactToTask, action.payload);
    yield put(reactToTaskSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(reactToTaskFailed(action.payload));
  }
}

function* withdrawTaskReactionRequestedGenerator(action: PayloadAction<WithdrawTaskReactionRequest>) {
  try {
    yield call(withdrawTaskReaction, action.payload);
    yield put(withdrawTaskReactionSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(withdrawTaskReactionFailed(action.payload));
  }
}

function* commentOnTaskRequestedGenerator(action: PayloadAction<CommentOnTaskRequest>) {
  try {
    yield call(commentOnTask, action.payload);
    yield put(commentOnTaskSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(commentOnTaskFailed(action.payload.taskId));
  }
}

function* reactToTaskCommentRequestedGenerator(action: PayloadAction<ReactToTaskCommentRequest>) {
  try {
    yield call(reactToTaskComment, action.payload);
    yield put(reactToTaskCommentSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(reactToTaskCommentFailed(action.payload));
  }
}

function* withdrawTaskCommentReactionRequestedGenerator(action: PayloadAction<WithdrawTaskCommentReactionRequest>) {
  try {
    yield call(withdrawTaskCommentReaction, action.payload);
    yield put(withdrawTaskCommentReactionSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(withdrawTaskCommentReactionFailed(action.payload));
  }
}

function* retrieveTasksForDependenciesRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveTasksForDependenciesResponse = yield call(retrieveTasksForDependencies, action.payload);
    yield put(retrieveTasksForDependenciesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTasksForDependenciesFailed());
  }
}

function* editTaskRequestedGenerator(action: PayloadAction<EditTaskRequest>) {
  try {
    yield call(editTask, action.payload);
    yield put(editTaskSucceeded(action.payload));
    yield put(closeDrawer());
    yield put(showSuccess(t('edited_task_successfully', { ns: 'tasks', name: action.payload.title })));
  } catch (error) {
    yield put(showWarning(error));
    Sentry.captureException(error);
    yield put(editTaskFailed());
  }
}

function* archiveTaskRequestedGenerator(action: PayloadAction<SingleTaskId>) {
  try {
    yield call(archiveTask, action.payload);
    yield put(archiveTaskSucceeded(action.payload.id));
  } catch (error) {
    Sentry.captureException(error);
    yield put(archiveTaskFailed());
  }
}

function* retrieveSingleTasksInTaskListRequestedGenerator(action: PayloadAction<TaskListId>) {
  try {
    const response: RetrieveSingleTasksInTaskListResponse = yield call(retrieveSingleTasksInTaskList, action.payload);
    yield put(retrieveSingleTasksInTaskListSucceeded([action.payload, response]));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSingleTasksInTaskListFailed());
  }
}

function* markAsCompleteGenerator(action: PayloadAction<SingleTaskIdWithEmployeeId>) {
  try {
    yield call(markTaskAsCompleted, action.payload);
    yield put(markAsCompleteSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(markAsCompleteFailed());
  }
}

function* sendToReviewerGenerator(action: PayloadAction<SingleTaskIdWithEmployeeId>) {
  try {
    yield call(sendTaskToReviewer, action.payload);
    yield put(sendToReviewerSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(sendToReviewerFailed());
  }
}

function* returnToAssigneeGenerator(action: PayloadAction<SingleTaskIdWithEmployeeId>) {
  try {
    yield call(returnTaskToAssignee, action.payload);
    yield put(returnToAssigneeSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(returnToAssigneeFailed());
  }
}

function* approveTaskGenerator(action: PayloadAction<SingleTaskIdWithEmployeeId>) {
  try {
    yield call(approveTask, action.payload);
    yield put(approveTaskSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(approveTaskFailed());
  }
}

function* returnToReviewerGenerator(action: PayloadAction<SingleTaskIdWithEmployeeId>) {
  try {
    yield call(returnToReviewer, action.payload);
    yield put(returnToReviewerSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(returnToReviewerFailed());
  }
}

function* createTaskViewRequestedGenerator(action: PayloadAction<CreateTasksViewRequest>) {
  try {
    yield call(createTaskView, action.payload);
    yield put(createTaskViewSucceeded(action.payload));
    yield put(showSuccess(t('task_view_created', { ns: 'tasks', name: action.payload.tasksView?.name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createTaskViewFailed());
  }
}

function* renameTasksViewRequestedGenerator(action: PayloadAction<RenameTasksViewRequest>) {
  try {
    yield call(renameTasksView, action.payload);
    yield put(renameTasksViewSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(renameTasksViewFailed());
  }
}

function* deleteTasksViewRequestedGenerator(action: PayloadAction<DeleteTasksViewRequest>) {
  const { taskViewId } = action.payload;
  const tasksViewName = yield select((state) => selectTasksViewById(state, taskViewId));
  const savedIds = yield select(selectTasksViewsSavedIds);

  try {
    if (savedIds.includes(taskViewId)) {
      yield call(deleteTasksView, action.payload);
    }

    yield put(deleteTasksViewSucceeded(action.payload));
    yield put(showSuccess(t('deleted_task_view_successfully', { ns: 'tasks', name: tasksViewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteTasksViewFailed());
  }
}

function* retrieveSingleTasksForTaskListRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveSingleTasksForTaskListResponse = yield call(retrieveSingleTasksForTaskList, action.payload);
    yield put(retrieveSingleTasksForTaskListSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSingleTasksForTaskListFailed());
  }
}

function* createTaskListRequestedGenerator(action: PayloadAction<CreateTaskListRequest>) {
  try {
    const response: TaskListId = yield call(createTaskList, action.payload);
    const responseSingleTasks: RetrieveSingleTasksInTaskListResponse = yield call(retrieveSingleTasksInTaskList, new TaskListId({ id: response.id }));

    yield put(createTaskListSucceeded([response, action.payload, responseSingleTasks.singleTasks]));
    yield put(resetTaskListRequestForm());
    yield put(closeDrawer());
    yield put(showSuccess(t('created_task_list_successfully', { ns: 'tasks', name: action.payload.title })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createTaskListFailed());
  }
}

function* retrieveSingleTaskRelationsRequestedGenerator(action: PayloadAction<SingleTaskId>) {
  try {
    const response: RetrieveSingleTaskRelationsResponse = yield call(retrieveSingleTaskRelations, action.payload);
    yield put(retrieveSingleTaskRelationsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSingleTaskRelationsFailed());
  }
}

function* deleteTaskListRequestedGenerator(action: PayloadAction<DeleteTaskListRequest>) {
  try {
    yield call(deleteTaskList, action.payload);
    yield put(deleteTaskListSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteTaskListFailed());
  }
}

function* retrieveTaskListRelationsRequestedGenerator(action: PayloadAction<TaskListId>) {
  try {
    const response = yield call(retrieveTaskListRelations, action.payload);
    yield put(retrieveTaskListRelationsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTaskListRelationsFailed());
  }
}

function* editTaskListRequestedGenerator(action: PayloadAction<EditTaskListRequest>) {
  try {
    yield call(editTaskList, action.payload);
    const response: RetrieveSingleTasksInTaskListResponse = yield call(retrieveSingleTasksInTaskList, new TaskListId({ id: action.payload.taskListId }));
    yield put(editTaskListSucceeded([action.payload, response.singleTasks]));
    yield put(resetEditTaskListRequestForm());
    yield put(closeDrawer());
    yield put(showSuccess(t('edited_task_list_successfully', { ns: 'tasks', name: action.payload.title })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editTaskListFailed());
  }
}

function* updateTasksViewRequestedGenerator(action: PayloadAction<UpdateTasksViewRequest>) {
  try {
    yield call(updateTasksView, action.payload);
    yield put(updateTasksViewSucceeded(action.payload));
    yield put(showSuccess(t('tasks_view_updated_successfully', { ns: 'tasks', name: action.payload.name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateTasksViewFailed());
  }
}

function* deleteTaskCommentRequestedGenerator(action: PayloadAction<DeleteTaskCommentRequest>) {
  try {
    yield call(deleteTaskComment, action.payload);
    yield put(deleteTaskCommentSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteTaskCommentFailed());
  }
}

function* editTaskCommentRequestedGenerator(action: PayloadAction<EditTaskCommentRequest>) {
  try {
    yield call(editTaskComment, action.payload);
    yield put(editTaskCommentSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editTaskCommentFailed());
  }
}

function* retrieveSingleTasksInTaskListForDrawerGenerator(action: PayloadAction<TaskListId>) {
  try {
    const taskKindWithPrivate: TaskKindWithPrivate = yield select((state) => selectTasksByTaskId(state, action.payload.id));
    const taskList = taskKindWithPrivate.kind.value as TaskListItem;
    if (taskList.singleTasks.length === 0) {
      const tasks: RetrieveSingleTasksInTaskListResponse = yield call(retrieveSingleTasksInTaskList, action.payload);
      yield put(
        retrieveSingleTasksInTaskListForDrawerSucceeded(
          tasks.singleTasks.map(
            (task) => new SimpleTask({ id: task.singleTaskId, title: task.title, publicId: task.publicId, statusId: task.statusId, type: TaskType.SINGLE_TASK })
          )
        )
      );
    } else {
      yield put(
        retrieveSingleTasksInTaskListForDrawerSucceeded(
          taskList.singleTasks.map(
            (task) => new SimpleTask({ id: task.singleTaskId, title: task.title, publicId: task.publicId, statusId: task.statusId, type: TaskType.SINGLE_TASK })
          )
        )
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSingleTasksInTaskListForDrawerFailed());
  }
}

function* retrieveTaskFilesRequestedGenerator(action: PayloadAction<RetrieveFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveFiles, action.payload);
    yield put(retrieveTaskFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTaskFilesFailed());
  }
}

function* loadTasksRequestedWatcher() {
  yield takeLatest(loadTasksRequested.type, loadTasksRequestedGenerator);
}

function* retrieveTasksRequestedWatcher() {
  yield takeLatest(retrieveTasksRequested.type, retrieveTasksRequestedGenerator);
}

function* changeTasksGroupByWatcher() {
  yield takeLatest(changeTasksGroupBy.type, retrieveGroupedTasks);
}

function* changeTasksScopeWatcher() {
  yield takeLatest(changeTasksScope.type, retrieveGroupedTasks);
}

function* nextPageByDueDateWatcher() {
  yield takeLatest(nextPageByDueDate.type, retrieveGroupedTasks);
}

function* nextPageByStatusWatcher() {
  yield takeLatest(nextPageByStatus.type, retrieveGroupedTasks);
}

function* createSingleTaskRequestedWatcher() {
  yield takeLatest(createSingleTaskRequested.type, createSingleTaskRequestedGenerator);
}

function* retrieveEmployeesAutocompleteItemsRequestedWatcher() {
  yield takeLatest(retrieveAssigneeAutocompleteItemsRequested.type, retrieveAssigneeAutocompleteItemsRequestedGenerator);
}

function* retrieveReviewerAutocompleteItemsRequestedWatcher() {
  yield takeLatest(retrieveReviewerAutocompleteItemsRequested.type, retrieveReviewerAutocompleteItemsRequestedGenerator);
}

function* deleteSingleTaskRequestedWatcher() {
  yield takeLatest(deleteSingleTaskRequested.type, deleteSingleTaskRequestedGenerator);
}

function* retrieveSingleTaskRequestedWatcher() {
  yield takeLatest(retrieveSingleTaskRequested.type, retrieveSingleTaskRequestedGenerator);
}

function* reactToTaskRequestedWatcher() {
  yield takeLatest(reactToTaskRequested.type, reactToTaskRequestedGenerator);
}

function* withdrawTaskReactionRequestedWatcher() {
  yield takeLatest(withdrawTaskReactionRequested.type, withdrawTaskReactionRequestedGenerator);
}

function* commentOnTaskRequestedWatcher() {
  yield takeLatest(commentOnTaskRequested.type, commentOnTaskRequestedGenerator);
}

function* reactToSingleTaskCommentRequestedWatcher() {
  yield takeLatest(reactToTaskCommentRequested.type, reactToTaskCommentRequestedGenerator);
}

function* withdrawTaskCommentRequestedWatcher() {
  yield takeLatest(withdrawTaskCommentReactionRequested.type, withdrawTaskCommentReactionRequestedGenerator);
}

function* retrieveTasksForDependenciesRequestedWatcher() {
  yield takeLatest(retrieveTasksForDependenciesRequested.type, retrieveTasksForDependenciesRequestedGenerator);
}

function* editTaskRequestedWatcher() {
  yield takeLatest(editTaskRequested.type, editTaskRequestedGenerator);
}

function* archiveTaskRequestedWatcher() {
  yield takeLatest(archiveTaskRequested.type, archiveTaskRequestedGenerator);
}

function* retrieveSingleTasksInTaskListRequestedWatcher() {
  yield takeLatest(retrieveSingleTasksInTaskListRequested.type, retrieveSingleTasksInTaskListRequestedGenerator);
}

function* markAsCompleteRequestedWatcher() {
  yield takeLatest(markAsCompleteRequested.type, markAsCompleteGenerator);
}

function* sendToReviewerRequestedWatcher() {
  yield takeLatest(sendToReviewerRequested.type, sendToReviewerGenerator);
}

function* returnToAssigneeRequestedWatcher() {
  yield takeLatest(returnToAssigneeRequested.type, returnToAssigneeGenerator);
}

function* approveTaskRequestedWatcher() {
  yield takeLatest(approveTaskRequested.type, approveTaskGenerator);
}

function* returnToReviewerRequestedWatcher() {
  yield takeLatest(returnToReviewerRequested.type, returnToReviewerGenerator);
}

function* createTaskViewRequestedWatcher() {
  yield takeLatest(createTaskViewRequested.type, createTaskViewRequestedGenerator);
}

function* renameTasksViewRequestedWatcher() {
  yield takeLatest(renameTasksViewRequested.type, renameTasksViewRequestedGenerator);
}

function* deleteTasksViewRequestedWatcher() {
  yield takeLatest(deleteTasksViewRequested.type, deleteTasksViewRequestedGenerator);
}

function* retrieveSingleTasksForTaskListRequestedWatcher() {
  yield takeLatest(retrieveSingleTasksForTaskListRequested.type, retrieveSingleTasksForTaskListRequestedGenerator);
}

function* createTaskListRequestedWatcher() {
  yield takeLatest(createTaskListRequested.type, createTaskListRequestedGenerator);
}

function* retrieveSingleTaskRelationsRequestedWatcher() {
  yield takeLatest(retrieveSingleTaskRelationsRequested.type, retrieveSingleTaskRelationsRequestedGenerator);
}

function* deleteTaskListRequestedWatcher() {
  yield takeLatest(deleteTaskListRequested.type, deleteTaskListRequestedGenerator);
}

function* retrieveTaskListRelationsRequestedWatcher() {
  yield takeLatest(retrieveTaskListRelationsRequested.type, retrieveTaskListRelationsRequestedGenerator);
}

function* editTaskListRequestedWatcher() {
  yield takeLatest(editTaskListRequested.type, editTaskListRequestedGenerator);
}

function* updateTasksViewRequestedWatcher() {
  yield takeLatest(updateTasksViewRequested.type, updateTasksViewRequestedGenerator);
}

function* deleteTaskCommentRequestedWatcher() {
  yield takeLatest(deleteTaskCommentRequested.type, deleteTaskCommentRequestedGenerator);
}

function* editTaskCommentRequestedWatcher() {
  yield takeLatest(editTaskCommentRequested.type, editTaskCommentRequestedGenerator);
}

function* retrieveSingleTasksInTaskListForDrawerRequestedWatcher() {
  yield takeLatest(retrieveSingleTasksInTaskListForDrawerRequested.type, retrieveSingleTasksInTaskListForDrawerGenerator);
}

function* retrieveTaskFilesRequestedWatcher() {
  yield takeLatest(retrieveTaskFilesRequested.type, retrieveTaskFilesRequestedGenerator);
}

export function* tasksSagas() {
  yield all([
    fork(approveTaskRequestedWatcher),
    fork(archiveTaskRequestedWatcher),
    fork(changeTasksGroupByWatcher),
    fork(changeTasksScopeWatcher),
    fork(commentOnTaskRequestedWatcher),
    fork(createSingleTaskRequestedWatcher),
    fork(createTaskViewRequestedWatcher),
    fork(deleteSingleTaskRequestedWatcher),
    fork(deleteTasksViewRequestedWatcher),
    fork(editTaskRequestedWatcher),
    fork(loadTasksRequestedWatcher),
    fork(markAsCompleteRequestedWatcher),
    fork(nextPageByDueDateWatcher),
    fork(nextPageByStatusWatcher),
    fork(reactToSingleTaskCommentRequestedWatcher),
    fork(reactToTaskRequestedWatcher),
    fork(retrieveEmployeesAutocompleteItemsRequestedWatcher),
    fork(retrieveReviewerAutocompleteItemsRequestedWatcher),
    fork(retrieveSingleTaskRequestedWatcher),
    fork(retrieveSingleTasksForTaskListRequestedWatcher),
    fork(retrieveSingleTasksInTaskListRequestedWatcher),
    fork(retrieveTasksForDependenciesRequestedWatcher),
    fork(retrieveTasksRequestedWatcher),
    fork(renameTasksViewRequestedWatcher),
    fork(returnToAssigneeRequestedWatcher),
    fork(returnToReviewerRequestedWatcher),
    fork(sendToReviewerRequestedWatcher),
    fork(withdrawTaskCommentRequestedWatcher),
    fork(withdrawTaskReactionRequestedWatcher),
    fork(createTaskListRequestedWatcher),
    fork(retrieveSingleTaskRelationsRequestedWatcher),
    fork(deleteTaskListRequestedWatcher),
    fork(retrieveTaskListRelationsRequestedWatcher),
    fork(editTaskListRequestedWatcher),
    fork(updateTasksViewRequestedWatcher),
    fork(deleteTaskCommentRequestedWatcher),
    fork(editTaskCommentRequestedWatcher),
    fork(retrieveSingleTasksInTaskListForDrawerRequestedWatcher),
    fork(retrieveTaskFilesRequestedWatcher)
  ]);
}
