import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

export const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  color: theme.palette.common.black,
  fontSize: 14,
  fontWeight: 400,
  padding: '8px 12px 8px 20px',
  boxShadow: 'none',
  borderRadius: '8px',
  '&:hover': {
    boxShadow: 'none'
  },
  '&:active': {
    backgroundColor: theme.palette.customTheme.focusItem,
    color: theme.palette.common.white
  }
}));
