import { Step, StepProps, styled } from '@mui/material';

export const FlowStep = styled(Step)<StepProps>(({ theme, active, disabled }) => ({
  minWidth: 'unset',
  width: 81,
  flexBasis: 81,
  flexGrow: 0,
  flexShrink: 0,
  height: 8,
  borderRadius: 4,
  backgroundColor: active ? theme.palette.customTheme.navColorSelected : '#F2F4F7',
  cursor: 'pointer',
  display: 'inline-block',
  // pointerEvents: disabled ? 'none' : 'auto'
}));
