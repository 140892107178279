import { Backdrop, IconButton, Stack, Typography } from '@mui/material';
import { FolderIcon } from '@/shared';

export const TasksAssetsNoFiles = () => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%'
      }}
    >
      <Backdrop
        sx={(theme) => ({
          color: (theme) => theme.palette.primary.main,
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'transparent',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        })}
        open
      >
        <Stack
          gap={2}
          sx={{
            alignItems: 'center'
          }}
        >
          <IconButton
            sx={{
              width: 56,
              height: 56,
              borderRadius: '50%',
              border: '1px solid #898989'
            }}
          >
            <FolderIcon width={24} height={24} color="#898989" />
          </IconButton>
          <Typography variant="body2" sx={{ color: '#898989' }}>
            This folder seems empty
          </Typography>
        </Stack>
      </Backdrop>
    </div>
  );
};
