import { CloseIcon, StyledBlurryModal, StyledModalContentAutoWidth } from '@/shared';
import Grid from '@mui/material/Grid2';
import { IconButton } from '@mui/material';
import { TaskAssetsModalNav, TaskNewCommentAssetsListView } from '@features/drawer';

interface TaskNewCommentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TaskNewCommentModal: React.FC<TaskNewCommentModalProps> = ({ isOpen, onClose }) => {
  return (
    <StyledBlurryModal open={isOpen} onClose={onClose}>
      <StyledModalContentAutoWidth
        sx={{
          position: 'relative',
          maxWidth: {
            xs: 'auto',
            lg: '80%'
          },
          height: {
            xs: '90%'
          },
          padding: '16px',
          display: 'flex',
          borderRadius: '20px',
          boxShadow: 'none'
        }}
      >
        <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
          <CloseIcon width={24} height={24} />
        </IconButton>
        <Grid container sx={{ height: '100%' }} columnSpacing={'12px'}>
          <Grid size={3}>
            <TaskAssetsModalNav />
          </Grid>
          <Grid size={9}>
            <TaskNewCommentAssetsListView />
          </Grid>
        </Grid>
      </StyledModalContentAutoWidth>
    </StyledBlurryModal>
  );
};
