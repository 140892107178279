import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Department, RetrieveDepartmentsResponse } from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@shared/shared.model';

interface EmployeesState {
  entities: {
    departments: {
      byId: { [key: string]: Department };
      allIds: string[];
    };
  };
  ui: {
    departmentsListStatus: ActionStatus;
  };
}

const initialState: EmployeesState = {
  entities: {
    departments: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    departmentsListStatus: ActionStatus.Idle
  }
};

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    retrieveDepartmentsRequested: (state) => {
      state.ui.departmentsListStatus = ActionStatus.Pending;
    },
    retrieveDepartmentsSucceeded: (state, action: PayloadAction<RetrieveDepartmentsResponse>) => {
      const departments = action.payload.departments.filter((d) => !state.entities.departments.byId.hasOwnProperty(d.id));

      for (const department of departments) {
        state.entities.departments.byId[department.id] = department;
        state.entities.departments.allIds.push(department.id);
      }

      state.ui.departmentsListStatus = ActionStatus.Idle;
    },
    retrieveDepartmentsFailed: (state) => {
      state.ui.departmentsListStatus = ActionStatus.Failed;
    }
  }
});

const selectDepartmentsById = (state: RootState) => state.departments.entities.departments.byId;
const selectDepartmentsIds = (state: RootState) => state.departments.entities.departments.allIds;
export const selectRetrieveDepartmentsRequestedStatus = (state: RootState) => state.departments.ui.departmentsListStatus;

export const selectDepartments = createSelector([selectDepartmentsById, selectDepartmentsIds], (byId, ids) => ids.map((id) => byId[id]));

export const { retrieveDepartmentsRequested, retrieveDepartmentsSucceeded, retrieveDepartmentsFailed } = departmentsSlice.actions;
export default departmentsSlice.reducer;
