import {
  styled,
  alpha,
  TextField,
  TextFieldProps,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Switch,
  SwitchProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  Button,
  ButtonProps,
  AppBarProps,
  Paper,
  PaperProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Select,
  SelectProps,
  AccordionProps,
  AccordionSummaryProps,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  InputBase,
  Snackbar,
  InputLabel,
  InputLabelProps,
  AlertProps,
  Alert,
  alertClasses,
  svgIconClasses,
  ModalProps,
  Modal,
  inputBaseClasses,
  filledInputClasses,
  formControlClasses,
  inputLabelClasses,
  ListItemButtonProps,
  ListItemButton,
  listItemButtonClasses,
  typographyClasses,
  CardProps,
  autocompleteClasses,
  CheckboxProps,
  Checkbox,
  checkboxClasses,
  TableRow,
  TableRowProps,
  tableRowClasses,
  tableCellClasses,
  TableHead,
  TableHeadProps,
  ChipProps,
  buttonClasses,
  outlinedInputClasses,
  chipClasses,
  textFieldClasses,
  FormControlLabel,
  FormControlLabelProps,
  TableCell,
  ListItem,
  listItemClasses,
  FilledInput,
  switchClasses,
  paperClasses,
  formHelperTextClasses,
  formLabelClasses,
  buttonBaseClasses,
  inputAdornmentClasses,
  ListItemProps
} from '@mui/material';
import { forwardRef } from 'react';
import { CheckedDarkIcon, ChevronDownIcon } from '@/shared';
import { SnackbarProps } from '@mui/base';
import { ErrorOutline, MoreHoriz } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';

interface StyledTooltipProps extends TooltipProps {
  customWidth?: number;
  parentColor?: boolean;
  isVisible?: boolean;
}

interface OnboardingAccordionSummaryProps extends AccordionSummaryProps {
  isStepStarted?: boolean;
  isStepCompleted?: boolean;
}

interface StyledEditButtonProps extends ButtonProps {
  isStepCompleted?: boolean;
}

export interface StyledNavAppBarProps extends AppBarProps {
  open?: boolean;
  width?: number;
}

interface StyledFormField extends StackProps {
  isEditable?: boolean;
}

interface StyledImageGradientProps extends BoxProps {
  isEditable?: boolean;
  width: number;
  height: number;
}
interface NotificationSnackbarProps extends SnackbarProps {
  isRead: boolean;
}
interface StyledPeopleDirectoryCheckboxProps extends CheckboxProps {
  isSelected?: boolean;
}

export const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: 0,
  fontFamily: 'inherit',
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem'
}));

export const StyledPaperShadow = styled(Paper)<PaperProps>(({ theme }) => ({
  boxShadow: '0 2px 1px #0000000d, 0 0 1px #00000040',
  borderRadius: 0
}));

export const RowStartStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}));

export const RowCenterStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

export const RowStretchStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'stretch'
}));

export const RowCenterStackJustifyCenter = styled(Stack)<StackProps>(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const RowCenterStackFlexWrap = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap'
}));

export const RowCenterJustifyBetweenStackFlexWrap = styled(Stack)<StackProps>(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
}));

export const RowCenterEndStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));

export const RowCenterEndStackWrap = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'wrap'
}));

export const RowCenterJustifyBetweenStack = styled(Stack)<StackProps>(() => ({
  position: 'relative',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const ColumnStartAlignStack = styled(Stack)<StackProps>(() => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}));

export const MaterialUISwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  [`& .${switchClasses.switchBase}`]: {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    [`& .${switchClasses.checked}`]: {
      color: theme.palette.common.white,
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          theme.palette.common.white
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2
  }
}));

export const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'width'
})<{
  open?: boolean;
  width?: number;
}>(({}) => ({
  padding: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  [`& .${paperClasses.root}`]: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}));

export const StyledTooltip = styled(
  forwardRef(({ className, customWidth, isVisible, ...props }: StyledTooltipProps, ref) => <Tooltip ref={ref} {...props} classes={{ popper: className }} />)
)(({ theme, customWidth, isVisible }) => ({
  visibility: isVisible ? 'visible' : 'hidden',
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: customWidth || 300,
    padding: 0,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: '0.5rem'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.customTheme.contBgr,
    fontSize: '1.8rem',
    boxShadow: 'inherit',
    '&:before': {
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    }
  }
}));

export const StyledAlert = styled(({ ...props }: AlertProps) => (
  <Alert
    {...props}
    iconMapping={{
      warning: <ErrorOutline />,
      info: <MoreHoriz />,
      success: <CheckedDarkIcon />
    }}
  />
))(({ theme }) => ({
  [`&.${alertClasses.root}`]: {
    minHeight: 32,
    padding: '0 6px',
    alignItems: 'center'
  },
  [`&.${alertClasses.colorWarning}`]: {
    backgroundColor: '#BF5AC2',
    color: theme.palette.common.white
  },
  [`&.${alertClasses.colorSuccess}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.customTheme.contColor
  },
  [`&.${alertClasses.colorInfo}`]: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.customTheme.contColor
  },
  [`& .${alertClasses.message}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [`& .${alertClasses.icon}`]: {
    color: theme.palette.common.black,
    [`& .${svgIconClasses.root}`]: {
      fontSize: 'unset',
      width: 16,
      height: 16
    }
  }
}));

export const StyledModalContent = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '900px',
  backgroundColor: theme.palette.customTheme.contBgr,
  boxShadow: '1.5rem',
  padding: '1rem',
  [theme.breakpoints.up('sm')]: {
    minWidth: '780px'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'calc(100% - 12px)'
  }
}));

export const StyledModalContentAutoWidth = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.customTheme.contBgr,
  [theme.breakpoints.up('sm')]: {
    minWidth: '780px'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'calc(100% - 12px)'
  }
}));

export const StyledViewModal = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(100% - 24px)',
  padding: '12px',
  display: 'flex',
  borderRadius: '12px',
  boxShadow: 'none',
  backgroundColor: theme.palette.customTheme.bgr,
  overflowY: 'scroll',
  maxWidth: 'auto',
  [theme.breakpoints.up('sm')]: {
    minWidth: '780px'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'calc(100% - 12px)'
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 722
  }
}));

export const VisuallyHidden = styled(Box)<BoxProps>({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export const StyledSelect = styled(Select)<SelectProps>(({ theme }) => ({
  minWidth: 150,
  height: 50,
  'label + &': {
    marginTop: 0
  },
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'left',
    webkitAppearance: 'none',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  },
  [`& .${inputBaseClasses.root}.${inputBaseClasses.disabled}`]: {
    backgroundColor: theme.palette.grey[800]
  },
  [`& .${inputBaseClasses.root}.${inputBaseClasses.focused}`]: {
    '& input': {
      backgroundColor: theme.palette.common.white
    }
  },
  [`& .${formHelperTextClasses.root}`]: {
    textAlign: 'right'
  },
  [`& .${inputBaseClasses.inputMultiline}`]: {
    padding: 0
  },
  [`& .${paperClasses.root}`]: {
    backgroundColor: theme.palette.common.white
  },
  '& .MuiSvg-icon': {
    userSelect: 'none',
    width: 12,
    height: 7,
    display: 'inline-block',
    flexShrink: 0,
    fontSize: '1.5rem',
    position: 'absolute;',
    right: '15px',
    top: '26px',
    pointerEvents: 'none',
    color: alpha(theme.palette.common.black, 0.54),
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  '& fieldset': {
    border: 0
  },
  'div[role="combobox"]': {
    padding: '14px 7px 11px',
    backgroundColor: theme.palette.grey[800],
    textFillColor: theme.palette.common.black,
    border: '1px solid',
    borderColor: 'transparent',
    transition: theme.transitions.create(['border-color']),
    fontSize: 14
  },
  'div[role="combobox"].Mui-disabled': {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  'div[aria-expanded="true"]': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.secondary.main,
    '& ~ .MuiSvg-icon': {
      transform: 'rotate(180deg)'
    }
  }
}));

export const StyledCheckBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '50px',
  height: '50px',
  position: 'relative',
  '& .checkmark': {
    strokeDasharray: 130,
    strokeDashoffset: 130,
    strokeWidth: 5,
    stroke: theme.palette.common.white,
    fill: 'none',
    strokeLinecap: 'round',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards'
  },
  '& .checkmark-circle': {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 5,
    stroke: theme.palette.success.main,
    fill: theme.palette.success.main,
    strokeLinecap: 'round'
  },
  '@keyframes stroke': {
    '100%': {
      strokeDashoffset: 0
    }
  }
}));

export const MasterInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`.${textFieldClasses.root}`]: {
    position: 'static',
    width: '100%',

    '& label': {
      backgroundColor: theme.palette.common.white,
      color: 'inherit',
      top: '-4px'
    },
    '& label.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[200]
    }
  },
  [`& .${outlinedInputClasses.root}`]: {
    height: 50,
    '& fieldset': {
      border: 0,
      padding: 0,
      height: '100%'
    },
    '& input': {
      padding: 0
    }
  }
}));

export const AutoCompleteInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`.${textFieldClasses.root}`]: {
    width: '100%',
    backgroundColor: theme.palette.common.white,

    '& label': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      top: '-4px'
    },
    '& label.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[200]
    }
  },
  [`& .${outlinedInputClasses.root}`]: {
    fontSize: 14,
    height: 50,
    '& fieldset': {
      borderColor: theme.palette.customTheme.contColor,
      padding: '16px 11px',
      height: '100%'
    },
    '& input': {
      padding: '16px 11px'
    },
    '&:focus fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    borderColor: 'transparent',
    '& svg, path': {
      color: theme.palette.grey[200],
      fill: theme.palette.grey[200]
    }
  }
}));

export const OnboardingAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(({}) => ({
  borderRadius: '10px',
  '&::before': {
    display: 'none'
  }
}));

export const OnboardingAccordionSummary = styled(({ isStepStarted, isStepCompleted, ...props }: OnboardingAccordionSummaryProps) => (
  <AccordionSummary expandIcon={!isStepCompleted && <ChevronDownIcon />} {...props} />
))(({ theme, isStepStarted, isStepCompleted }) => ({
  height: 56,
  padding: theme.spacing(2),
  borderRadius: '10px',
  borderLeft: `8px solid ${!isStepStarted ? theme.palette.grey[500] : isStepCompleted ? theme.palette.secondary.main : theme.palette.primary.dark}`,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '&.Mui-expanded': {
    marginTop: theme.spacing(2)
  },
  '&.Mui-disabled': {
    borderColor: 'transparent'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0
  },
  '&:hover .MuiButtonBase-root': {
    display: 'inline-flex'
  }
}));

export const OnboardingAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: '32px 0 20px 40px'
  },
  [theme.breakpoints.down('lg')]: {
    padding: '24px'
  }
}));

export const StyledEditButton = styled(({ isStepCompleted, ...props }: StyledEditButtonProps) => <Button {...props} />)(({ theme, isStepCompleted }) => ({
  backgroundColor: isStepCompleted ? theme.palette.secondary.main : theme.palette.primary.dark,
  padding: '12px 24px 12px 20px',
  borderRadius: '10px',
  height: 43
}));

export const AppBarSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  marginLeft: 0,
  width: '100%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

export const AppBarSearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.black,
  '& svg': {
    zIndex: 1,
    opacity: 0.3
  }
}));

export const AppBarStyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(2),
  width: '100%',
  [`& .${inputBaseClasses.input}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
      '&:focus': {
        width: '35ch'
      }
    }
  }
}));

export const StyledUpdateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px'
}));

export const PrimaryDarkButton = styled(Button)<ButtonProps>(({ theme, startIcon, disabled }) => ({
  backgroundColor: disabled ? theme.palette.grey[300] : theme.palette.customTheme.navColor,
  borderColor: theme.palette.customTheme.navColor,
  color: theme.palette.common.white,
  borderRadius: '10px',
  padding: startIcon ? '12px 20px 12px 26px' : '12px 20px',
  minWidth: 134,
  height: 43,
  gap: '12px',
  fontWeight: 700
}));

export const SecondaryDarkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.navColorSelected,
  borderColor: theme.palette.customTheme.navColorSelected,
  color: theme.palette.common.white,
  borderRadius: '10px',
  padding: '12px 20px 12px 26px',
  minWidth: 134,
  height: 43,
  gap: '12px',
  fontWeight: 700
}));

export const ShareButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  borderColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px',
  padding: '12px 20px 12px 26px',
  width: 134,
  height: 43,
  gap: '12px',
  fontWeight: 700
}));

export const SaveButton = styled(LoadingButton)<LoadingButtonProps>(({ theme, disabled }) => ({
  backgroundColor: disabled ? theme.palette.customTheme.globalContentColorMuted : theme.palette.customTheme.brand,
  borderColor: 'transparent',
  padding: '12px 20px 12px 24px',
  height: 43,
  color: theme.palette.common.white,
  borderRadius: '10px',
  fontWeight: 700
}));

export const StyledTransparentIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  padding: 0,
  backgroundColor: 'transparent',
  gap: '4px'
}));

export const StyledHomeLinkButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px',
  textTransform: 'uppercase',
  padding: '12px 32px'
})) as typeof Button;

export const EmployeeModalTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    [`& .${autocompleteClasses.endAdornment}`]: {
      right: '17px'
    },
    [`&.${inputBaseClasses.focused}`]: {
      '& fieldset': {
        borderWidth: '1px',
        borderColor: theme.palette.secondary.main
      }
    }
  }
}));

export const StyledAddNewRowButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: theme.palette.customTheme.contColor,
  borderRadius: '10px',
  color: theme.palette.customTheme.contColor,
  padding: ' 12px 20px 12px 26px',
  height: 43,
  fontWeight: 700,
  '& path': {
    fill: theme.palette.customTheme.contColor
  },
  '&.Mui-disabled': {
    opacity: '0.25',
    backgroundColor: '#1F2020',
    borderColor: 'transparent',
    color: theme.palette.common.white,
    '& path': {
      fill: theme.palette.common.white
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '& path': {
      fill: theme.palette.common.white
    }
  }
}));

export const StyledNotificationSnackbar = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== 'isRead'
})<NotificationSnackbarProps>(({ theme, isRead }) => ({
  backgroundColor: isRead ? '#9747FF1A' : theme.palette.common.white,
  color: theme.palette.common.black,
  borderRadius: '8px',
  padding: '8px 8px 8px 20px',
  position: 'static',
  minHeight: 60,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.up('sm')]: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}));

export const StyledDatePickerFilledInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.customTheme.contColor,
      textFillColor: theme.palette.customTheme.contColor
    },
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    }
  },
  [`& .${inputBaseClasses.root}`]: {
    paddingLeft: '8px',
    [`&.Mui-disabled`]: {
      '& svg': {
        display: 'block',
        marginBottom: '5px'
      }
    }
  },
  [`& .${inputBaseClasses.disabled}`]: {
    backgroundColor: 'transparent'
  },
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: theme.palette.customTheme.primaryA,
    height: '50px',
    fontSize: '14px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '1px solid',
    paddingTop: '12px',
    borderColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['border-color', 'background-color']),
    [`&.Mui-disabled`]: {
      backgroundColor: theme.palette.customTheme.primaryA
    },
    [`& .${inputBaseClasses.input}`]: {
      padding: '0 3px',
      backgroundColor: 'transparent',
      textFillColor: theme.palette.customTheme.contColor,
      fontSize: 16,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      [`&.Mui-disabled`]: {
        textFillColor: alpha(theme.palette.common.black, 0.3)
      }
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.main
    },
    '&.Mui-error': {
      borderColor: theme.palette.customTheme.focusItem
    }
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    fontSize: '12px'
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-error': {
    color: theme.palette.customTheme.focusItem
  },
  '& .MuiFilledInput-root .MuiAutocomplete-endAdornment': {
    right: 20
  },
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
    color: theme.palette.customTheme.focusItem
  }
}));

export const StyledFilledInput = styled(
  forwardRef<HTMLDivElement, TextFieldProps>(({ placeholder = '-', ...props }, ref) => (
    <TextField fullWidth ref={ref} {...props} variant="filled" placeholder={placeholder} />
  ))
)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    fontSize: 14,
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    },
    padding: '4px',
    backgroundColor: 'transparent',
    color: theme.palette.customTheme.contColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [`& .${svgIconClasses.root}`]: {
    width: '16px',
    height: '16px',
    marginLeft: '2px'
  },
  [`& .${inputBaseClasses.root}`]: {
    paddingLeft: '8px',
    [`&.${inputBaseClasses.disabled}`]: {
      backgroundColor: theme.palette.customTheme.primaryA,
      opacity: 0.8,
      cursor: 'not-allowed'
    }
  },
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: theme.palette.customTheme.primaryA,
    height: 'auto',
    minHeight: '50px',
    fontSize: '14px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '1px solid transparent',
    paddingTop: '12px',
    transition: theme.transitions.create(['border-color', 'background-color']),
    [`&.${filledInputClasses.focused}`]: {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white
    },
    [`&.${filledInputClasses.error}`]: {
      borderColor: theme.palette.customTheme.focusItem
    }
  },
  [`& .${filledInputClasses.underline}`]: {
    // Disable the underline here
    '&:before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:after': {
      content: 'none',
      borderBottom: 'none'
    }
  },
  [`& .${formLabelClasses.root}`]: {
    fontSize: '12px',
    [`&.${formLabelClasses.error}`]: {
      color: theme.palette.customTheme.focusItem
    }
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    right: '20px'
  },
  [`& .${formLabelClasses.asterisk}`]: {
    color: theme.palette.customTheme.focusItem
  },
  '& svg': {
    marginLeft: '6px'
  }
}));

export const StyledFilledTextArea = styled(
  forwardRef<HTMLDivElement, TextFieldProps>(({ placeholder = '-', ...props }, ref) => (
    <TextField multiline minRows={1} ref={ref} {...props} variant="filled" placeholder={placeholder} />
  ))
)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    fontSize: 14,
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    },
    padding: '4px',
    backgroundColor: 'transparent',
    color: theme.palette.customTheme.contColor
  },
  [`& .${svgIconClasses.root}`]: {
    width: '16px',
    height: '16px',
    marginLeft: '2px'
  },
  [`& .${inputBaseClasses.root}`]: {
    paddingLeft: '8px',
    [`&.${inputBaseClasses.disabled}`]: {
      backgroundColor: theme.palette.customTheme.primaryA,
      opacity: 0.8,
      cursor: 'not-allowed'
    }
  },
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: theme.palette.customTheme.primaryA,
    height: 'auto',
    minHeight: '50px',
    fontSize: '14px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '1px solid transparent',
    paddingTop: '12px',
    transition: theme.transitions.create(['border-color', 'background-color']),
    [`&.${filledInputClasses.focused}`]: {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white
    },
    [`&.${filledInputClasses.error}`]: {
      borderColor: theme.palette.customTheme.focusItem
    }
  },
  [`& .${filledInputClasses.underline}`]: {
    // Disable the underline here
    '&:before': {
      content: 'none',
      borderBottom: 'none'
    },
    '&:after': {
      content: 'none',
      borderBottom: 'none'
    }
  },
  [`& .${formLabelClasses.root}`]: {
    fontSize: '12px',
    [`&.${formLabelClasses.error}`]: {
      color: theme.palette.customTheme.focusItem
    }
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    right: '20px'
  },
  [`& .${formLabelClasses.asterisk}`]: {
    color: theme.palette.customTheme.focusItem
  }
}));

export const EmployeesInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  },
  [`&.${inputBaseClasses.root}`]: {
    paddingRigh: 0
  }
}));

export const MembersInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const PermissionMembersInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    [`& .${inputAdornmentClasses.root}`]: {
      [`& .${svgIconClasses.root}`]: {
        width: 24,
        height: 24
      }
    },
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const AutocompleteInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width }) => ({
  [`& .${filledInputClasses.root}`]: {
    width,
    height: 50,
    border: 0,
    borderBottom: 0,
    borderColor: 'transparent',
    '&::before': {
      content: 'none'
    }
  },
  [`&.${formControlClasses.root}`]: {
    width,
    [`& .${filledInputClasses.focused}`]: {
      border: 0,
      borderBottom: 0,
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }
}));

export const StyledTransparentFilledInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    fontSize: 14,
    backgroundColor: 'inherit',
    borderColor: theme.palette.customTheme.contColor,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  }
}));

export const StyledInsertLinkFilledInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.customTheme.contColor,
    padding: '7px 11px',
    '&:hover': {
      backgroundColor: theme.palette.common.white
    }
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledTransparentFilledInputDatePicker = styled(StyledDatePickerFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: theme.palette.customTheme.contColor,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));

export const StyledWorkDayFilledInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderRadius: 0,
    border: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    fontWeight: 700,
    padding: '0 12px',
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: theme.palette.divider
    },
    [`&.${filledInputClasses.focused}`]: {
      borderColor: theme.palette.divider
    }
  },
  [`& .${inputLabelClasses.root}`]: {
    textTransform: 'uppercase',
    fontWeight: 400,
    position: 'absolute',
    top: '-16px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '12px'
  },
  [`&.${formControlClasses.root}`]: {
    width: width,
    '&:first-of-type': {
      [`& .${filledInputClasses.root}`]: {
        borderLeft: `1px solid ${theme.palette.divider}`
      }
    }
  }
}));

const StyledFormWrapperComponent = forwardRef<HTMLDivElement, StyledFormField>(({ isEditable, ...otherProps }, ref) => <Stack ref={ref} {...otherProps} />);

export const StyledFormWrapper = styled(StyledFormWrapperComponent, {
  shouldForwardProp: (prop) => prop !== 'isEditable'
})<{
  isEditable?: boolean;
}>(({ theme, isEditable }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 0,
  border: 0,
  borderRadius: 10,
  margin: '0',

  '& .Mui-ProfileFiledHeader': {
    transition: theme.transitions.create(['background-color', 'border'])
  },

  // Hover and Focus styles
  '&:hover, &:focus-within': {
    // Conditionally apply styles based on isEditable
    ...(isEditable === false && {
      '& .Mui-ProfileFiledHeader': {
        borderLeft: '8px solid',
        borderRadius: 10,
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.grey[700]
      }
    }),
    '& button': {
      opacity: 1
    }
  }
}));

export const StyledFormWrapperSettingsPage = styled(StyledFormWrapperComponent, {
  shouldForwardProp: (prop) => prop !== 'isEditable'
})<{
  isEditable: boolean;
}>(({ theme, isEditable }) => ({
  backgroundColor: theme.palette.customTheme.contBgr,
  padding: 0,
  borderRadius: 10,

  '& .Mui-ProfileFiledHeader': {
    transition: theme.transitions.create(['background-color', 'border'])
  },

  // Hover and Focus styles
  '&:hover, &:focus-within': {
    ...(isEditable === false && {
      '& .Mui-ProfileFiledHeader': {
        borderLeft: '8px solid',
        borderRadius: 10,
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.grey[700]
      }
    }),
    '& button': {
      opacity: 1
    }
  }
}));

export const StyledFormHeader = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isEditable'
})<{
  isEditable?: boolean;
}>(({ theme, isEditable }) => ({
  width: '100%',
  height: 60,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: isEditable ? theme.palette.customTheme.drawerBackground : theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: '10px',
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
}));

export const StyledFormLabel = styled(InputLabel)<InputLabelProps>(({ shrink = true }) => ({
  fontSize: '12px',
  top: '14px',
  left: 0,
  zIndex: 10
}));

export const StyledAudienceLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme, disabled }) => ({
  ...(disabled && {
    [`& .${typographyClasses.root}`]: {
      color: theme.palette.common.black
    }
  })
}));

export const StyledFormSubmitButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: '12px 24px 12px 20px',
  height: 43,
  borderRadius: '8px'
}));

export const StyledImportPeopleButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: '12px 24px',
  borderRadius: 10
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  position: 'relative',

  [`& .${autocompleteClasses.inputRoot}`]: {
    padding: '8px 10px'
  },

  [`& .${chipClasses.root}`]: {
    marginTop: '4px',
    marginLeft: '-2px',
    height: '24px',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,

    '& svg': {
      color: theme.palette.common.white
    }
  },

  [`& .${buttonBaseClasses.root}.${buttonBaseClasses.disabled}`]: {
    display: 'none'
  },

  [`& .${autocompleteClasses.popupIndicator}`]: {
    position: 'absolute',
    right: '20px'
  },

  [`& .${autocompleteClasses.tag}:last-of-type`]: {
    backgroundColor: '#0000008a',
    color: theme.palette.common.white,
    borderRadius: '16px',
    minWidth: '30px',
    textAlign: 'center',
    marginTop: '4px',
    marginLeft: '-2px',
    height: '24px',
    opacity: 0.38
  }
})) as typeof Autocomplete;

export const StyledImageGradient = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isEditable'
})<StyledImageGradientProps>(({ isEditable, width, height }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  borderRadius: '7px',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(0deg, rgba(37, 29, 56, ${isEditable ? 0.5 : 0}) 0%, rgba(37, 29, 56, ${isEditable ? 0.5 : 0}) 100%)`,
  width,
  height
}));

export const StyledBlurryModal = styled(Modal)<ModalProps>(({}) => ({
  background: 'rgba(217, 217, 217, 0.60)',
  backdropFilter: 'blur(10px)'
}));

export const StyledFixedWidthTypography = styled(Typography)<TypographyProps>(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 250,
  fontSize: 14
}));

export const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme, disabled }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    height: 40,
    borderRadius: '8px',
    borderBottom: 0,
    margin: 0,
    padding: '12px 4px 12px 16px',
    justifyContent: 'space-between',
    backgroundColor: disabled ? theme.palette.customTheme.solidBgr : theme.palette.customTheme.headerColorSub,
    transition: theme.transitions.create('border-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&::before': {
      content: 'none'
    }
  },
  [`& .${typographyClasses.root}`]: {
    fontWeight: 400,
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: theme.palette.primary.main
  },
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: 'rgba(151, 71, 255, 0.40)'
  },
  [`&.${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.secondary.dark
  },
  '&:hover': {
    borderLeft: `8px solid ${theme.palette.customTheme.focusItem}`
  }
})) as typeof ListItemButton;

export const AssetsStyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme, disabled }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    height: 40,
    borderRadius: '7px',
    borderBottom: 0,
    margin: '3px 0',
    padding: '12px 4px 12px 16px',
    fontSize: 14,
    justifyContent: 'space-between',
    backgroundColor: disabled ? theme.palette.customTheme.solidBgr : 'var(--00-Global-Default-Item-BGR, #EEEEEE73)',
    ransition: theme.transitions.create('border-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&::before': {
      content: 'none'
    }
  },
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.dark,
    [`& .${typographyClasses.root}`]: {
      fontWeight: 700
    }
  },
  [`&.${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.secondary.dark
  },
  '&:hover': {
    borderLeft: `8px solid ${theme.palette.primary.dark}`
  }
})) as typeof ListItemButton;

export const StyledListItemButtonNested = styled(StyledListItemButton)<ListItemButtonProps>(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    position: 'relative',
    padding: '4px 16px 4px 32px',
    borderRadius: '8px',
    height: 25,
    '&::before': {
      content: '""',
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: theme.palette.customTheme.contColor,
      position: 'absolute',
      left: 15,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  }
}));

export const StyledListAssetItemButtonNested = styled(StyledListItemButton)<ListItemButtonProps>(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    position: 'relative',
    padding: '4px 16px 4px 40px',
    borderRadius: '8px',
    height: 25,
    '&::before': {
      content: '""',
      width: 7,
      height: 7,
      borderRadius: '50%',
      backgroundColor: theme.palette.customTheme.contColor,
      position: 'absolute',
      left: 25,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  }
}));

export const StyledCountOpenDrawerButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.customTheme.focusItem,
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));

export const StyledGroupWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})<StackProps & { expanded?: boolean; disabled?: boolean }>(({ theme, expanded, disabled }) => ({
  border: disabled ? 0 : `1px solid ${theme.palette.common.black}`,
  borderRadius: 4,
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  maxHeight: expanded ? 550 : 60,
  overflowY: 'scroll'
}));

export const StyledGroupCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  [`&.${checkboxClasses.root}`]: {
    color: theme.palette.common.black
  },
  [`&.${checkboxClasses.checked}`]: {
    color: theme.palette.customTheme.focusItem
  },
  [`&.${checkboxClasses.checked}.${checkboxClasses.disabled}`]: {
    color: theme.palette.grey[900],
    pointerEvents: 'none'
  },
  '& .MuiSvgIcon-root': {
    width: 32,
    height: 32
  }
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'editable'
})<TableRowProps & { editable?: boolean }>(({ theme, editable }) => ({
  [`&.${tableRowClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.customTheme.zebra
    }
  },
  [`&.${tableRowClasses.hover}:hover`]: {
    backgroundColor: theme.palette.customTheme.drawerBackground
  },
  [`&.${tableRowClasses.root}.${tableRowClasses.selected}.${tableRowClasses.hover}`]: {
    backgroundColor: theme.palette.customTheme.drawerBackground
  },

  [`&.${tableRowClasses.selected}`]: {
    [`& .${checkboxClasses.root}`]: {
      borderLeft: `7px solid ${theme.palette.customTheme.focusItem}`,
      borderRadius: 4
    }
  },
  [`& .${tableCellClasses.paddingCheckbox}`]: {
    border: 0,
    padding: 0
  },
  [`& .${checkboxClasses.root}`]: {
    borderLeft: '7px solid transparent',
    height: 50,
    backgroundColor: 'transparent'
  }
}));

export const StyledTableHead = styled(TableHead, {
  shouldForwardProp: (prop) => prop !== 'editable'
})<TableHeadProps & { editable?: boolean }>(({ theme, editable }) => ({
  borderRadius: 10,
  backgroundColor: theme.palette.common.white,
  [`& .${tableRowClasses.root}`]: {
    [`& .${tableCellClasses.root}`]: {
      border: 0
    },
    [`& .${tableCellClasses.paddingCheckbox}`]: {
      border: 0,
      padding: 0
    },
    [`& .${checkboxClasses.indeterminate}`]: {
      color: theme.palette.customTheme.focusItem
    }
  }
}));

export const StyledTransparentButton = styled(Button)<ButtonProps>(({}) => ({
  minWidth: 'auto',
  padding: 0,
  margin: 0,
  fontWeight: 700,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

export const StyledActionButton = styled(StyledTransparentButton)<ButtonProps>(({ theme, ...props }) => ({
  minWidth: 'auto',
  height: 40,
  width: '100%',
  margin: 0,
  padding: '12px 24px',
  fontSize: 14,
  lineHeight: 'normal',
  fontWeight: 400,
  justifyContent: props.endIcon ? 'space-between' : 'flex-start',
  backgroundColor: theme.palette.customTheme.headerColorSub,
  borderRadius: '7px',
  borderLeft: '7px solid',
  borderColor: 'transparent',
  transition: theme.transitions.create('border-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  [`&.${buttonClasses.disabled}`]: {
    [`& .${buttonClasses.endIcon}`]: {
      '& svg, path': {
        fill: alpha(theme.palette.common.black, 0.26),
        stroke: alpha(theme.palette.common.black, 0.26)
      }
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.customTheme.headerColorSub,
    borderColor: theme.palette.customTheme.focusItem
  }
}));

export const StyledProfileLink = styled(Button)<ButtonProps>(() => ({
  opacity: 0,
  fontWeight: 700,
  ml: 'auto',
  backgroundColor: 'transparent',
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));

export const StyledTableCell = styled(TableCell)(() => ({
  width: 200,
  height: 40,
  padding: '8px 16px',
  backgroundColor: 'transparent'
}));

export const PrimaryTypographyHeader = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.customTheme.navColor
}));

export const PrimaryTypographyHeaderTitleCase = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.customTheme.navColor,
  textTransform: 'capitalize'
}));

export const PrimaryTypographySub = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.customTheme.navColor
}));

export const PrimaryTypographyBodyBold = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.customTheme.navColor
}));

export const StyledCategoryListItem = styled(ListItem)(({ theme }) => ({
  [`&.${listItemClasses.root}`]: {
    borderRadius: '8px',
    transition: theme.transitions.create('background-color, transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    })
  },
  [`&.${listItemClasses.root}:hover`]: {
    backgroundColor: theme.palette.customTheme.hoverItem,
    transform: 'translateX(5px)'
  }
}));

export const StyledRecordAutocompleteInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ theme, width }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: '1px solid',
    borderBottom: 0,
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width,
    [`& .${filledInputClasses.focused}`]: {
      borderColor: theme.palette.customTheme.contColor,
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }
}));

//TODO @Stevo -> check
export const StyledErbButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '12px 24px 12px 20px',
  borderRadius: '10px',
  height: 43
}));

export const StyledGenericForm = styled(StyledFormWrapperComponent)`
  ${({ theme, isEditable }) => ({
    backgroundColor: theme.palette.customTheme.contBgr,
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.customTheme.borderMaster,
    borderRadius: 10,
    margin: '0 0 16px 0',
    '& .Mui-ProfileFiledHeader': {
      transition: theme.transitions.create(['background-color, border'])
    },
    '&:hover, &:focus-within': {
      ...(!isEditable
        ? {
            '& .Mui-ProfileFiledHeader': {
              borderLeft: '8px solid',
              borderRadius: 10,
              borderColor: theme.palette.primary.dark,
              backgroundColor: isEditable ? theme.palette.customTheme.drawerBackground : theme.palette.grey[700]
            }
          }
        : {}),
      '& button': {
        opacity: 1
      }
    }
  })}
`;

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<StyledPeopleDirectoryCheckboxProps>(({ theme, isSelected }) => ({
  [`&.${checkboxClasses.root}`]: {
    color: theme.palette.common.black,
    padding: 0
  },
  [`&.${checkboxClasses.checked}`]: {
    color: isSelected ? theme.palette.common.white : theme.palette.customTheme.focusItem
  },
  [`&.${checkboxClasses.checked}.${checkboxClasses.disabled}`]: {
    color: theme.palette.grey[900],
    pointerEvents: 'none'
  },
  [`&. ${svgIconClasses.root}`]: {
    border: '1px solid'
  }
}));

export const StyledRenameInput = styled(FilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width, theme }) => ({
  minWidth: width,
  maxWidth: '100%',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&::before': {
    content: 'none'
  },
  '&::after': {
    content: 'none'
  },
  '& .MuiInputBase-input': {
    '&::selection': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4)
    }
  },
  [`&.Mui-focused`]: {
    backgroundColor: 'transparent'
  },
  [`& input`]: {
    padding: 0
  }
}));

export const StyledAutocompleteListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  [`&.${listItemClasses.root}`]: {
    height: 50,
    padding: '7px 3px',
    borderRadius: '4px',
    borderLeft: '4px solid transparent',
    transition: theme.transitions.create('background-color, padding, height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '&:hover': {
      // backgroundColor: theme.palette.customTheme.hoverItem,
      borderColor: theme.palette.customTheme.focusItem
    }
  }
}));

export const StyledAutocompleteListItemButton = styled(ListItemButton)(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    minHeight: 46,
    padding: 1,
    borderRadius: '8px',
    transition: theme.transitions.create('background-color, padding, height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '&:hover': {
      backgroundColor: theme.palette.customTheme.hoverItem
    }
  }
}));

export const StyledPrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 135,
  backgroundColor: theme.palette.customTheme.contColor,
  color: theme.palette.common.white,
  padding: '12px 16px',
  borderRadius: 8,
  height: 43,
  lineHeight: 'normal'
}));

export const StackCenterAlign = styled(Stack)<StackProps>(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}));

export const StackCenterAlignJustifyEnd = styled(Stack)<StackProps>(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'flex-end',
  textAlign: 'center'
}));

export const StackVerticalAlignEnd = styled(Stack)<StackProps>(() => ({
  alignItems: 'flex-end'
}));

export const CaptionTypographyMuted = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.customTheme.globalContentColorMuted
}));

export const FullWidthBox = styled(Box)<BoxProps>(() => ({
  width: '100%'
}));

export const RelativeBox = styled(Box)<BoxProps>(() => ({
  position: 'relative'
}));

export const BottomElementObserver = styled(Box)<BoxProps>({
  width: '100%',
  height: 1
});

export const FullWidthStack = styled(Stack)<StackProps>(() => ({
  width: '100%'
}));

export const PrimaryCancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'flex',
  padding: '12px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  height: 34,
  flex: '1 0 0',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: theme.palette.common.black,
  background: theme.palette.common.white
}));

export const PrimaryApplyButton = styled(Button)<ButtonProps>(({ theme, disabled }) => ({
  display: 'flex',
  padding: '12px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  height: 34,
  flex: '1 0 0',
  borderRadius: '4px',
  color: theme.palette.common.white,
  backgroundColor: disabled ? theme.palette.customTheme.globalContentColorMuted : theme.palette.customTheme.focusItem
}));

export const StyledTaskLink = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'none',
  opacity: 0,
  fontSize: 14,
  fontWeight: 700,
  ml: 'auto',
  backgroundColor: 'transparent',
  color: theme.palette.customTheme.globalContentColorMuted,
  transition: theme.transitions.create('opacity, display, color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    color: theme.palette.text.primary
  }
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .even': {
    backgroundColor: theme.palette.customTheme.zebraFreezeOne
  },
  '& .odd': {
    backgroundColor: theme.palette.customTheme.zebraFreezeTwo
  },
  '& .MuiDataGrid-container--top [role=row]': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '14px'
  }
}));
