import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';

export const BillingButton = styled(LoadingButton)<LoadingButtonProps>(({ theme, disabled }) => ({
  height: 43,
  backgroundColor: disabled ? theme.palette.customTheme.globalContentColorMuted : theme.palette.customTheme.focusItem,
  borderRadius: '10px',
  color: theme.palette.common.white,
  fontWeight: 700,
  padding: '12px 32px',
  '& path': {
    fill: theme.palette.common.white
  },
  '&.Mui-disabled': {
    '& path': {
      fill: 'rgba(0, 0, 0, 0.12)'
    }
  }
}));
