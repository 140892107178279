import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';

export const TasksPrimaryButton = styled(LoadingButton)<LoadingButtonProps>(({ theme, disabled }) => ({
  minWidth: 135,
  backgroundColor: disabled ? theme.palette.grey[300] : theme.palette.customTheme.contColor,
  color: theme.palette.common.white,
  padding: '12px 16px',
  borderRadius: 8,
  height: 35,
  lineHeight: 'normal',
  fontWeight: 700
}));
