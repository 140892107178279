import { Chip, ChipProps, chipClasses, styled } from '@mui/material';

export const CouponChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: '#FF9864',
  padding: '3px 7px',
  borderRadius: '8px',
  [`& .${chipClasses.label}`]: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.primary.main,
    padding: 0,
    margin: 0
  }
}));
