import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  removeNewAddressEvents,
  selectAddressCategory,
  selectNewAddressEvents,
  updateAddressRequested,
  AddressCategoryTable,
  createAddressSchema,
  selectProfileNotification
} from '@features/employee-profile';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';

import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFormWrapper,
  StyledFormHeader,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  ActionStatus
} from '@/shared';
import { selectCurrentEmployeeId } from '@features/shared';
import { Address, UpdateAddressRequest } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface AddressCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const AddressCategory = forwardRef<HTMLDivElement, AddressCategoryProps>(({ employeeId }, ref) => {
  const name = 'Address';
  const { t } = useTranslation(['common', 'employee_profile']);
  const dispatch = useAppDispatch();
  const addressCategory = useAppSelector(selectAddressCategory);
  const newAddressEvent = useAppSelector(selectNewAddressEvents);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'address';
  const [isEditable, setIsEditable] = useState(false);
  const addressSchema = useMemo(() => createAddressSchema(t), [t]);
  const {
    formState: { dirtyFields, errors },
    reset,
    handleSubmit,
    setValue
  } = useForm<Address>({
    mode: 'all',
    resolver: zodResolver(addressSchema),
    defaultValues: {
      addressEvents: addressCategory.addressEvents
    }
  });

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      addressEvents: addressCategory.addressEvents
    });
    dispatch(removeNewAddressEvents());
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleSetEditable = () => {
    setIsEditable(true);
  };

  const onSubmit = () => {
    dispatch(
      updateAddressRequested({
        employeeId,
        addressEvents: addressCategory.addressEvents
      } as UpdateAddressRequest)
    );
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      addressEvents: addressCategory.addressEvents
    });
  }, [addressCategory]);

  useEffect(() => {
    if (newAddressEvent.length > 0) {
      setValue('addressEvents', addressCategory.addressEvents, { shouldDirty: true });
    }
  }, [newAddressEvent]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'address' } as ProfileCategoryInfo}>
              <IconButton
                className="Mui-Edit"
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>
                  {t('edit', { ns: 'common' })} {name}
                </VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <PrimaryDarkButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
        </Stack>
      </Box>
      <AddressCategoryTable handleSetEditable={handleSetEditable} employeeId={employeeId} />
    </StyledFormWrapper>
  );
});
