export enum BillingPeriod {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export interface SubscriptionPlan {
  name: 'free-monthly' | 'starter-monthly' | 'growth-monthly' | 'free-yearly' | 'starter-yearly' | 'growth-yearly';
  price: number;
  usersCount: number;
  storage: number;
  billingPeriod: BillingPeriod;
  stripeLookupKey: string;
}

export const ADDITIONAL_SEATS_PRICE_IN_USD: number = 5;
export const ADDITIONAL_STORAGE_PRICE_IN_USD: number = 5;

export const subscriptionPlans: Record<string, SubscriptionPlan> = {
  'free-monthly': {
    name: 'free-monthly',
    price: 0,
    usersCount: 10,
    storage: 1,
    billingPeriod: BillingPeriod.Monthly,
    stripeLookupKey: 'free_monthly_usd'
  },
  'starter-monthly': {
    name: 'starter-monthly',
    price: 20,
    usersCount: 250,
    storage: 5,
    billingPeriod: BillingPeriod.Monthly,
    stripeLookupKey: 'starter_monthly_usd'
  },
  'growth-monthly': {
    name: 'growth-monthly',
    price: 50,
    usersCount: 1000,
    storage: 10,
    billingPeriod: BillingPeriod.Monthly,
    stripeLookupKey: 'growth_monthly_usd'
  },
  'free-yearly': {
    name: 'free-yearly',
    price: 0,
    usersCount: 10,
    storage: 1,
    billingPeriod: BillingPeriod.Yearly,
    stripeLookupKey: 'free_yearly_usd'
  },
  'starter-yearly': {
    name: 'starter-yearly',
    price: 200,
    usersCount: 50,
    storage: 5,
    billingPeriod: BillingPeriod.Yearly,
    stripeLookupKey: 'starter_yearly_usd'
  },
  'growth-yearly': {
    name: 'growth-yearly',
    price: 500,
    usersCount: 1000,
    storage: 10,
    billingPeriod: BillingPeriod.Yearly,
    stripeLookupKey: 'growth_yearly_usd'
  }
};
