import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import Lightbox, { isImageSlide } from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import { Stack, Box, Typography, alpha } from '@mui/material';
import { isImageUrl, isVideoUrl, isDocumentUrl, getExtensionFromUrl } from '@/utils';
import { InlinePostEdit, PostDoc, PostDocumentViewModal, PostMedia } from '@features/homepage';
import VideocamIcon from '@mui/icons-material/Videocam';
import { fetchBlobFileNames } from '@api/blob-storage.api';
import { DocFileCard, RowCenterStack } from '@/shared';
import * as Sentry from '@sentry/react';

import 'plyr-react/plyr.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

export const sanitizeConfig = {
  ALLOWED_TAGS: ['em-emoji', 'employee', 'emoji', 'b', 'i', 'em', 'strong', 'a', 'br', 'p', 'div', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'li'],
  ALLOWED_ATTR: [
    'native',
    'size',
    'skin',
    'set',
    'style',
    'loading',
    'class',
    'data-id',
    'data-denotation-char',
    'data-index',
    'data-value',
    'data-name',
    'href',
    'target',
    'rel'
  ]
};

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) {
    newWindow.focus();
  }
};

interface PostContentProps {
  postId?: string;
  authorId?: string;
  message: string;
  medias: PostMedia[];
  docs: PostDoc[];
  isEditable?: boolean;
  handleSetPostEditable?: (id: string) => void;
}

export const PostContent: React.FC<PostContentProps> = ({ postId, authorId, message, medias, docs, isEditable, handleSetPostEditable }) => {
  // TODO: check if neccessary.
  // On sending the post and comment messages, we are sanitizing the message content (which is HTML)
  const rteContent = { __html: DOMPurify.sanitize(message, sanitizeConfig) };
  const [index, setIndex] = useState(-1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const thumbnailsRef = useRef(null);
  const zoomRef = useRef(null);
  const [fileNames, setFileNames] = useState<PostDoc[]>([]);
  const [isFileModalOpen, setIsFileModalOpen] = useState<boolean>(false);

  const [activeFileUrl, setActiveFileUrl] = useState<string>('');

  useEffect(() => {
    const fetchAttachmentFileNames = async () => {
      try {
        const docFiles = await fetchBlobFileNames(docs.map((df) => df.src));
        // old docs were in mediaUrls
        const mediaFiles = await fetchBlobFileNames(medias.filter((df) => isDocumentUrl(df.src)).map((f) => f.src));
        const docFileNames = docFiles.map((name, index) => ({
          name,
          src: docs[index].src,
          alt: docs[index].alt
        }));
        // old docs were in mediaUrls
        const mediaFileNames = mediaFiles.map((name, index) => ({
          name,
          src: medias[index].src,
          alt: medias[index].alt
        }));
        setFileNames([...mediaFileNames, ...docFileNames]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchAttachmentFileNames();
  }, [medias, docs]);

  const handleOpen = (index: number) => {
    setIndex(index);
  };

  const handleSetActiveFileUrl = (url: string) => {
    setActiveFileUrl(url);
    setIsFileModalOpen(true);
  };

  const handleFileModalClose = () => {
    setIsFileModalOpen(false);
    setActiveFileUrl('');
  };

  // Create a new array with the 'type' property added based on the file extension
  const slidesArray = medias
    .map((file, idx) => {
      if (isVideoUrl(file.src)) {
        return {
          key: file.name || idx,
          type: 'video',
          width: 1280,
          height: 720,
          sources: [
            {
              src: file.src,
              name: file.name,
              type: `video/${getExtensionFromUrl(file.src)}`
            }
          ],
          download: file.src
        };
      } else if (isDocumentUrl(file.src)) {
        return null;
      } else {
        return {
          ...file,
          key: file.name || idx,
          name: file.name,
          download: file.src
        };
      }
    })
    .filter((slide) => slide !== null);

  return (
    <Stack gap={1} ref={containerRef}>
      {isEditable && (
        <InlinePostEdit postId={postId!} authorId={authorId!} files={medias} docs={docs} content={message} handleSetPostEditable={handleSetPostEditable!} />
      )}
      {!isEditable && (
        <>
          <Box
            component="div"
            dangerouslySetInnerHTML={rteContent}
            sx={{
              '& h1, h2, h3, h4, h5, p': {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '4px'
              },
              '& h1 img': {
                width: 24,
                height: 24
              },
              fontSize: 16,
              wordBreak: 'break-word'
            }}
          />
          <RowCenterStack
            gap={1}
            sx={{
              flexWrap: 'wrap'
            }}
          >
            {medias.length > 0 && (
              <RowCenterStack
                className="Mui-PostMedia"
                gap={2}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  borderRadius: 4,
                  padding: 2,
                  border: '1px solid',
                  borderColor: (theme) => theme.palette.customTheme.borderMaster,
                  width: {
                    xs: '100%',
                    lg: 358
                  }
                }}
              >
                {medias.slice(0, 1).map((image, index) => (
                  <Stack
                    key={image.name || index}
                    onClick={() => handleOpen(index)}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    {isImageUrl(image.src) && (
                      <Box
                        component="img"
                        src={image.src}
                        alt={image.alt}
                        loading="lazy"
                        sx={{
                          display: 'block',
                          objectFit: 'cover',
                          objectPosition: 'top center',
                          minWidth: {
                            xs: '100%',
                            md: 282
                          },
                          height: 282,
                          borderRadius: 2
                        }}
                      />
                    )}
                    {isVideoUrl(image.src) && (
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          height: 225,
                          '& video': {
                            objectFit: 'fill',
                            width: '100%',
                            height: 225
                          }
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backgroundColor: (theme) => alpha(theme.palette.common.black, 0.5),
                            width: '100%',
                            height: '100%'
                          }}
                        />
                        <VideocamIcon
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: (theme) => theme.palette.common.white
                          }}
                        />
                        <video>
                          <source src={image.src} type="video/mp4" />
                        </video>
                      </Box>
                    )}
                  </Stack>
                ))}
                {slidesArray.length > 1 && (
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.common.black,
                      fontWeight: 700,
                      fontSize: 24,
                      minWidth: 40
                    }}
                  >
                    + {slidesArray.length - 1}
                  </Typography>
                )}
              </RowCenterStack>
            )}
            {fileNames &&
              fileNames.map((doc, index) => (
                <Stack key={doc.name + index} onClick={() => handleSetActiveFileUrl(doc.src)} sx={{ cursor: 'pointer' }}>
                  <DocFileCard key={doc.name || index} src={doc.src} name={doc.name} />
                </Stack>
              ))}
          </RowCenterStack>
          <PostDocumentViewModal fileUrl={activeFileUrl} isModalOpen={isFileModalOpen} onClose={handleFileModalClose} />
          <Lightbox
            plugins={[Video, Counter, Thumbnails, Zoom, Download]}
            slides={slidesArray as PostMedia[]}
            thumbnails={{
              ref: thumbnailsRef,
              border: 0,
              borderRadius: 7
            }}
            index={index}
            open={index >= 0}
            close={() => setIndex(-1)}
            download={{
              download: async ({ slide }) => {
                if (isImageSlide(slide)) {
                  openInNewTab(slide.src);
                }
              }
            }}
            video={{
              muted: false
            }}
            carousel={{
              imageProps: {
                style: {
                  borderRadius: 8
                }
              }
            }}
            zoom={{
              ref: zoomRef,
              scrollToZoom: true,
              zoomInMultiplier: 4,
              maxZoomPixelRatio: 50
            }}
            counter={{
              container: {
                style: {
                  position: 'absolute',
                  top: 8,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: 16,
                  fontWeight: 700,
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  borderRadius: 7,
                  padding: 8
                }
              }
            }}
            styles={{
              container: {
                background: 'rgba(217, 217, 217, 0.60)',
                backdropFilter: 'blur(10px)',
                cursor: 'grabbing'
              },
              icon: {
                width: 24,
                height: 24
              },
              button: {
                backgroundColor: '#000000',
                width: 36,
                height: 36,
                borderRadius: 7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                filter: 'none'
              },
              navigationNext: {
                padding: 0,
                right: 16
              },
              navigationPrev: {
                padding: 0,
                left: 16
              },
              toolbar: {
                padding: 0,
                top: 'unset',
                right: 'unset',
                bottom: 8,
                left: '50%',
                transform: 'translateX(-50%)',
                gap: 8
              }
            }}
          />
        </>
      )}
    </Stack>
  );
};
