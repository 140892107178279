import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { CheckCouponValidityRequest, CheckCouponValidityResponse } from '@thrivea/payment-client';
import { BillingPeriod, SubscriptionPlan, subscriptionPlans } from '@features/admin-settings';
import { BasicInfoFormType, CreateNewTenantRequest } from '@features/welcome';

interface WelcomeState {
  createNewTenantStatus?: ActionStatus;
  isCouponValid?: boolean;
  activeStep: number;
  isTosAccepted: boolean;
  couponCode: string;
  subscriptionPlan: SubscriptionPlan;
  basicInfoForm: BasicInfoFormType;
  ui: {
    couponStatus: ActionStatus;
    billingPeriod: BillingPeriod;
  };
}

const initialState: WelcomeState = {
  createNewTenantStatus: undefined,
  activeStep: 0,
  isTosAccepted: false,
  couponCode: '', // At the moment, only exist coupon code with 100% discount
  subscriptionPlan: subscriptionPlans['free-monthly'],
  basicInfoForm: {} as BasicInfoFormType,
  ui: {
    couponStatus: ActionStatus.Idle,
    billingPeriod: BillingPeriod.Monthly
  }
};

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    checkCouponRequested: (state, action: PayloadAction<CheckCouponValidityRequest>) => {
      state.ui.couponStatus = ActionStatus.Pending;
    },
    checkCouponSucceeded: (state, action: PayloadAction<CheckCouponValidityResponse>) => {
      state.isCouponValid = action.payload.isValid;
      state.ui.couponStatus = ActionStatus.Idle;
    },
    checkCouponFailed: (state) => {
      state.ui.couponStatus = ActionStatus.Failed;
    },
    createNewTenantRequested: (state, action: PayloadAction<CreateNewTenantRequest>) => {
      state.activeStep = 3;
      state.createNewTenantStatus = ActionStatus.Pending;
    },
    createNewTenantSucceeded: (state) => {
      state.createNewTenantStatus = ActionStatus.Idle;
    },
    createNewTenantFailed: (state) => {
      state.createNewTenantStatus = ActionStatus.Failed;
    },
    acceptTos: (state, action: PayloadAction<boolean>) => {
      state.isTosAccepted = action.payload;
    },
    setCouponCode: (state, action: PayloadAction<string>) => {
      state.couponCode = action.payload;
    },
    changePlan: (state, action: PayloadAction<SubscriptionPlan>) => {
      state.subscriptionPlan = action.payload;
    },
    toggleBillingPeriodForSelectedPlan: (state, action: PayloadAction<BillingPeriod>) => {
      const subscriptionPlanName =
        action.payload === BillingPeriod.Monthly
          ? state.subscriptionPlan.name.replace('yearly', 'monthly')
          : state.subscriptionPlan.name.replace('monthly', 'yearly');

      state.subscriptionPlan = subscriptionPlans[subscriptionPlanName];
    },
    toggleBillingPeriod: (state, action: PayloadAction<BillingPeriod>) => {
      state.ui.billingPeriod = action.payload;
    },
    changeActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    updateBasicInfoFormData(state, action: PayloadAction<BasicInfoFormType>) {
      state.basicInfoForm = action.payload;
    }
  }
});

export const selectCreateNewTenantStatus = (state: RootState) => state.welcome.createNewTenantStatus;
export const selectIsCouponValid = (state: RootState) => state.welcome.isCouponValid;

export const selectActiveStep = (state: RootState) => state.welcome.activeStep;
export const selectIsTosAccepted = (state: RootState) => state.welcome.isTosAccepted;
export const selectCouponCode = (state: RootState) => state.welcome.couponCode;
export const selectSubscriptionPlan = (state: RootState) => state.welcome.subscriptionPlan;
export const selectTotalPrice = (state: RootState) => state.welcome.subscriptionPlan.price * (state.welcome.isCouponValid ? 0 : 1);
export const selectBasicInfoFormData = (state: RootState) => state.welcome.basicInfoForm;
export const selectCouponStatus = (state: RootState) => state.welcome.ui.couponStatus;

export const selectBillingPeriod = (state: RootState) => state.welcome.ui.billingPeriod;
export const selectIsBillingYearly = (state: RootState) => state.welcome.ui.billingPeriod === BillingPeriod.Yearly;

export const selectIsActivePlanBillingYearly = (state: RootState) => state.welcome.subscriptionPlan.billingPeriod === BillingPeriod.Yearly;

export const {
  checkCouponRequested,
  checkCouponSucceeded,
  checkCouponFailed,
  createNewTenantRequested,
  createNewTenantSucceeded,
  createNewTenantFailed,
  acceptTos,
  setCouponCode,
  changePlan,
  toggleBillingPeriod,
  toggleBillingPeriodForSelectedPlan,
  changeActiveStep,
  updateBasicInfoFormData
} = welcomeSlice.actions;

export default welcomeSlice.reducer;
