import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  CreateNewTenantRequest,
  checkCouponFailed,
  checkCouponRequested,
  checkCouponSucceeded,
  createNewTenantFailed,
  createNewTenantRequested,
  createNewTenantSucceeded
} from '@features/welcome';
import { PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CreateOrganizationRequest } from '@thrivea/organization-client';
import { CreateTenantRequest } from '@thrivea/admin-onboarding-client';
import { createOrganization } from '@api/organization.api';
import { createTenant } from '@api/admin.api';
import { checkCouponValidity } from '@api/payment.api';
import { CheckCouponValidityRequest, CheckCouponValidityResponse } from '@thrivea/payment-client';

function* checkCouponRequestedGenerator(action: PayloadAction<CheckCouponValidityRequest>) {
  try {
    const response: CheckCouponValidityResponse = yield call(checkCouponValidity, action.payload);

    yield put(checkCouponSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(checkCouponFailed());
  }
}

function* createNewTenantRequestedGenerator(action: PayloadAction<CreateNewTenantRequest>) {
  const { organizationId, organizationName, firstName, lastName, emailAddress, subscriptionPlan, coupon } = action.payload;
  const chosenPlan = `${subscriptionPlan.replace('-', '_')}_usd`;

  try {
    yield all([
      call(
        createOrganization,
        new CreateOrganizationRequest({
          organizationId,
          organizationName,
          firstName,
          lastName,
          emailAddress,
          chosenPlan,
          coupon
        })
      ),
      call(createTenant, new CreateTenantRequest({ organizationId, organizationName }))
    ]);

    yield put(createNewTenantSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(createNewTenantFailed());
  }
}

function* checkCouponRequestedWatcher() {
  yield takeLatest(checkCouponRequested.type, checkCouponRequestedGenerator);
}

function* createNewTenantRequestedWatcher() {
  yield takeLatest(createNewTenantRequested.type, createNewTenantRequestedGenerator);
}

export function* welcomeSagas() {
  yield all([fork(checkCouponRequestedWatcher), fork(createNewTenantRequestedWatcher)]);
}
