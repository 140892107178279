import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const SiteCardTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'completed'
})<{ completed: boolean }>(({ completed }) => ({
  fontStyle: completed ? 'italic' : 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 250,
  fontSize: 14
}));
