import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { DocTypeFileIcon, FolderIcon, ImageTypeFileIcon, VideoTypeFileIcon } from '@/shared';
import { FileType } from '@thrivea/organization-client';

interface AssetsFileCardProps {
  fileType: FileType;
  labelText: string;
}

export const AssetsFileCard: React.FC<AssetsFileCardProps> = ({ fileType, labelText }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: 36,
        height: 36,
        borderRadius: '4px',
        borderColor: (theme) => theme.palette.common.black,
        backgroundColor: (theme) => theme.palette.customTheme.zebraFreezeTwo,
        padding: 0
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CardMedia
          sx={{
            padding: 0,
            marginBottom: '-5px'
          }}
        >
          {fileType === FileType.PHOTO && <ImageTypeFileIcon width={16} height={16} />}
          {fileType === FileType.AUDIO || (fileType === FileType.VIDEO && <VideoTypeFileIcon width={16} height={16} />)}
          {(fileType === FileType.PDF || fileType === FileType.PRESENTATION || fileType === FileType.SPREADSHEET || fileType === FileType.DOCUMENT) && (
            <DocTypeFileIcon width={16} height={16} />
          )}
          {fileType === FileType.FOLDER && <FolderIcon width={16} height={16} />}
        </CardMedia>
        {fileType !== FileType.FOLDER && (
          <CardContent
            sx={{
              padding: 0,
              '&:last-child': {
                padding: 0
              }
            }}
          >
            <Stack
              sx={{
                width: 26,
                height: 16,
                padding: 1,
                border: '1px solid',
                borderColor: (theme) => theme.palette.common.black,
                backgroundColor: (theme) => theme.palette.common.white,
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Typography sx={{ textTransform: 'uppercase', fontWeight: 700, fontSize: 10 }}>{labelText.slice(0, 3)}</Typography>
            </Stack>
          </CardContent>
        )}
      </Stack>
    </Card>
  );
};
