import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { Empty } from '@bufbuild/protobuf';
import {
  loadEmployeeProfileRequested,
  loadEmployeeProfileFailed,
  loadEmployeeProfileSucceeded,
  LoadEmployeeProfileResults,
  updateEEOSucceeded,
  updateEEOFailed,
  updateEEORequested,
  updateEmergencySucceeded,
  updateEmergencyFailed,
  updateEmergencyRequested,
  updatePayrollSucceeded,
  updatePayrollFailed,
  updatePayrollRequested,
  updateEmploymentSucceeded,
  updateEmploymentFailed,
  updateEmploymentRequested,
  updateWorkEligibilitySucceeded,
  updateWorkEligibilityFailed,
  updateWorkEligibilityRequested,
  updateFinancialSucceeded,
  updateFinancialFailed,
  updateFinancialRequested,
  updateHomeSucceeded,
  updateHomeFailed,
  updateHomeRequested,
  updateBasicInfoRequested,
  updateBasicInfoSucceeded,
  updateBasicInfoFailed,
  updatePersonalRequested,
  updatePersonalSucceeded,
  updatePersonalFailed,
  updatePersonalContactDetailsSucceeded,
  updatePersonalContactDetailsFailed,
  updatePersonalContactDetailsRequested,
  updateIdentificationSucceeded,
  updateIdentificationFailed,
  updateIdentificationRequested,
  updateWorkSucceeded,
  updateWorkFailed,
  updateWorkRequested,
  updateWorkContactDetailsRequested,
  updateWorkContactDetailsSucceeded,
  updateWorkContactDetailsFailed,
  updateAddressRequested,
  updateAddressSucceeded,
  updateAddressFailed,
  updateAboutRequested,
  updateAboutSucceeded,
  updateAboutFailed,
  updateProfileMediaSucceeded,
  updateProfileMediaFailed,
  updateProfileMediaRequested,
  retrieveEmployeeProfileAndCoverReadSasTokenSucceeded,
  retrieveEmployeeProfileAndCoverReadSasTokenFailed,
  retrieveEmployeeProfileAndCoverReadSasTokenRequested,
  selectEmployeeProfilePictureFileMultiSize,
  selectEmployeeCoverPictureFileMultiSize
} from '@features/employee-profile';

import { retrieveWorkingPatterns } from '@api/organization.api';
import {
  retrieveCalendarItems,
  retrieveEmployeeItems,
  retrieveEmployeeProfile,
  retrieveEmploymentTypes,
  retrieveEthnicities,
  retrieveGenders,
  retrieveJobCategories,
  retrievePrefixes,
  retrievePronouns,
  retrieveWorkEligibilityTypes,
  updateEEO,
  updateEmergency,
  updateEmployment,
  updateFinancial,
  updateHome,
  updatePayroll,
  updateWorkEligibility,
  updateBasicInfo,
  updatePersonal,
  updatePersonalContactDetails,
  updateIdentification,
  updateWork,
  updateWorkContactDetails,
  updateAddress,
  updateAbout,
  retrieveSites,
  retrieveDepartments,
  retrieveSuperpowers,
  retrieveHobbies,
  retrieveFoodPreferences,
  updateProfileMedia
} from '@api/employee-profile.api';
import {
  UpdateEeoRequest,
  RetrieveEmployeeProfileRequest,
  UpdateEmergencyRequest,
  UpdatePayrollRequest,
  UpdateEmploymentRequest,
  UpdateWorkEligibilityRequest,
  UpdateFinancialRequest,
  UpdateHomeRequest,
  UpdateBasicInfoRequest,
  UpdatePersonalRequest,
  UpdatePersonalContactDetailsRequest,
  UpdateIdentificationRequest,
  UpdateWorkRequest,
  UpdateWorkContactDetailsRequest,
  UpdateAddressRequest,
  UpdateAboutRequest,
  UpdateProfileMediaRequest} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { RetrieveEmployeeProfileAndCoverCreateSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';
import { retrieveEmployeeProfileAndCoverCreateSasUri, retrieveEmployeeProfileAndCoverReadSasToken } from '@api/shared-access-signature.api';
import { UploadFileMultipleSizes, uploadFileMultipleSizes } from '@api/blob-storage.api';
import { updateCurrentUserProfilePicture } from '@features/shared';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { MultiSizeImageBlobs } from '@/shared';
import { setCurrentEmployeeProfileCoverPicture, setCurrentEmployeeProfilePicture } from '@features/admin-settings';

function* loadEmployeeProfileRequestedGenerator(action: PayloadAction<string>) {
  try {
    const results: LoadEmployeeProfileResults = yield all({
      employeeProfile: call(retrieveEmployeeProfile, new RetrieveEmployeeProfileRequest({ employeeId: action.payload })),
      employeeItemsResponse: call(retrieveEmployeeItems, new Empty()),
      pronounsResponse: call(retrievePronouns, new Empty()),
      prefixesResponse: call(retrievePrefixes, new Empty()),
      gendersResponse: call(retrieveGenders, new Empty()),
      workEligibilityTypesResponse: call(retrieveWorkEligibilityTypes, new Empty()),
      ethnicitiesResponse: call(retrieveEthnicities, new Empty()),
      jobCategoriesResponse: call(retrieveJobCategories, new Empty()),
      employmentTypeResponse: call(retrieveEmploymentTypes, new Empty()),
      workingPatternsResponse: call(retrieveWorkingPatterns, new Empty()),
      calendarItemsResponse: call(retrieveCalendarItems, new Empty()),
      organizationSitesResponse: call(retrieveSites, new Empty()),
      organizationDepartmentsResponse: call(retrieveDepartments, new Empty()),
      superPowersResponse: call(retrieveSuperpowers, new Empty()),
      hobbiesResponse: call(retrieveHobbies, new Empty()),
      foodPreferencesResponse: call(retrieveFoodPreferences, new Empty())
    });

    yield put(loadEmployeeProfileSucceeded(results));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadEmployeeProfileFailed());
  }
}

function* updateHomeGenerator(action: PayloadAction<UpdateHomeRequest>) {
  try {
    yield call(updateHome, action.payload);
    yield put(updateHomeSucceeded(action.payload));
  } catch (error) {
    yield put(updateHomeFailed());
  }
}

function* updateFinancialGenerator(action: PayloadAction<UpdateFinancialRequest>) {
  try {
    yield call(updateFinancial, action.payload);
    yield put(updateFinancialSucceeded(action.payload));
  } catch (error) {
    yield put(updateFinancialFailed());
  }
}

function* updateWorkEligibilityGenerator(action: PayloadAction<UpdateWorkEligibilityRequest>) {
  try {
    yield call(updateWorkEligibility, action.payload);
    yield put(updateWorkEligibilitySucceeded(action.payload));
  } catch (error) {
    yield put(updateWorkEligibilityFailed());
  }
}

function* updateEmploymentGenerator(action: PayloadAction<UpdateEmploymentRequest>) {
  try {
    yield call(updateEmployment, action.payload);
    yield put(updateEmploymentSucceeded(action.payload));
  } catch (error) {
    yield put(updateEmploymentFailed());
  }
}

function* updatePayrollGenerator(action: PayloadAction<UpdatePayrollRequest>) {
  try {
    yield call(updatePayroll, action.payload);
    yield put(updatePayrollSucceeded(action.payload));
  } catch (error) {
    yield put(updatePayrollFailed());
  }
}

function* updateEmergencyGenerator(action: PayloadAction<UpdateEmergencyRequest>) {
  try {
    yield call(updateEmergency, action.payload);
    yield put(updateEmergencySucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateEmergencyFailed());
  }
}

function* updateBasicInfoGenerator(action: PayloadAction<UpdateBasicInfoRequest>) {
  try {
    yield call(updateBasicInfo, action.payload);
    yield put(updateBasicInfoSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateBasicInfoFailed(action.payload));
  }
}

function* updatePersonalGenerator(action: PayloadAction<UpdatePersonalRequest>) {
  try {
    yield call(updatePersonal, action.payload);
    yield put(updatePersonalSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updatePersonalFailed(action.payload));
  }
}

function* updatePersonalContactDetailsGenerator(action: PayloadAction<UpdatePersonalContactDetailsRequest>) {
  try {
    yield call(updatePersonalContactDetails, action.payload);
    yield put(updatePersonalContactDetailsSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updatePersonalContactDetailsFailed(action.payload));
  }
}

function* updateIdentificationGenerator(action: PayloadAction<UpdateIdentificationRequest>) {
  try {
    yield call(updateIdentification, action.payload);
    yield put(updateIdentificationSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateIdentificationFailed(action.payload));
  }
}

function* updateWorkGenerator(action: PayloadAction<UpdateWorkRequest>) {
  try {
    yield call(updateWork, action.payload);
    yield put(updateWorkSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateWorkFailed(action.payload));
  }
}

function* updateWorkContactDetailsGenerator(action: PayloadAction<UpdateWorkContactDetailsRequest>) {
  try {
    yield call(updateWorkContactDetails, action.payload);
    yield put(updateWorkContactDetailsSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateWorkContactDetailsFailed(action.payload));
  }
}

function* updateAddressGenerator(action: PayloadAction<UpdateAddressRequest>) {
  try {
    yield call(updateAddress, action.payload);
    yield put(updateAddressSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateAddressFailed(action.payload));
  }
}

function* updateAboutGenerator(action: PayloadAction<UpdateAboutRequest>) {
  try {
    yield call(updateAbout, action.payload);
    yield put(updateAboutSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateAboutFailed(action.payload));
  }
}

function* updateEEOGenerator(action: PayloadAction<UpdateEeoRequest>) {
  try {
    yield call(updateEEO, action.payload);
    yield put(updateEEOSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateEEOFailed());
  }
}

function* retrieveEmployeeProfileAndCoverReadSasTokeGenerator() {
  try {
    const sasToken: SasToken = yield call(retrieveEmployeeProfileAndCoverReadSasToken, new Empty());
    yield put(retrieveEmployeeProfileAndCoverReadSasTokenSucceeded(sasToken));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeProfileAndCoverReadSasTokenFailed());
  }
}

function* updateEmployeeProfileMediaGenerator(action: PayloadAction<UpdateProfileMediaRequest>) {
  const successMessage = t('media_upload_successfully', { ns: 'employee_profile' });
  const profilePictureMultiSize: MultiSizeImageBlobs = yield select(selectEmployeeProfilePictureFileMultiSize);
  const profileCoverPictureMultiSize: MultiSizeImageBlobs = yield select(selectEmployeeCoverPictureFileMultiSize);

  let profilePictureUrl = '';
  let coverPictureUrl = '';

  try {
    const sasUri: SasUri = yield call(
      retrieveEmployeeProfileAndCoverCreateSasUri,
      new RetrieveEmployeeProfileAndCoverCreateSasUriRequest({
        employeeId: action.payload.employeeId
      })
    );

    if (profilePictureMultiSize) {
      const profilePictureRequest = {
        sasUri: sasUri.uri,
        multiSizeImage: profilePictureMultiSize
      } as UploadFileMultipleSizes;
      profilePictureUrl = yield call(uploadFileMultipleSizes, profilePictureRequest);
    }

    if (profileCoverPictureMultiSize) {
      const profileCoverRequest = {
        sasUri: sasUri.uri,
        multiSizeImage: profileCoverPictureMultiSize
      } as UploadFileMultipleSizes;
      coverPictureUrl = yield call(uploadFileMultipleSizes, profileCoverRequest);
    }

    if (!profilePictureMultiSize) {
      profilePictureUrl = action.payload.profilePictureUrl;
    }

    if (!profileCoverPictureMultiSize) {
      coverPictureUrl = action.payload.coverPictureUrl;
    }

    yield call(updateProfileMedia, {
      employeeId: action.payload.employeeId,
      profilePictureUrl,
      coverPictureUrl
    } as UpdateProfileMediaRequest);

    yield put(updateProfileMediaSucceeded(action.payload));
    yield put(updateCurrentUserProfilePicture(profilePictureUrl));
    yield put(setCurrentEmployeeProfilePicture(profilePictureUrl));
    yield put(setCurrentEmployeeProfileCoverPicture(coverPictureUrl));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateProfileMediaFailed());
  }
}

function* loadEmployeeProfileRequestedWatcher() {
  yield takeLatest(loadEmployeeProfileRequested.type, loadEmployeeProfileRequestedGenerator);
}

function* updateHomeRequestedWatcher() {
  yield takeLatest(updateHomeRequested.type, updateHomeGenerator);
}

function* updateFinancialRequestedWatcher() {
  yield takeLatest(updateFinancialRequested.type, updateFinancialGenerator);
}

function* updateWorkEligibilityRequestedWatcher() {
  yield takeLatest(updateWorkEligibilityRequested.type, updateWorkEligibilityGenerator);
}

function* updateEmploymentRequestedWatcher() {
  yield takeLatest(updateEmploymentRequested.type, updateEmploymentGenerator);
}

function* updatePayrollRequestedWatcher() {
  yield takeLatest(updatePayrollRequested.type, updatePayrollGenerator);
}

function* updateEmergencyRequestedWatcher() {
  yield takeLatest(updateEmergencyRequested.type, updateEmergencyGenerator);
}

function* updateEEORequestedWatcher() {
  yield takeLatest(updateEEORequested.type, updateEEOGenerator);
}

function* updateBasicInfoWatcher() {
  yield takeLatest(updateBasicInfoRequested.type, updateBasicInfoGenerator);
}

function* updatePersonalWatcher() {
  yield takeLatest(updatePersonalRequested.type, updatePersonalGenerator);
}

function* updatePersonalContactDetailsWatcher() {
  yield takeLatest(updatePersonalContactDetailsRequested.type, updatePersonalContactDetailsGenerator);
}

function* updateIdentificationWatcher() {
  yield takeLatest(updateIdentificationRequested.type, updateIdentificationGenerator);
}

function* updateWorkWatcher() {
  yield takeLatest(updateWorkRequested.type, updateWorkGenerator);
}

function* updateWorkContactDetailsWatcher() {
  yield takeLatest(updateWorkContactDetailsRequested.type, updateWorkContactDetailsGenerator);
}

function* updateAddressWatcher() {
  yield takeLatest(updateAddressRequested.type, updateAddressGenerator);
}

function* updateAboutWatcher() {
  yield takeLatest(updateAboutRequested.type, updateAboutGenerator);
}

function* updateEmployeeProfileMediaWatcher() {
  yield takeLatest(updateProfileMediaRequested.type, updateEmployeeProfileMediaGenerator);
}

function* retrieveEmployeeProfileAndCoverReadSasTokeWatcher() {
  yield takeLatest(retrieveEmployeeProfileAndCoverReadSasTokenRequested.type, retrieveEmployeeProfileAndCoverReadSasTokeGenerator);
}

export function* employeeProfileSagas() {
  yield all([
    fork(loadEmployeeProfileRequestedWatcher),
    fork(updateEmergencyRequestedWatcher),
    fork(updatePayrollRequestedWatcher),
    fork(updateEmploymentRequestedWatcher),
    fork(updateWorkEligibilityRequestedWatcher),
    fork(updateFinancialRequestedWatcher),
    fork(updateHomeRequestedWatcher),
    fork(updateEEORequestedWatcher),
    fork(updateBasicInfoWatcher),
    fork(updatePersonalWatcher),
    fork(updatePersonalContactDetailsWatcher),
    fork(updateIdentificationWatcher),
    fork(updateWorkWatcher),
    fork(updateWorkContactDetailsWatcher),
    fork(updateAddressWatcher),
    fork(updateAboutWatcher),
    fork(retrieveEmployeeProfileAndCoverReadSasTokeWatcher),
    fork(updateEmployeeProfileMediaWatcher)
  ]);
}
