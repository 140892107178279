import { Tabs, tabsClasses } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { selectActiveTasksViewId, selectVisibleTasksViews, TasksViewsTab } from '@features/tasks';

export const TasksViewsTabs = () => {
  const activeTasksViewId = useAppSelector(selectActiveTasksViewId);
  const visibleTasksViews = useAppSelector(selectVisibleTasksViews);

  const activeTabIndex = visibleTasksViews.findIndex((vtv) => vtv.tasksViewId === activeTasksViewId);

  const validTabIndex = activeTabIndex >= 0 ? activeTabIndex : 0;

  return (
    <Tabs
      value={validTabIndex}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        minHeight: 43,
        borderRadius: 2,
        maxWidth: '90%',
        justifyContent: 'left',
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      {visibleTasksViews.map((vtv) => (
        <TasksViewsTab key={vtv.tasksViewId} view={vtv} />
      ))}
    </Tabs>
  );
};
