import React, { useState, useCallback, useEffect } from 'react';
import {
  FormControlLabel,
  Box,
  TextField,
  InputAdornment,
  Stack,
  Typography,
  Collapse,
  List,
  typographyClasses,
  ClickAwayListener,
  InputLabel,
  ListItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  ChevronDownIcon,
  CustomCheckBox,
  HighlightText,
  RowCenterStack,
  StyledFixedWidthTypography,
  StyledCheckbox,
  StyledTransparentButton,
  Option
} from '@/shared';
import { useTranslation } from 'react-i18next';

// The hook for filtering dropdown Options
const useFilteredFields = (options: Option[], value: string) => {
  const [fields, setFields] = useState<Option[]>([]);

  const filterFields = useCallback(() => options.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())), [options, value]);

  useEffect(() => {
    setFields(filterFields());
  }, [filterFields]);

  return fields;
};

interface PeopleDirectoryCustomDropdownProps {
  options: Option[];
  values: Option[];
  label: string;
  partiallySelected?: { optionId: string; isPartiallySelected: boolean }[];
  onOptionSelect: (
    selectedOption: {
      id: string;
      name: string;
    },
    isEnabled: boolean
  ) => void;
}

export const PeopleDirectoryCustomDropdown: React.FC<PeopleDirectoryCustomDropdownProps> = ({ options, values, label, partiallySelected, onOptionSelect }) => {
  const { t } = useTranslation(['common']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState('');

  const filteredFields = useFilteredFields(options, searchText);

  const handleToggleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpened = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        sx={{
          width: 226,
          position: 'relative',
          borderRadius: 1,
          border: isOpened ? '1px solid' : 0,
          borderBottom: 0,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => (isOpened ? theme.palette.common.white : theme.palette.customTheme.primaryA)
        }}
      >
        <Stack
          onClick={handleToggleClick}
          sx={{
            pl: 1,
            pr: 2,
            py: 1,
            height: 50,
            gap: 0,
            borderRadius: 1
          }}
        >
          <InputLabel
            shrink
            size="small"
            disabled
            sx={{
              fontSize: 14
            }}
          >
            {label}
          </InputLabel>
          <RowCenterStack
            sx={{
              justifyContent: 'space-between'
            }}
          >
            {values.length === 0 && <StyledFixedWidthTypography sx={{ maxWidth: 100 }}>{t('none', { ns: 'common' })}</StyledFixedWidthTypography>}
            {values.length === 1 && <StyledFixedWidthTypography sx={{ maxWidth: 100 }}>{values[0].name}</StyledFixedWidthTypography>}
            {values.length > 1 && values.length < options.length && (
              <RowCenterStack>
                <StyledFixedWidthTypography sx={{ maxWidth: 100 }}>{values[0].name}</StyledFixedWidthTypography>
                <Typography>
                  &nbsp;+ {values.length - 1} {t('more', { ns: 'common' })}
                </Typography>
              </RowCenterStack>
            )}
            {values.length > 0 && values.length === options.length && (
              <StyledFixedWidthTypography sx={{ maxWidth: 100 }}>{t('all', { ns: 'common' })}</StyledFixedWidthTypography>
            )}
            <StyledTransparentButton
              sx={{
                transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            >
              <ChevronDownIcon />
            </StyledTransparentButton>
          </RowCenterStack>
        </Stack>
        <Collapse
          in={isOpened}
          sx={{
            position: 'absolute',
            top: 45,
            left: -1,
            right: -1,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            border: isOpened ? '1px solid' : 0,
            borderTop: 0,
            borderColor: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.customTheme.primaryA,
            zIndex: (theme) => theme.zIndex.fab
          }}
        >
          <Stack
            gap={1}
            sx={{
              padding: '8px',
              backgroundColor: (theme) => theme.palette.common.white
            }}
          >
            <List
              role="listbox"
              sx={{
                maxHeight: 270,
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                gap: 0
              }}
            >
              {filteredFields.length === 0 ? (
                <ListItem>
                  <Typography>{t('no_options_found', { ns: 'common' })}</Typography>
                </ListItem>
              ) : (
                filteredFields.map((field) => (
                  <ListItem key={field.id} aria-selected={values.includes(field)} sx={{ height: 30, padding: '7px 0' }}>
                    {partiallySelected ? (
                      <FormControlLabel
                        key={field.id}
                        control={
                          <StyledCheckbox
                            indeterminate={partiallySelected.find((ps) => ps.optionId === field.id)!.isPartiallySelected}
                            icon={<CustomCheckBox size={18} />}
                            checked={values.includes(field)}
                            onChange={(event) => onOptionSelect(field, event.target.checked)}
                          />
                        }
                        label={
                          <StyledFixedWidthTypography sx={{ maxWidth: 150 }}>
                            <HighlightText text={field.name} highlight={searchText} />
                          </StyledFixedWidthTypography>
                        }
                        sx={{
                          width: '100%',
                          gap: 1,
                          alignItems: 'center',
                          marginLeft: 0,
                          [`& .${typographyClasses.root}`]: {
                            width: '100%'
                          }
                        }}
                      />
                    ) : (
                      <FormControlLabel
                        key={field.id}
                        control={
                          <StyledCheckbox
                            icon={<CustomCheckBox size={18} />}
                            checked={values.includes(field)}
                            onChange={(event) => onOptionSelect(field, event.target.checked)}
                          />
                        }
                        label={
                          <StyledFixedWidthTypography sx={{ maxWidth: 150 }}>
                            <HighlightText text={field.name} highlight={searchText} />
                          </StyledFixedWidthTypography>
                        }
                        sx={{
                          width: '100%',
                          gap: 1,
                          alignItems: 'center',
                          marginLeft: 0,
                          [`& .${typographyClasses.root}`]: {
                            width: '100%'
                          }
                        }}
                      />
                    )}
                  </ListItem>
                ))
              )}
            </List>
            <TextField
              placeholder={t('search', { ns: 'common' })}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: '1.5rem' }} />
                    </InputAdornment>
                  )
                }
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
                borderRadius: 4,
                '& .MuiInputBase-root': {
                  paddingLeft: 0
                },
                '& input': {
                  padding: 1
                },
                '& fieldset': {
                  border: '0 !important'
                }
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Stack>
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
};
