import { Chip, chipClasses, ChipProps, styled } from '@mui/material';
import { InvoiceStatus } from '@thrivea/payment-client';

interface BillingStatusChipStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface BillingStatusChipProps extends ChipProps {
  status: InvoiceStatus;
}

export const BillingStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isAssigned' && prop !== 'isInReview' && prop !== 'isCompleted'
})<BillingStatusChipProps>(({ status, theme }) => {
  const row: Record<InvoiceStatus, BillingStatusChipStyleProps> = {
    [InvoiceStatus.DRAFT]: { backgroundColor: '#BEBABA', textColor: theme.palette.primary.main },
    [InvoiceStatus.OPEN]: { backgroundColor: '#87D3F8', textColor: theme.palette.primary.main },
    [InvoiceStatus.VOID]: { backgroundColor: '#DC3545', textColor: theme.palette.common.white },
    [InvoiceStatus.PAID]: { backgroundColor: '#2FBCC4', textColor: theme.palette.common.white },
    [InvoiceStatus.INVOICE_STATUS_UNSPECIFIED]: { backgroundColor: '#FFFFFF', textColor: theme.palette.text.primary },
    [InvoiceStatus.UNCOLLECTIBLE]: { backgroundColor: '#FF0000', textColor: theme.palette.common.white }
  };

  return {
    backgroundColor: row[status].backgroundColor,
    color: row[status].textColor,
    maxWidth: 50,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
