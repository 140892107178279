import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { Empty } from '@bufbuild/protobuf';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SasToken, SasUri } from '@thrivea/auth-client';
import {
  CopyFilesRequest,
  CreateFolderRequest,
  DeletedFileItem,
  EditDescriptionRequest,
  EmployeeAccess,
  FileAccessRole,
  FileActivityItem,
  FileInfo,
  FileItem,
  FileType,
  FolderInfo,
  HardDeleteFilesRequest,
  MoveFilesRequest,
  MultipleFilesInfo,
  PinFolderRequest,
  RenameFileRequest,
  RestoreFilesRequest,
  RetrieveFileActivitiesRequest,
  RetrieveFileActivitiesResponse,
  RetrieveFilesAccessRequest,
  RetrieveFilesAccessResponse,
  RetrieveFilesInfoRequest,
  RetrieveFilesInfoResponse,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveRecentFilesRequest,
  RetrieveSharedFilesRequest,
  RetrieveStarredFilesRequest,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  RevokeAccessForAllRequest,
  ShareEmployee,
  ShareFileRequest,
  SoftDeleteFilesRequest,
  StarFileRequest,
  UploadFilesRequest
} from '@thrivea/organization-client';
import { FileWithStatus, UploadedFile, UploadProgress } from '@api/blob-storage.api';
import { RootState } from '@app/store';
import { ActionStatus, Sorting } from '@/shared';
import { trimQueryParams } from '@/utils';
import { chain, uniq } from 'lodash';
import { mapMimeTypeToFileType, ExtendedTreeItem, FileSystemItem, UploadFilesPayload, toSortFile } from '@features/assets';
import { t } from 'i18next';

export const TRASH_BIN_FILES_ID = '8bec9c1c-547a-4da4-a432-fe3e6c1aced9';
export const SHARED_WITH_ME_FILES_ID = '5b52b980-4c9c-47fe-87cb-81a7ea5c3229';
export const MY_UPLOADS_FILES_FOLDER_ID = '10dd551b-66b3-44ec-9c3f-b5004a37326e';
export const MY_FILES_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f911';
export const NEWS_FEED_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f912';
export const TASKS_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f913';
export const EMPLOYEE_RECORD_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f914';
export const TIME_OFF_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f915';

export interface AssetsState {
  entities: {
    files: {
      byId: { [key: string]: FileItem };
      allIds: string[];
      recentFileIds: string[];
      starredFileIds: string[];
      newFolderIds: string[];
    };
    fileSystem: {
      byId: { [key: string]: FileSystemItem };
      allIds: string[];
    };
    trashBinFiles: {
      byId: { [key: string]: DeletedFileItem };
      allIds: string[];
      removedIds: string[];
    };
    accessItems: {
      byInitialId: { [key: string]: EmployeeAccess };
      byUpdatedId: { [key: string]: EmployeeAccess };
      initialIds: string[];
      updatedIds: string[];
      addedIds: string[];
      removedIds: string[];
    };
    sharedFiles: {
      byId: { [key: string]: FileItem };
      allIds: string[];
    };
    myUploads: {
      byId: { [key: string]: FileItem };
      allIds: string[];
    };
    moveFolderItems: {
      byId: { [key: string]: FileItem };
      allIds: string[];
    };
    myUploadsRootFolders: FileItem[];
  };
  ui: {
    myFilesSorting: Sorting;
    filesWithStatus: FileWithStatus[];
    fileUploadProgress: UploadProgress;
    retrieveFilesStatus: ActionStatus;
    retrieveRecentFilesStatus: ActionStatus;
    retrieveStarredFilesStatus: ActionStatus;
    retrieveSharedFilesStatus: ActionStatus;
    uploadFilesStatus: ActionStatus;
    filesTotalCount: number;
    filesPageNumber: number;
    filesPageSize: number;
    retrieveTrashFilesStatus: ActionStatus;
    trashBinFilesTotalCount: number;
    trashBinFilesPageNumber: number;
    trashBinFilesPageSize: number;
    recentFilesTotalCount: number;
    recentFilesPageNumber: number;
    recentFilesPageSize: number;
    starredFilesTotalCount: number;
    starredFilesPageNumber: number;
    starredFilesPageSize: number;
    sharedFilesTotalCount: number;
    sharedFilesPageNumber: number;
    sharedFilesPageSize: number;
    isDragging: boolean;
    retrieveFilesAccessStatus: ActionStatus;
    filesUploading: boolean;
    createNewFolderStatus: ActionStatus;
    activeFilter: 'recents' | 'starred' | 'none';
    isMoveModalOpen: boolean;
    isViewModalOpen: boolean;
    drawer: {
      fileItem: FileInfo;
      folderItem: FolderInfo;
      multipleFilesItem: MultipleFilesInfo;
    };
    activityItemsStatus: ActionStatus;
    retrieveFilesInfoStatus: ActionStatus;
  };
  // activeFile: FileItem;
  myUploadsReadSasToken: string;
  myUploadsCreateDeleteSasUri: string;
  selectedFolderId: string;
  expandedFolderIds: string[];
  draggingState: 'Internal' | 'External';
  selectedFileIds: string[]; // used to open drawer
  activityItems: FileActivityItem[];
}

const initialState: AssetsState = {
  entities: {
    files: {
      byId: {},
      allIds: [],
      recentFileIds: [],
      starredFileIds: [],
      newFolderIds: []
    },
    fileSystem: {
      byId: {},
      allIds: []
    },
    trashBinFiles: {
      byId: {},
      allIds: [],
      removedIds: []
    },
    accessItems: {
      byInitialId: {},
      byUpdatedId: {},
      initialIds: [],
      updatedIds: [],
      addedIds: [],
      removedIds: []
    },
    sharedFiles: {
      byId: {},
      allIds: []
    },
    myUploads: {
      byId: {},
      allIds: []
    },
    moveFolderItems: {
      byId: {},
      allIds: []
    },
    myUploadsRootFolders: [
      new FileItem({ id: NEWS_FEED_FOLDER_ID, name: t('feed', { ns: 'assets' }), type: FileType.FOLDER }),
      new FileItem({ id: TASKS_FOLDER_ID, name: t('tasks', { ns: 'assets' }), type: FileType.FOLDER }),
      new FileItem({ id: EMPLOYEE_RECORD_FOLDER_ID, name: t('employee_record', { ns: 'assets' }), type: FileType.FOLDER })
    ]
  },
  ui: {
    myFilesSorting: {
      sortBy: 'name',
      sortDirection: 'asc'
    },
    filesWithStatus: [],
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    },
    retrieveFilesStatus: ActionStatus.Idle,
    retrieveRecentFilesStatus: ActionStatus.Idle,
    retrieveStarredFilesStatus: ActionStatus.Idle,
    retrieveSharedFilesStatus: ActionStatus.Idle,
    uploadFilesStatus: ActionStatus.Idle,
    filesTotalCount: 0,
    filesPageNumber: 1,
    filesPageSize: 10,
    retrieveTrashFilesStatus: ActionStatus.Idle,
    trashBinFilesTotalCount: 0,
    trashBinFilesPageNumber: 1,
    trashBinFilesPageSize: 10,
    recentFilesTotalCount: 0,
    recentFilesPageNumber: 1,
    recentFilesPageSize: 10,
    starredFilesTotalCount: 0,
    starredFilesPageNumber: 1,
    starredFilesPageSize: 10,
    sharedFilesTotalCount: 0,
    sharedFilesPageNumber: 1,
    sharedFilesPageSize: 10,
    isDragging: false,
    retrieveFilesAccessStatus: ActionStatus.Idle,
    filesUploading: false,
    createNewFolderStatus: ActionStatus.Idle,
    activeFilter: 'none',
    isMoveModalOpen: false,
    isViewModalOpen: false,
    drawer: {
      fileItem: new FileInfo({}),
      folderItem: new FolderInfo({}),
      multipleFilesItem: new MultipleFilesInfo({})
    },
    activityItemsStatus: ActionStatus.Idle,
    retrieveFilesInfoStatus: ActionStatus.Idle
  },
  myUploadsReadSasToken: '',
  myUploadsCreateDeleteSasUri: '',
  selectedFolderId: MY_FILES_FOLDER_ID,
  expandedFolderIds: [MY_FILES_FOLDER_ID],
  draggingState: 'External',
  selectedFileIds: [],
  activityItems: []
};

const makeFileSystemId = (parentFileId: string, childFileId: string) => parentFileId + '|' + childFileId;

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    selectMultipleFiles: (state, action: PayloadAction<string[]>) => {
      state.selectedFileIds = action.payload;
    },
    retrieveFilesRequested: (state, action: PayloadAction<RetrieveFilesRequest>) => {
      const { pageNumber, pageSize, folderId } = action.payload;

      state.ui.retrieveFilesStatus = ActionStatus.Pending;
      state.ui.filesPageNumber = pageNumber;
      state.ui.filesPageSize = pageSize;

      state.selectedFolderId = folderId;
    },
    retrieveFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.files.byId[file.id] = file;
        state.entities.files.allIds = uniq(state.entities.files.allIds.concat(file.id));

        const fileSystemId = makeFileSystemId(state.selectedFolderId, file.id);

        if (!state.entities.fileSystem.hasOwnProperty(fileSystemId)) {
          state.entities.fileSystem.byId[fileSystemId] = {
            parentFileId: state.selectedFolderId,
            childFileId: file.id,
            type: file.type
          };

          state.entities.fileSystem.allIds.push(fileSystemId);
        }
      }

      state.ui.retrieveFilesStatus = ActionStatus.Idle;
      state.ui.filesTotalCount = totalCount;
    },
    retrieveFilesFailed: (state) => {
      state.ui.retrieveFilesStatus = ActionStatus.Failed;
    },
    retrieveRecentFilesRequested: (state, action: PayloadAction<RetrieveRecentFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.recentFilesPageNumber = pageNumber;
      state.ui.recentFilesPageSize = pageSize;
      state.ui.retrieveRecentFilesStatus = ActionStatus.Pending;

      state.entities.files.recentFileIds = [];
    },
    retrieveRecentFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.files.byId[file.id] = file;
        state.entities.files.recentFileIds = uniq(state.entities.files.recentFileIds.concat(file.id));
      }

      state.ui.recentFilesTotalCount = totalCount;
      state.ui.retrieveRecentFilesStatus = ActionStatus.Idle;
    },
    retrieveRecentFilesFailed: (state) => {
      state.ui.retrieveRecentFilesStatus = ActionStatus.Failed;
    },
    retrieveStarredFilesRequested: (state, action: PayloadAction<RetrieveStarredFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.starredFilesPageNumber = pageNumber;
      state.ui.starredFilesPageSize = pageSize;
      state.ui.retrieveStarredFilesStatus = ActionStatus.Pending;
    },
    retrieveStarredFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.files.byId[file.id] = file;
        state.entities.files.starredFileIds = uniq(state.entities.files.starredFileIds.concat(file.id));
      }

      state.ui.starredFilesTotalCount = totalCount;
      state.ui.retrieveStarredFilesStatus = ActionStatus.Idle;
    },
    retrieveStarredFilesFailed: (state) => {
      state.ui.retrieveStarredFilesStatus = ActionStatus.Failed;
    },
    uploadFilesRequested: (state, action: PayloadAction<UploadFilesPayload>) => {
      const { files } = action.payload;

      // TODO: remove parentFolderId, rely on selectedFolderId
      state.ui.filesWithStatus = files;

      state.ui.uploadFilesStatus = ActionStatus.Pending;
    },
    notifyBackendOnUpload: (state, action: PayloadAction<UploadFilesRequest>) => {},
    uploadFilesSucceeded: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Idle;
    },
    uploadFilesFailed: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Failed;
    },
    uploadFile: (state, action: PayloadAction<FileWithStatus>) => {
      const fileWithStatus = state.ui.filesWithStatus.find((fws) => fws.id === action.payload.id)!;
      fileWithStatus.isUploading = true;
      fileWithStatus.isUploaded = false;
    },
    updateFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      const { fileProgress, totalProgress } = action.payload;

      state.ui.fileUploadProgress = {
        fileProgress,
        totalProgress
      };
    },
    fileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      const fileWithStatus = state.ui.filesWithStatus.find((fws) => fws.id === action.payload.fileWithStatus.id)!;
      fileWithStatus.isUploading = false;
      fileWithStatus.isUploaded = true;
      fileWithStatus.url = trimQueryParams(action.payload.url);

      state.entities.files.byId[fileWithStatus.id] = new FileItem({
        id: fileWithStatus.id,
        name: fileWithStatus.name,
        sizeInBytes: fileWithStatus.file.size,
        type: mapMimeTypeToFileType(fileWithStatus.file.type),
        url: fileWithStatus.url
      });
      state.entities.files.allIds.push(fileWithStatus.id);

      const fileSystemId = makeFileSystemId(state.selectedFolderId, fileWithStatus.id);

      state.entities.fileSystem.byId[fileSystemId] = {
        parentFileId: state.selectedFolderId,
        childFileId: fileWithStatus.id,
        type: state.entities.files.byId[fileWithStatus.id].type
      } as FileSystemItem;
      state.entities.fileSystem.allIds.push(fileSystemId);

      state.ui.filesTotalCount += 1;

      state.ui.fileUploadProgress = {
        fileProgress: 0,
        totalProgress: 0
      };
    },
    toggleRecentFiles: (state) => {
      state.ui.activeFilter = state.ui.activeFilter === 'recents' ? 'none' : 'recents';
    },
    toggleStarredFiles: (state) => {
      state.ui.activeFilter = state.ui.activeFilter === 'starred' ? 'none' : 'starred';
    },
    createNewFolder: (state, action: PayloadAction<CreateFolderRequest>) => {
      const { id, name } = action.payload;

      state.entities.files.byId[id] = new FileItem({
        id,
        description: '',
        isStarred: false,
        name,
        sizeInBytes: 0,
        type: FileType.FOLDER,
        url: ''
      });
      state.entities.files.newFolderIds.push(id);

      const fileSystemId = makeFileSystemId(state.selectedFolderId, id);

      state.entities.fileSystem.byId[fileSystemId] = {
        parentFileId: state.selectedFolderId,
        childFileId: id,
        type: FileType.FOLDER
      } as FileSystemItem;
      state.entities.fileSystem.allIds.push(fileSystemId);

      state.ui.filesTotalCount += 1;
    },
    removeNewFolder: (state, action: PayloadAction<string>) => {
      const newFolderId = action.payload;

      delete state.entities.files.byId[newFolderId];
      state.entities.files.newFolderIds = state.entities.files.newFolderIds.filter((id) => id !== newFolderId);

      const keyEnding = `|${newFolderId}`;
      const fileSystemId = chain(state.entities.fileSystem.byId)
        .keys()
        .find((key) => key.endsWith(keyEnding))
        .value();

      delete state.entities.fileSystem.byId[fileSystemId];
      state.entities.fileSystem.allIds = state.entities.fileSystem.allIds.filter((id) => id !== fileSystemId);

      state.ui.filesTotalCount -= 1;
    },
    createFolderRequested: (state, action: PayloadAction<CreateFolderRequest>) => {
      const { id, name } = action.payload;

      state.entities.files.byId[id] = { ...state.entities.files.byId[id], name };

      state.ui.createNewFolderStatus = ActionStatus.Pending;
    },
    createFolderSucceeded: (state, action: PayloadAction<string>) => {
      // API responded with Empty, so we reuse request object
      const newFolderId = action.payload;

      state.entities.files.allIds = uniq(state.entities.files.allIds.concat(newFolderId));
      state.entities.files.newFolderIds = state.entities.files.newFolderIds.filter((id) => id !== newFolderId);

      state.ui.createNewFolderStatus = ActionStatus.Idle;
    },
    createFolderFailed: (state) => {
      state.ui.createNewFolderStatus = ActionStatus.Failed;
    },
    retrieveMyUploadsReadSasTokenRequested: (state, action: PayloadAction<Empty>) => {},
    retrieveMyUploadsReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.myUploadsReadSasToken = action.payload.token;
    },
    retrieveMyUploadsReadSasTokenFailed: (state) => {},
    retrieveMyUploadsCreateDeleteSasUriRequested: (state, action: PayloadAction<Empty>) => {},
    retrieveMyUploadsCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.myUploadsCreateDeleteSasUri = action.payload.uri;
    },
    retrieveMyUploadsCreateDeleteSasUriFailed: (state) => {},
    refreshMyUploadsCreateDeleteSasUriRequested: (state, action: PayloadAction<Empty>) => {},
    refreshMyUploadsCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.myUploadsCreateDeleteSasUri = action.payload.uri;
    },
    refreshMyUploadsCreateDeleteSasUriFailed: (state) => {},
    renameFileRequested: (state, action: PayloadAction<RenameFileRequest>) => {
      // TODO: add status for spinner
    },
    renameFileSucceeded: (state, action: PayloadAction<RenameFileRequest>) => {
      const { fileId, newName } = action.payload;

      state.entities.files.byId[fileId] = { ...state.entities.files.byId[fileId], name: newName };
    },
    renameFileFailed: (state) => {},
    editDescriptionRequested: (state, action: PayloadAction<EditDescriptionRequest>) => {},
    editDescriptionSucceeded: (state) => {},
    editDescriptionFailed: (state) => {},
    softDeleteFilesRequested: (state, action: PayloadAction<SoftDeleteFilesRequest>) => {},
    softDeleteFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      const deletedFileIds = action.payload;

      for (const deletedFileId of deletedFileIds) {
        delete state.entities.files.byId[deletedFileId];
        state.entities.files.allIds = state.entities.files.allIds.filter((id) => id !== deletedFileId);

        const keyEnding = `|${deletedFileId}`;
        const fileSystemId = chain(state.entities.fileSystem.byId)
          .keys()
          .find((key) => key.endsWith(keyEnding))
          .value();

        delete state.entities.fileSystem.byId[fileSystemId];
        state.entities.fileSystem.allIds = state.entities.fileSystem.allIds.filter((id) => id !== fileSystemId);

        // TODO: check this
        state.selectedFileIds = [];
        state.ui.filesTotalCount -= 1;
      }
    },
    softDeleteFilesFailed: (state) => {},
    retrieveFilesInfoRequested: (state, action: PayloadAction<RetrieveFilesInfoRequest>) => {
      const { selectedFileIds } = action.payload;

      state.selectedFileIds = selectedFileIds;
      state.ui.drawer.fileItem = new FileInfo({});
      state.ui.drawer.folderItem = new FolderInfo({});
      state.ui.drawer.multipleFilesItem = new MultipleFilesInfo({});
      state.ui.retrieveFilesInfoStatus = ActionStatus.Pending;
    },
    retrieveFilesInfoSucceeded: (state, action: PayloadAction<RetrieveFilesInfoResponse>) => {
      const { kind } = action.payload;

      switch (kind.case) {
        case 'fileInfo': {
          state.ui.drawer.fileItem = kind.value;
          break;
        }
        case 'folderInfo': {
          state.ui.drawer.folderItem = kind.value;
          break;
        }
        case 'multipleFilesInfo': {
          state.ui.drawer.multipleFilesItem = kind.value;
          break;
        }
        default: {
          state.ui.drawer.fileItem = new FileInfo({});
          state.ui.drawer.folderItem = new FolderInfo({});
          state.ui.drawer.multipleFilesItem = new MultipleFilesInfo({});
        }
      }
      state.ui.retrieveFilesInfoStatus = ActionStatus.Idle;
    },
    retrieveFilesInfoFailed: (state) => {
      state.ui.retrieveFilesInfoStatus = ActionStatus.Failed;
    },
    retrieveTrashBinFilesRequested: (state, action: PayloadAction<RetrieveTrashBinFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.trashBinFilesPageNumber = pageNumber;
      state.ui.trashBinFilesPageSize = pageSize;
      state.ui.retrieveTrashFilesStatus = ActionStatus.Pending;
    },
    retrieveTrashBinFilesSucceeded: (state, action: PayloadAction<RetrieveTrashBinFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.trashBinFiles.byId[file.id] = file;
        state.entities.trashBinFiles.allIds = uniq(state.entities.trashBinFiles.allIds.concat(file.id));
      }

      state.ui.trashBinFilesTotalCount = totalCount;
      state.ui.retrieveTrashFilesStatus = ActionStatus.Idle;
    },
    retrieveTrashBinFilesFailed: (state) => {
      state.ui.retrieveTrashFilesStatus = ActionStatus.Failed;
    },
    clearRecentFiles: (state) => {
      state.entities.files.recentFileIds = [];
    },
    retrieveFilesAccessRequested: (state, action: PayloadAction<RetrieveFilesAccessRequest>) => {
      state.ui.retrieveFilesAccessStatus = ActionStatus.Pending;

      state.entities.accessItems.byInitialId = {};
      state.entities.accessItems.byUpdatedId = {};
      state.entities.accessItems.initialIds = [];
      state.entities.accessItems.updatedIds = [];
      state.entities.accessItems.addedIds = [];
      state.entities.accessItems.removedIds = [];
    },
    retrieveFilesAccessSucceeded: (state, action: PayloadAction<RetrieveFilesAccessResponse>) => {
      const { accessItems } = action.payload;

      for (const item of accessItems) {
        if (!state.entities.accessItems.byInitialId.hasOwnProperty(item.id)) {
          state.entities.accessItems.byInitialId[item.id] = item;
          state.entities.accessItems.byUpdatedId[item.id] = item;
          state.entities.accessItems.initialIds.push(item.id);
        }
      }

      state.ui.retrieveFilesAccessStatus = ActionStatus.Idle;
    },
    retrieveFilesAccessFailed: (state) => {
      state.ui.retrieveFilesAccessStatus = ActionStatus.Failed;
    },
    copyFilesRequested: (state, action: PayloadAction<CopyFilesRequest>) => {},
    copyFilesSucceeded: (state) => {},
    copyFilesFailed: (state) => {},
    selectFolder: (state, action: PayloadAction<string>) => {
      const currentFolderId = action.payload;

      state.selectedFolderId = currentFolderId;

      let tempParentIds: string[] = [currentFolderId];
      const parentIds = getAllParentFileIds(state.entities.fileSystem.byId, currentFolderId, tempParentIds);

      state.expandedFolderIds = uniq(state.expandedFolderIds.concat(parentIds));
    },
    expandFolders: (state, action: PayloadAction<string[]>) => {
      state.expandedFolderIds = action.payload;
    },
    starFileRequested: (state, action: PayloadAction<StarFileRequest>) => {},
    starFileSucceeded: (state, action: PayloadAction<string>) => {
      const starredFileId = action.payload;

      state.entities.files.byId[starredFileId] = new FileItem({
        ...state.entities.files.byId[starredFileId],
        isStarred: !state.entities.files.byId[starredFileId].isStarred
      });

      if (state.entities.files.byId[starredFileId].isStarred)
        state.entities.files.starredFileIds = uniq(state.entities.files.starredFileIds.concat(starredFileId));
      else state.entities.files.starredFileIds = state.entities.files.starredFileIds.filter((id) => id !== starredFileId);
    },
    starFileFailed: (state) => {},
    moveFilesRequested: (state, action: PayloadAction<MoveFilesRequest>) => {
      state.entities.files.allIds = [];
    },
    moveFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      const fileIds = action.payload;

      for (const id of fileIds) {
        state.entities.files.allIds.push(id);
      }

      state.selectedFileIds = [];
    },
    moveFilesFailed: (state) => {},
    setInnerDragging: (state, action: PayloadAction<'Internal' | 'External'>) => {
      state.draggingState = action.payload;
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.ui.isDragging = action.payload;
    },
    shareFileRequested: (state, action: PayloadAction<ShareFileRequest>) => {},
    shareFilesSucceeded: (state, action: PayloadAction<ShareEmployee[]>) => {
      const shareEmployees = action.payload;

      for (const employee of shareEmployees) {
        state.entities.accessItems.byInitialId[employee.employeeId] = {
          ...state.entities.accessItems.byInitialId[employee.employeeId],
          accessRole: employee.accessRole
        };
      }
    },
    shareFilesFailed: (state) => {},
    hardDeleteFilesRequested: (state, action: PayloadAction<HardDeleteFilesRequest>) => {},
    hardDeleteFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      for (const id of action.payload) {
        state.entities.trashBinFiles.removedIds.push(id);
      }

      state.ui.trashBinFilesTotalCount -= action.payload.length;
    },
    hardDeleteFilesFailed: (state) => {},
    restoreFilesRequested: (state, action: PayloadAction<RestoreFilesRequest>) => {},
    restoreFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      for (const id of action.payload) {
        const restoredFile = state.entities.trashBinFiles.byId[id];

        state.entities.trashBinFiles.removedIds.push(id);

        state.entities.files.byId[id] = new FileItem({
          id: restoredFile.name,
          description: restoredFile.description,
          isStarred: false,
          sizeInBytes: restoredFile.sizeInBytes,
          type: restoredFile.type,
          url: restoredFile.url
        });
        state.entities.files.allIds.push(id);
      }

      state.ui.trashBinFilesTotalCount -= action.payload.length;
    },
    restoreFilesFailed: (state) => {},
    pinFolderRequested: (state, action: PayloadAction<PinFolderRequest>) => {},
    pinFolderSucceeded: (state, action: PayloadAction<string>) => {
      // const folder = state.entities.topLevelFolders.byId[action.payload];
      // state.entities.topLevelFolders.byId[action.payload] = {
      //   ...folder,
      //   isPinned: !folder.isPinned
      // };
    },
    pinFolderFailed: (state) => {},
    revokeAccessForAllRequested: (state, action: PayloadAction<RevokeAccessForAllRequest>) => {},
    revokeAccessForAllSucceeded: (state) => {},
    revokeAccessForAllFailed: (state) => {},
    addEmployeeToShareList: (state, action: PayloadAction<EmployeeAccess[]>) => {
      const shareEmployees = action.payload;

      for (const employee of shareEmployees) {
        state.entities.accessItems.byUpdatedId[employee.id] = employee;
        state.entities.accessItems.updatedIds.push(employee.id);
        state.entities.accessItems.addedIds.push(employee.id);
      }
    },
    removeEmployeeFromShareList: (state, action: PayloadAction<string>) => {
      state.entities.accessItems.removedIds = uniq([...state.entities.accessItems.removedIds, action.payload]);
    },
    updateEmployeeAccessRole: (state, action: PayloadAction<{ employeeId: string; role: FileAccessRole }>) => {
      const employeeId = action.payload.employeeId;
      const role = action.payload.role;

      state.entities.accessItems.byUpdatedId[employeeId] = {
        ...state.entities.accessItems.byUpdatedId[employeeId],
        accessRole: role
      };
      if (!state.entities.accessItems.updatedIds.includes(employeeId)) {
        state.entities.accessItems.updatedIds.push(employeeId);
      }
    },
    retrieveSharedFilesRequested: (state, action: PayloadAction<RetrieveSharedFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.selectedFileIds = [];
      state.ui.retrieveSharedFilesStatus = ActionStatus.Pending;
      state.ui.sharedFilesPageNumber = pageNumber;
      state.ui.sharedFilesPageSize = pageSize;
      // @jovan missing pageNumber and pageSize, sort.
    },
    retrieveSharedFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.sharedFiles.byId[file.id] = file;
        state.entities.sharedFiles.allIds = uniq(state.entities.sharedFiles.allIds.concat(file.id));
      }

      state.ui.retrieveSharedFilesStatus = ActionStatus.Idle; // @jovan can I use the same state?
      state.ui.sharedFilesTotalCount = totalCount; // @jovan can I use the same state?
    },
    retrieveSharedFilesFailed: (state) => {
      state.ui.retrieveSharedFilesStatus = ActionStatus.Failed;
    },
    retrieveMyUploadsRequested: (state, action: PayloadAction<RetrieveFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.retrieveFilesStatus = ActionStatus.Pending;
    },
    retrieveMyUploadsSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.myUploads.byId[file.id] = file;
        state.entities.myUploads.allIds = uniq(state.entities.myUploads.allIds.concat(file.id));
      }

      state.ui.retrieveFilesStatus = ActionStatus.Idle;
      state.ui.filesTotalCount = totalCount;
    },
    retrieveMyUploadsFailed: (state) => {
      state.ui.retrieveFilesStatus = ActionStatus.Failed;
    },
    openMoveModal: (state) => {
      state.ui.isMoveModalOpen = true;
    },
    closeMoveModal: (state) => {
      state.ui.isMoveModalOpen = false;
    },
    openViewModal: (state) => {
      state.ui.isViewModalOpen = true;
    },
    closeViewModal: (state) => {
      state.ui.isViewModalOpen = false;
    },
    sortFilesBy: (state, action: PayloadAction<Sorting>) => {
      const { sortBy, sortDirection } = action.payload;

      state.ui.myFilesSorting.sortBy = sortBy;
      state.ui.myFilesSorting.sortDirection = sortDirection;
    },
    retrieveFileActivitiesRequested: (state, action: PayloadAction<RetrieveFileActivitiesRequest>) => {
      state.activityItems = [];
      state.ui.activityItemsStatus = ActionStatus.Pending;
    },
    retrieveFileActivitiesSucceeded: (state, action: PayloadAction<RetrieveFileActivitiesResponse>) => {
      const { activityItems } = action.payload;

      state.activityItems = activityItems;
      state.ui.activityItemsStatus = ActionStatus.Idle;
    },
    retrieveFileActivitiesFailed: (state) => {
      state.ui.activityItemsStatus = ActionStatus.Failed;
    }
  }
});

export const getAllParentFileIds = (fileSystem: Record<string, FileSystemItem>, childFileId: string, parents: string[] = []): string[] => {
  const fileItem = Object.values(fileSystem).find((item) => item.childFileId === childFileId);

  if (fileItem && fileItem.parentFileId) {
    parents.push(fileItem.parentFileId);

    return getAllParentFileIds(fileSystem, fileItem.parentFileId, parents);
  }

  return parents;
};

export const selectActiveFilter = (state: RootState) => state.assets.ui.activeFilter;

export const selectFilesById = (state: RootState) => state.assets.entities.files.byId;
export const selectAllFilesIds = (state: RootState) => state.assets.entities.files.allIds;
export const selectRecentFilesId = (state: RootState) => state.assets.entities.files.recentFileIds;
export const selectStarredFilesId = (state: RootState) => state.assets.entities.files.starredFileIds;

export const selectSharedFilesById = (state: RootState) => state.assets.entities.sharedFiles.byId;
export const selectSharedFilesAllIds = (state: RootState) => state.assets.entities.sharedFiles.allIds;
export const selectSharedFilesPageNumber = (state: RootState) => state.assets.ui.sharedFilesPageNumber;
export const selectSharedFilesPageSize = (state: RootState) => state.assets.ui.sharedFilesPageSize;
export const selectSharedFilesTotalCount = (state: RootState) => state.assets.ui.sharedFilesTotalCount;
export const selectSharedFilesStatus = (state: RootState) => state.assets.ui.retrieveSharedFilesStatus;

// Selector to get shared files
export const selectSharedFiles = createSelector([selectSharedFilesById, selectSharedFilesAllIds], (byId, allIds) => allIds.map((id) => byId[id]));

export const selectSharedFilesPagesCount = createSelector([selectSharedFilesTotalCount, selectSharedFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectMemoizedSharedFilesPagesStatusText = createSelector(
  [selectSharedFilesPageNumber, selectSharedFilesPageSize, selectSharedFilesTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectFilesTotalCount = (state: RootState) => state.assets.ui.filesTotalCount;
export const selectRecentFilesTotalCount = (state: RootState) => state.assets.ui.recentFilesTotalCount;
export const selectStarredFilesTotalCount = (state: RootState) => state.assets.ui.starredFilesTotalCount;

// Selectors for My Uploads
export const selectMyUploadsById = (state: RootState) => state.assets.entities.myUploads.byId;
export const selectMyUploadsAllIds = (state: RootState) => state.assets.entities.myUploads.allIds;
export const selectMyUploads = createSelector([selectMyUploadsById, selectMyUploadsAllIds], (byId, allIds) => allIds.map((id) => byId[id]));

const selectFileSystem = (state: RootState) => state.assets.entities.fileSystem;

export const buildTreeItems = (
  fileSystem: { byId: { [key: string]: FileSystemItem }; allIds: string[] },
  filesById: { [key: string]: FileItem },
  parentId: string,
  nestingLevel: number
): TreeViewBaseItem<ExtendedTreeItem>[] =>
  chain(fileSystem.allIds)
    .uniq()
    .filter((id) => fileSystem.byId[id].type === FileType.FOLDER && fileSystem.byId[id].parentFileId === parentId)
    .map((id) => ({
      folderId: fileSystem.byId[id].childFileId,
      folderName: filesById[fileSystem.byId[id].childFileId].name
    }))
    .map(
      ({ folderId, folderName }) =>
        ({
          id: folderId,
          label: folderName,
          nestingLevel,
          children: buildTreeItems(fileSystem, filesById, folderId, nestingLevel + 1)
        }) as ExtendedTreeItem
    )
    .value();

export const selectMyFoldersAsTreeItems = createSelector([selectFileSystem, selectFilesById], (fileSystem, filesById) =>
  buildTreeItems(fileSystem, filesById, MY_FILES_FOLDER_ID, 1)
);

export const selectSelectedFolderId = (state: RootState) => state.assets.selectedFolderId;
export const selectExpandedFolderIds = (state: RootState) => state.assets.expandedFolderIds;

export const selectCreateNewFolderStatus = (state: RootState) => state.assets.ui.createNewFolderStatus;
export const selectNewFolderIds = (state: RootState) => state.assets.entities.files.newFolderIds;

const selectSortBy = (state: RootState) => state.assets.ui.myFilesSorting.sortBy;
const selectSortDirection = (state: RootState) => state.assets.ui.myFilesSorting.sortDirection;

export const selectMyFilesSorting = (state: RootState) => state.assets.ui.myFilesSorting;
export const selectMyFilesSortBySortFile = (state: RootState) => toSortFile(state.assets.ui.myFilesSorting.sortBy);

export const selectFiles = createSelector(
  [selectFilesById, selectFileSystem, selectSelectedFolderId, selectSortBy, selectSortDirection],
  (byId, fileSystem, selectedFolderId, sortBy, sortDirection) => {
    return chain(fileSystem.byId)
      .entries()
      .filter(([_key, value]) => value.parentFileId === selectedFolderId)
      .map(([_key, value]) => value.childFileId)
      .map((id) => byId[id])
      .orderBy([sortBy], [sortDirection])
      .value();
  }
);

export const selectFilesOnly = createSelector(
  [selectFilesById, selectFileSystem, selectSelectedFolderId, selectSortBy, selectSortDirection],
  (byId, fileSystem, selectedFolderId, sortBy, sortDirection) => {
    return chain(fileSystem.byId)
      .entries()
      .filter(([_key, value]) => value.parentFileId === selectedFolderId && byId[value.childFileId].type !== FileType.FOLDER)
      .map(([_key, value]) => value.childFileId)
      .map((id) => byId[id])
      .orderBy([sortBy], [sortDirection])
      .value();
  }
);

export const selectFilteredFiles = createSelector([selectFiles, selectNewFolderIds], (files, newFolderIds) => {
  return files.filter((file) => !newFolderIds.includes(file.id));
});

export const selectRecentFiles = createSelector([selectFilesById, selectRecentFilesId], (byId, recentFilesIds) => recentFilesIds.map((id) => byId[id]));
export const selectStarredFiles = createSelector([selectFilesById, selectStarredFilesId], (byId, starredFileIds) => starredFileIds.map((id) => byId[id]));

export const selectNewFolders = createSelector([selectFilesById, selectNewFolderIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectFilesPageNumber = (state: RootState) => state.assets.ui.filesPageNumber;
export const selectFilesPageSize = (state: RootState) => state.assets.ui.filesPageSize;

export const selectRecentFilesPageNumber = (state: RootState) => state.assets.ui.recentFilesPageNumber;
export const selectRecentFilesPageSize = (state: RootState) => state.assets.ui.recentFilesPageSize;

export const selectStarredFilesPageNumber = (state: RootState) => state.assets.ui.starredFilesPageNumber;
export const selectStarredFilesPageSize = (state: RootState) => state.assets.ui.starredFilesPageSize;

export const selectMemoizedFilePagesStatusText = createSelector(
  [selectFilesPageNumber, selectFilesPageSize, selectFilesTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectFilesPageCount = createSelector([selectFilesTotalCount, selectFilesPageSize], (totalCount, pageSize) => Math.ceil(totalCount / pageSize));

export const selectRetrieveFilesStatus = (state: RootState) => state.assets.ui.retrieveFilesStatus;
export const selectRetrieveRecentFilesStatus = (state: RootState) => state.assets.ui.retrieveRecentFilesStatus;
export const selectRetrieveStarredFilesStatus = (state: RootState) => state.assets.ui.retrieveStarredFilesStatus;

export const selectUploadFilesStatus = (state: RootState) => state.assets.ui.uploadFilesStatus;

export const selectMyUploadsCreateDeleteSasUri = (state: RootState) => state.assets.myUploadsCreateDeleteSasUri;
export const selectMyUploadsReadSasToken = (state: RootState) => state.assets.myUploadsReadSasToken;
export const selectMyUploadsRootFolders = (state: RootState) => state.assets.entities.myUploadsRootFolders;

export const selectFilesWithStatusForUpload = (state: RootState) =>
  state.assets.ui.filesWithStatus.filter((fws) => fws.isUploaded === false && fws.isUploading === false && fws.url === '');

export const selectUploadedFilesWithStatuses = (state: RootState) => state.assets.ui.filesWithStatus;

export const selectAssetsDrawerFileItem = (state: RootState) => state.assets.ui.drawer.fileItem!;
export const selectAssetsDrawerFolderItem = (state: RootState) => state.assets.ui.drawer.folderItem!;
export const selectAssetsDrawerMultipleFilesItem = (state: RootState) => state.assets.ui.drawer.multipleFilesItem!;

export const selectDraggingState = (state: RootState) => state.assets.draggingState;

export const selectTrashBinFileById = (state: RootState) => state.assets.entities.trashBinFiles.byId;
export const selectTrashBinFileAllIds = (state: RootState) => state.assets.entities.trashBinFiles.allIds;
export const selectTrashBinFileRemovedIds = (state: RootState) => state.assets.entities.trashBinFiles.removedIds;
export const selectTrashBinTotalCount = (state: RootState) => state.assets.ui.trashBinFilesTotalCount;

export const selectTrashBinFileIds = createSelector([selectTrashBinFileAllIds, selectTrashBinFileRemovedIds], (initialIds, removedIds) =>
  Array.from(new Set([...initialIds].filter((id) => !removedIds.includes(id))))
);

export const selectTrashBinFiles = createSelector([selectTrashBinFileById, selectTrashBinFileIds], (byId, ids) =>
  chain(ids)
    .map((id) => byId[id])
    .sortBy('name')
    .value()
);

export const selectTrashFilesPageNumber = (state: RootState) => state.assets.ui.trashBinFilesPageNumber;
export const selectTrashFilesPageSize = (state: RootState) => state.assets.ui.trashBinFilesPageSize;
export const selectTrashFilesStatus = (state: RootState) => state.assets.ui.retrieveTrashFilesStatus;

export const selectTrashBinPagesCount = createSelector([selectTrashBinTotalCount, selectTrashFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectMemoizedTrashFilePagesStatusText = createSelector(
  [selectTrashFilesPageNumber, selectTrashFilesPageSize, selectTrashBinTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectTrashFilesPageCount = createSelector([selectTrashBinTotalCount, selectTrashFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectFileUploadTotalProgress = (state: RootState) => state.assets.ui.fileUploadProgress.totalProgress;
export const selectIsDragging = (state: RootState) => state.assets.ui.isDragging;

export const selectAccessItemsByInitialId = (state: RootState) => state.assets.entities.accessItems.byInitialId;
export const selectAccessItemsByUpdatedId = (state: RootState) => state.assets.entities.accessItems.byUpdatedId;
export const selectAccessItemsInitialIds = (state: RootState) => state.assets.entities.accessItems.initialIds;
export const selectAccessItemsUpdatedIds = (state: RootState) => state.assets.entities.accessItems.updatedIds;
export const selectAccessItemsAddedIds = (state: RootState) => state.assets.entities.accessItems.addedIds;
export const selectAccessItemsRemovedIds = (state: RootState) => state.assets.entities.accessItems.removedIds;

export const selectAccessItemsIds = createSelector(
  [selectAccessItemsInitialIds, selectAccessItemsAddedIds, selectAccessItemsRemovedIds],
  (initialIds, addedIds, removedIds) => {
    return Array.from(uniq([...initialIds, ...addedIds].filter((id) => !removedIds.includes(id))));
  }
);

export const selectAccessItems = createSelector(
  [selectAccessItemsIds, selectAccessItemsByInitialId, selectAccessItemsByUpdatedId, selectAccessItemsUpdatedIds],
  (chosenEmployeeIds, byInitialId, byUpdatedId, updatedIds) => {
    return chosenEmployeeIds
      .filter((id) => !updatedIds.includes(id))
      .map((id) => byInitialId[id])
      .concat(updatedIds.map((id) => byUpdatedId[id]));
  }
);

export const selectSelectedFileIds = (state: RootState) => state.assets.selectedFileIds;
export const selectIsMoveModalOpen = (state: RootState) => state.assets.ui.isMoveModalOpen;
export const selectIsViewModalOpen = (state: RootState) => state.assets.ui.isViewModalOpen;

export const selectMemoizedFileIds = createSelector([selectSelectedFileIds], (selectedFileIds) => selectedFileIds);

export const selectSelectedFolderLabel = createSelector(
  [selectMyFoldersAsTreeItems, selectSelectedFolderId],
  (myFolders, selectedFolderId) => myFolders.find((mfti) => mfti.id === selectedFolderId)?.label
);

export const selectActivityItems = (state: RootState) => state.assets.activityItems;
export const selectActivityItemsStatus = (state: RootState) => state.assets.ui.activityItemsStatus;
export const selectRetrieveFilesInfoStatus = (state: RootState) => state.assets.ui.retrieveFilesInfoStatus;
export const selectRetrieveFilesAccessStatus = (state: RootState) => state.assets.ui.retrieveFilesAccessStatus;

export const {
  selectMultipleFiles,
  copyFilesRequested,
  copyFilesSucceeded,
  copyFilesFailed,
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveFilesFailed,
  retrieveFilesInfoRequested,
  retrieveFilesInfoSucceeded,
  retrieveFilesInfoFailed,
  retrieveStarredFilesRequested,
  retrieveStarredFilesSucceeded,
  retrieveStarredFilesFailed,
  uploadFilesRequested,
  notifyBackendOnUpload,
  uploadFilesSucceeded,
  uploadFilesFailed,
  uploadFile,
  updateFileUploadProgress,
  fileUploaded,
  createFolderRequested,
  createFolderSucceeded,
  createFolderFailed,
  retrieveMyUploadsReadSasTokenRequested,
  retrieveMyUploadsReadSasTokenSucceeded,
  retrieveMyUploadsReadSasTokenFailed,
  retrieveMyUploadsCreateDeleteSasUriRequested,
  retrieveMyUploadsCreateDeleteSasUriSucceeded,
  retrieveMyUploadsCreateDeleteSasUriFailed,
  retrieveFilesAccessRequested,
  retrieveFilesAccessSucceeded,
  retrieveFilesAccessFailed,
  refreshMyUploadsCreateDeleteSasUriRequested,
  refreshMyUploadsCreateDeleteSasUriSucceeded,
  refreshMyUploadsCreateDeleteSasUriFailed,
  renameFileRequested,
  renameFileSucceeded,
  renameFileFailed,
  retrieveTrashBinFilesRequested,
  retrieveTrashBinFilesSucceeded,
  retrieveTrashBinFilesFailed,
  retrieveRecentFilesRequested,
  retrieveRecentFilesSucceeded,
  retrieveRecentFilesFailed,
  clearRecentFiles,
  editDescriptionRequested,
  editDescriptionSucceeded,
  editDescriptionFailed,
  softDeleteFilesRequested,
  softDeleteFilesSucceeded,
  softDeleteFilesFailed,
  toggleRecentFiles,
  toggleStarredFiles,
  selectFolder,
  expandFolders,
  starFileRequested,
  starFileSucceeded,
  starFileFailed,
  setInnerDragging,
  moveFilesRequested,
  moveFilesSucceeded,
  moveFilesFailed,
  setIsDragging,
  shareFileRequested,
  shareFilesSucceeded,
  shareFilesFailed,
  hardDeleteFilesRequested,
  hardDeleteFilesSucceeded,
  hardDeleteFilesFailed,
  restoreFilesRequested,
  restoreFilesSucceeded,
  restoreFilesFailed,
  pinFolderRequested,
  pinFolderSucceeded,
  pinFolderFailed,
  revokeAccessForAllRequested,
  revokeAccessForAllSucceeded,
  revokeAccessForAllFailed,
  addEmployeeToShareList,
  removeEmployeeFromShareList,
  retrieveSharedFilesRequested,
  retrieveSharedFilesSucceeded,
  retrieveSharedFilesFailed,
  retrieveMyUploadsRequested,
  retrieveMyUploadsSucceeded,
  retrieveMyUploadsFailed,
  updateEmployeeAccessRole,
  createNewFolder,
  removeNewFolder,
  openMoveModal,
  closeMoveModal,
  openViewModal,
  closeViewModal,
  sortFilesBy,
  retrieveFileActivitiesRequested,
  retrieveFileActivitiesSucceeded,
  retrieveFileActivitiesFailed
} = assetSlice.actions;
export default assetSlice.reducer;
