import { Stepper, styled } from '@mui/material';

export const FlowStepper = styled(Stepper)(() => ({
  gap: '12px',
  '& .MuiStepConnector-root': {
    top: '50%',
    transform: 'translateY(-50%)'
  },
  '& .MuiStepConnector-line': {
    borderColor: 'transparent'
  }
}));
