import React, { useId, useMemo, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  ListItem,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  AddIcon,
  AlertIcon,
  ChevronDownIcon,
  StyledAddNewRowButton,
  PrimaryDarkButton,
  StyledUpdateButton,
  RowCenterStack,
  StyledModalContent,
  StyledAutocomplete,
  StyledTransparentFilledInput,
  StyledTransparentFilledInputDatePicker,
  CloseIcon
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { WorkChangeType, WorkEvent } from '@thrivea/organization-client';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  addNewWorkEvent,
  createWorkEventSchema,
  selectCompletedOrganizationSites,
  selectEmployeeItems,
  selectEmployeeProfileAndCoverReadSasToken,
  selectOrganizationDepartments,
  selectOrganizationSites,
  selectWorkCategoryTableData,
  WorksAtSiteTableCell
} from '@features/employee-profile';
import { initials } from '@utils/initials';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { blobWithSasTokenUrl } from '@utils/blobWithSasTokenUrl';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';
import { openDrawer, DrawerType, OpenDrawerRequest } from '@features/drawer';

interface WorkCategoryTable {
  handleSetEditable: () => void;
  employeeId: string;
}

export const WorkCategoryTable: React.FC<WorkCategoryTable> = ({ handleSetEditable, employeeId }) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile', 'work_change_type']);
  const dispatch = useAppDispatch();
  const [selectedRowData, setSelectedRowData] = useState<WorkEvent | null>(null);
  const [isWorkModalOpen, setIsWorkModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const workCategoryTableData = useAppSelector(selectWorkCategoryTableData);
  const employeeItems = useAppSelector(selectEmployeeItems);
  const sites = useAppSelector(selectOrganizationSites);
  const completedOrganizationSites = useAppSelector(selectCompletedOrganizationSites);
  const organizationDepartments = useAppSelector(selectOrganizationDepartments);
  const workEventSchema = useMemo(() => createWorkEventSchema(t), [t]);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const {
    formState: { errors, dirtyFields },
    control,
    reset,
    handleSubmit,
    setValue
  } = useForm<WorkEvent>({
    mode: 'all',
    resolver: zodResolver(workEventSchema),
    defaultValues: {
      effectiveDate: '',
      jobTitle: '',
      departmentId: '',
      reportsToId: '',
      worksAtSiteId: '',
      changeType: 0,
      reason: ''
    }
  });

  const workChangeType = Object.keys(WorkChangeType)
    .filter((key) => !isNaN(Number(key)))
    .slice(1) // exclude first option
    .map((key) => ({
      id: parseInt(key, 10) - 1, // deduct by 1 as we are removing first option
      name: WorkChangeType[Number(key)].toLowerCase()
    }));

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowEdit = (event: React.MouseEvent<HTMLElement>, rowData: WorkEvent) => {
    event.stopPropagation();
    setSelectedRowData(rowData);
  };

  const handleModalOpen = () => {
    setIsWorkModalOpen(true);
    handleSetEditable();
  };

  const handleCloseModal = () => {
    setIsWorkModalOpen(false);
    reset({
      effectiveDate: '',
      jobTitle: '',
      departmentId: '',
      reportsToId: '',
      worksAtSiteId: '',
      changeType: 0,
      reason: ''
    });
  };

  const onSubmit = (data: WorkEvent) => {
    dispatch(addNewWorkEvent(data));
    handleCloseModal();
  };

  const handleEmployeeDrawerOpen = (employeeId: string) => {
    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  return (
    <Stack
      gap={2}
      sx={{
        height: '100%',
        justifyContent: 'stretch',
        padding: 1
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          overflow: 'hidden',
          '&.MuiPaper-root': {
            backgroundColor: (theme) => (workCategoryTableData.length === 0 ? theme.palette.primary.light : 'transparent')
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            flex: 1,
            justifyContent: 'stretch'
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }} tabIndex={0}>
            {workCategoryTableData.length === 0 && (
              <Stack
                sx={{
                  width: '100%',
                  height: 250,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '16px',
                  '& svg': {
                    width: 24,
                    height: 24
                  }
                }}
              >
                <AlertIcon />
                <Typography>{t('empty_table', { ns: 'employee_profile' })}</Typography>
                <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'work' } as ProfileCategoryInfo}>
                  <StyledAddNewRowButton variant="outlined" endIcon={<AddIcon />} onClick={handleModalOpen}>
                    {t('add_new_row', { ns: 'common' })}
                  </StyledAddNewRowButton>
                </AllowedTo>
              </Stack>
            )}
            {workCategoryTableData.length !== 0 && (
              <>
                <Table stickyHeader>
                  <TableHead
                    sx={{
                      position: 'relative',
                      backgroundColor: (theme) => theme.palette.customTheme.contBgr
                    }}
                  >
                    <TableRow
                      sx={{
                        '& > .MuiTableCell-root': {
                          border: 0
                        }
                      }}
                    >
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('effective_date', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('job_title', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('department', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('reports_to', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('site', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('change_type', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('reason', { ns: 'employee_profile' })}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          position: 'sticky',
                          right: 0,
                          backgroundColor: (theme) => theme.palette.common.white,
                          minWidth: '90px',
                          height: '100%',
                          boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)'
                        }}
                      /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      position: 'relative'
                    }}
                  >
                    {workCategoryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((workEvent, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{workEvent.effectiveDate}</TableCell>
                        <TableCell>{workEvent.jobTitle}</TableCell>
                        <TableCell>{organizationDepartments.find((department) => department.id === workEvent.departmentId)?.name ?? ''}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleEmployeeDrawerOpen(workEvent.reportsToId)}
                            variant="text"
                            sx={{
                              color: (theme) => theme.palette.action.focus,
                              padding: 0,
                              margin: 0,
                              backgroundColor: 'transparent',
                              textDecoration: 'underline',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {employeeItems.byId.hasOwnProperty(workEvent.reportsToId) ? employeeItems.byId[workEvent.reportsToId].displayName : ''}
                          </Button>
                        </TableCell>
                        <WorksAtSiteTableCell site={sites.find((s) => s.id === workEvent.worksAtSiteId)} />
                        <TableCell>{t(workChangeType[workEvent.changeType]!.name, { ns: 'work_change_type' })}</TableCell>
                        <TableCell>{workEvent.reason}</TableCell>
                        {/* TODO - Implement after adding edit/delete actions */}
                        {/* <TableCell
                          sx={{
                            position: 'sticky',
                            right: 0,
                            backgroundColor: (theme) => theme.palette.common.white,
                            minWidth: '90px',
                            height: '100%',
                            boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)',
                            textAlign: 'center'
                          }}
                        >
                          <IsolatedMenu id={crypto.randomUUID()} handleRowEdit={(e) => handleRowEdit(e, row)} />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={workCategoryTableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'work' } as ProfileCategoryInfo}>
                  <StyledAddNewRowButton
                    variant="outlined"
                    endIcon={<AddIcon />}
                    onClick={handleModalOpen}
                    sx={{
                      ml: 2,
                      mb: 2
                    }}
                  >
                    {t('add_new_row', { ns: 'common' })}
                  </StyledAddNewRowButton>
                </AllowedTo>
              </>
            )}
          </TableContainer>
        </Stack>
        {/* add new work event modal */}
        <Modal
          open={isWorkModalOpen}
          onClose={handleCloseModal}
          sx={{
            background: 'rgba(217, 217, 217, 0.60)',
            backdropFilter: 'blur(10px)'
          }}
        >
          <StyledModalContent
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: {
                xs: 'auto',
                lg: '1316px'
              },
              height: {
                xs: '80%',
                lg: 'auto'
              },
              padding: '120px',
              display: 'flex',
              borderRadius: '20px',
              boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mb: 3
              }}
            >
              {t('add_new_row', { ns: 'common' })}
            </Typography>
            <Grid
              container
              columnSpacing={2}
              rowGap={2}
              sx={{
                mt: 2,
                mb: 4
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  width: '100%'
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: 2
                  }}
                >
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="effectiveDate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              {...field}
                              className="MuiDate-root"
                              format="yyyy-MM-dd"
                              value={field.value ? DateTime.fromISO(field.value) : null}
                              disableFuture
                              slots={{
                                textField: StyledTransparentFilledInputDatePicker
                              }}
                              slotProps={{
                                textField: {
                                  id: id + field.name,
                                  variant: 'filled',
                                  fullWidth: true,
                                  placeholder: 'YYYY-MM-DD',
                                  label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                  required: true,
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  InputProps: {
                                    disableUnderline: true,
                                    onBlur: field.onBlur
                                  },
                                  InputLabelProps: {
                                    shrink: true
                                  }
                                }
                              }}
                              onChange={(date: DateTime | null) => {
                                if (date) {
                                  const jsDate = date.toJSDate();
                                  const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                  field.onChange(stringDate);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="jobTitle"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentFilledInput
                          {...field}
                          id="job_title"
                          required
                          label={t('job_title', { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="departmentId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          limitTags={2}
                          options={organizationDepartments}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, value) => field.onChange(value ? value.id : '')}
                          onBlur={field.onBlur}
                          renderInput={(params) => (
                            <StyledTransparentFilledInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="reportsToId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          options={employeeItems.allIds}
                          getOptionLabel={(option) => employeeItems.byId[option].displayName ?? ''}
                          onChange={(_event, value, reason) => {
                            // check on blur if the value is empty
                            if (reason === 'blur' && !value) {
                              setValue('reportsToId', '');
                            } else {
                              field.onChange(value);
                              field.onBlur();
                            }
                          }}
                          onBlur={field.onBlur}
                          renderInput={(params) => (
                            <StyledTransparentFilledInput
                              {...params}
                              id={id + field.name}
                              required
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              <RowCenterStack
                                gap={2}
                                sx={{
                                  my: 1
                                }}
                              >
                                <Avatar src={blobWithSasTokenUrl(employeeItems.byId[option].profilePictureUrl, employeeProfileSasToken)}>
                                  {initials(employeeItems.byId[option].displayName)}
                                </Avatar>
                                <Typography>{employeeItems.byId[option].displayName}</Typography>
                              </RowCenterStack>
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="worksAtSiteId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          limitTags={2}
                          options={completedOrganizationSites}
                          getOptionLabel={(option) => option.name}
                          onChange={(_event, value, reason) => {
                            if (reason === 'blur' && !value) {
                              setValue('worksAtSiteId', '');
                            } else {
                              field.onChange(value!.id);
                            }
                          }}
                          onBlur={field.onBlur}
                          renderInput={(params) => (
                            <StyledTransparentFilledInput
                              {...params}
                              id={id + field.name}
                              required
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="changeType"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          options={workChangeType}
                          getOptionLabel={(option) => t(option.name, { ns: 'work_change_type' })}
                          onChange={(_, value) => field.onChange(value!.id)}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          // value={workChangeType.find((option) => option.id === field.value)}
                          onBlur={field.onBlur}
                          renderInput={(params) => (
                            <StyledTransparentFilledInput
                              {...params}
                              id={id + field.name}
                              required
                              label={t('change_type', { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                          renderOption={(props, option) => {
                            return (
                              <ListItem {...props} key={id + option.name}>
                                {t(option.name, { ns: 'work_change_type' })}
                              </ListItem>
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="reason"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentFilledInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <RowCenterStack
                  gap={2}
                  sx={{
                    justifyContent: 'center'
                  }}
                >
                  <PrimaryDarkButton variant="outlined" onClick={handleCloseModal}>
                    {t('cancel', { ns: 'common' })}
                  </PrimaryDarkButton>
                  <StyledUpdateButton
                    type="submit"
                    variant="contained"
                    disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                  >
                    {t('update', { ns: 'common' })}
                  </StyledUpdateButton>
                </RowCenterStack>
              </Box>
            </Grid>
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </StyledModalContent>
        </Modal>
      </Paper>
    </Stack>
  );
};
