import { Fragment, SyntheticEvent, useId, useState } from 'react';
import { ChevronDownIcon, StyledListItemButton, VisuallyHidden } from '@/shared';
import { Box, Collapse, List, ListItemText, IconButton, ListSubheader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { MY_FILES_FOLDER_ID, expandFolders, AssetsTreeItem, SHARED_WITH_ME_FILES_ID, TRASH_BIN_FILES_ID, MY_UPLOADS_FILES_FOLDER_ID } from '@features/assets';
import { OrderDirection, RetrieveFilesRequest, SortFile } from '@thrivea/organization-client';
import { selectCurrentEmployeeId } from '@features/shared';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import {
  retrieveTaskFilesRequested,
  selectTaskExpandedFolderIds,
  selectTaskFilesPageNumber,
  selectTaskFilesPageSize,
  selectTaskFolder,
  selectTaskSelectedFolderId,
  selectTasksMyFoldersAsTreeItems
} from '@features/tasks';

export const TaskAssetsModalNav = () => {
  const { t } = useTranslation(['common', 'assets']);
  const dispatch = useAppDispatch();

  const subHeaderId = useId();
  const filesPageSize = useAppSelector(selectTaskFilesPageSize);
  const filesPageNumber = useAppSelector(selectTaskFilesPageNumber);

  const signedInEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const myFilesAsTreeItems = useAppSelector(selectTasksMyFoldersAsTreeItems);
  const selectedFolderId = useAppSelector(selectTaskSelectedFolderId);
  const expandedFolderIds = useAppSelector(selectTaskExpandedFolderIds);

  const [activeItemId, setActiveItemId] = useState(MY_FILES_FOLDER_ID);
  const [isItemExpanded, setIsItemExpanded] = useState(true);

  const handleMyFilesItemClick = () => {
    setActiveItemId(MY_FILES_FOLDER_ID);
    dispatch(
      retrieveTaskFilesRequested(
        new RetrieveFilesRequest({
          employeeId: signedInEmployeeId,
          folderId: MY_FILES_FOLDER_ID,
          pageNumber: filesPageNumber,
          pageSize: filesPageSize,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.ASCENDING
        })
      )
    );
  };

  const handleFolderItemClick = (id: string) => {
    setActiveItemId(id);
    dispatch(selectTaskFolder(id));
    dispatch(
      retrieveTaskFilesRequested(
        new RetrieveFilesRequest({
          employeeId: signedInEmployeeId,
          folderId: id,
          pageNumber: filesPageNumber,
          pageSize: filesPageSize,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.ASCENDING
        })
      )
    );
  };

  const handleExpandedItemsChange = (_: SyntheticEvent<Element, Event>, itemIds: string[]): void => {
    dispatch(expandFolders(itemIds));
  };

  const handleTrashBinItemClick = () => {
    setActiveItemId(TRASH_BIN_FILES_ID);
    dispatch(selectTaskFolder(TRASH_BIN_FILES_ID));
  };

  const handleSharedWithMeItemClick = () => {
    setActiveItemId(SHARED_WITH_ME_FILES_ID);
    dispatch(selectTaskFolder(SHARED_WITH_ME_FILES_ID));
  };

  const handleMyUploadsItemClick = () => {
    setActiveItemId(MY_UPLOADS_FILES_FOLDER_ID);
    dispatch(selectTaskFolder(MY_UPLOADS_FILES_FOLDER_ID));
  };

  return (
    <Box component="nav" sx={{ backgroundColor: 'inherit', borderRadius: 'inherit', width: '100%', height: '100%', overflowY: 'scroll' }}>
      <List
        sx={{
          width: '100%',
          px: '4px',
          py: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '3px'
        }}
        aria-labelledby={subHeaderId}
      >
        <ListSubheader
          sx={{
            color: (theme) => theme.palette.customTheme.contColor,
            padding: '8px 4px 8px 16px',
            textTransform: 'uppercase',
            height: 47,
            fontSize: 14,
            lineHeight: '30px',
            fontWeight: 700
          }}
        >
          {t('file_other', { ns: 'assets' })}
        </ListSubheader>
        <Fragment>
          <StyledListItemButton onClick={handleMyFilesItemClick} disabled={false} selected={activeItemId === MY_FILES_FOLDER_ID}>
            <ListItemText
              primary={t('my_files', { ns: 'assets' })}
              primaryTypographyProps={{
                fontSize: 14
              }}
            />
            <IconButton
              disableFocusRipple
              onClick={() => setIsItemExpanded(!isItemExpanded)}
              sx={{
                p: 0,
                m: 0
              }}
            >
              {isItemExpanded ? (
                <>
                  <Box
                    sx={{
                      paddingRight: '4px'
                    }}
                  >
                    <ChevronDownIcon />
                  </Box>
                  <VisuallyHidden>Expand My Files</VisuallyHidden>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      paddingRight: '4px',
                      '& svg': {
                        transform: 'rotate(-90deg)'
                      }
                    }}
                  >
                    <ChevronDownIcon />
                  </Box>
                  <VisuallyHidden>Expand My Files</VisuallyHidden>
                </>
              )}
            </IconButton>
          </StyledListItemButton>
          <Collapse in={isItemExpanded} timeout="auto" unmountOnExit>
            <RichTreeView
              items={myFilesAsTreeItems}
              expandedItems={expandedFolderIds}
              onExpandedItemsChange={handleExpandedItemsChange}
              selectedItems={selectedFolderId}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3px'
              }}
              slots={{
                item: AssetsTreeItem,
                collapseIcon: ChevronDownIcon,
                expandIcon: () => (
                  <Box
                    sx={{
                      '& svg': {
                        transform: 'rotate(-90deg)'
                      }
                    }}
                  >
                    <ChevronDownIcon />
                  </Box>
                )
              }}
              onItemClick={(event: React.MouseEvent, itemId: string) => handleFolderItemClick(itemId)}
              getItemId={(item) => item.id}
            />
          </Collapse>
        </Fragment>
        <StyledListItemButton onClick={handleSharedWithMeItemClick} disabled={false} selected={activeItemId === SHARED_WITH_ME_FILES_ID}>
          <ListItemText
            primary={t('shared_with_me', { ns: 'assets' })}
            primaryTypographyProps={{
              fontSize: 14
            }}
          />
        </StyledListItemButton>
        <StyledListItemButton onClick={handleMyUploadsItemClick} disabled={true} selected={activeItemId === MY_UPLOADS_FILES_FOLDER_ID}>
          <ListItemText
            primary={t('my_uploads', { ns: 'assets' })}
            primaryTypographyProps={{
              fontSize: 14
            }}
          />
        </StyledListItemButton>
      </List>
    </Box>
  );
};
