import { styled } from '@mui/material/styles';
import { Box, Input as MuiInput, Slider, sliderClasses } from '@mui/material';
import { ChangeEvent } from 'react';
import { RowCenterJustifyBetweenStack } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectAdditionalStorage, updateAdditionalStorage } from '@features/admin-settings';

const Input = styled(MuiInput)`
  border: 1px solid black;
  border-radius: 4px;
  padding: 7px 11px;
`;

export const AdditionalStorageRangeSlider = () => {
  const dispatch = useAppDispatch();

  const additionalStorage = useAppSelector(selectAdditionalStorage);

  const handleAdditionalStorageChange = (value: number) => {
    dispatch(updateAdditionalStorage(value));
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    handleAdditionalStorageChange(newValue as number);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleAdditionalStorageChange(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (additionalStorage < 0) {
      handleAdditionalStorageChange(0);
    } else if (additionalStorage > 100) {
      handleAdditionalStorageChange(100);
    }
  };

  return (
    <Box sx={{ width: '100%', marginLeft: 1 }}>
      <RowCenterJustifyBetweenStack>
        <Slider
          value={typeof additionalStorage === 'number' ? additionalStorage : 0}
          onChange={handleSliderChange}
          sx={{
            width: 249,
            color: (theme) => theme.palette.common.white,
            borderRadius: 12,
            height: 8,
            [`& .${sliderClasses.thumb}`]: {
              backgroundColor: (theme) => theme.palette.common.white,
              width: 24,
              height: 24,
              borderRadius: 12,
              border: '1.5px solid',
              borderColor: (theme) => theme.palette.customTheme.focusItem
            },
            [`& .${sliderClasses.rail}`]: {
              opacity: 1
            }
          }}
        />
        <Input
          value={additionalStorage}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          disableUnderline
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number'
          }}
        />
      </RowCenterJustifyBetweenStack>
    </Box>
  );
};
